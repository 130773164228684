import { InjectionToken } from '@angular/core';

import * as LaunchDarklyClient from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  LDBooleanFeatureFlags,
  LDJsonFeatureFlags,
  LDNumberFeatureFlags,
  LDStringFeatureFlags
} from './launch-darkly-feature-flags.enum';
import { ILaunchDarklyUser } from './launch-darkly-user.interface';

export interface ILaunchDarklyService {
  ldFlagSubject: BehaviorSubject<LaunchDarklyClient.LDFlagSet>;
  ldFlag$: Observable<LaunchDarklyClient.LDFlagSet>;
  client: LaunchDarklyClient.LDClient | undefined;
  errorOnInit?: boolean;
  ldSlug?: string;
  initClient: (user: ILaunchDarklyUser) => void;
  /**
   * @deprecated use `checkFlagValue` instead
  */
  isFlagEnabled?: (flag: LDBooleanFeatureFlags) => boolean;
  checkFlagEnabled$?: (flag: LDBooleanFeatureFlags) => Observable<boolean>;
  /**
   * @deprecated use `checkFlagValue` instead
   */
  getFlagValue?: (flag: LDStringFeatureFlags, defaultFlag: string) => string;
  checkFlagValue$?: (flag: LDStringFeatureFlags, defaultFlag: string) => Observable<string>;
  /**
   * @deprecated use `checkFlagValue` instead
   */
  getFlagJsonValue?: <T>(flag: LDJsonFeatureFlags) => T | null;
  checkFlagJsonValue$?: <T>(flag: LDJsonFeatureFlags) => Observable<T | null>;
  checkNumberFlagValue$?: (flag: LDNumberFeatureFlags, defaultFlag: number) => Observable<number>;
  closeClient?: () => void;
  sendMatchFlowSubmitEvent?: () => void;
}

export const LAUNCH_DARKLY_SERVICE = new InjectionToken<ILaunchDarklyService>('Launch Darkly Service');
