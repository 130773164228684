/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throwing an error
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.

// TODO: Remove or find solution for prod.

import { InjectionToken } from '@angular/core';

export const currentCommitHash: string = 'SENTRY_RELEASE_REPLACE';

export const CURRENT_COMMIT_HASH = new InjectionToken<string>('Commit SHA');
