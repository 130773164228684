import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicFooterComponent } from './public-footer.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PublicFooterComponent],
  exports: [PublicFooterComponent]
})
export class PublicFooterModule {}
