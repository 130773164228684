import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError, first, from,
  map, of
} from 'rxjs';

import { datadogRum } from '@datadog/browser-rum';
import { ConfigurationService, ILaunchDarklyAppConfig } from '@sondermind/configuration';
import {
  ILaunchDarklyMetricContext,
  ILaunchDarklyService,
  ILaunchDarklyUser,
  LDBooleanFeatureFlags,
  LDJsonFeatureFlags,
  LDNumberFeatureFlags,
  LDStringFeatureFlags,
  LaunchDarklyMetricEvent
} from '@sondermind/launch-darkly';
import * as LaunchDarklyClient from 'launchdarkly-js-client-sdk';

@Injectable()
export class LaunchDarklyService implements ILaunchDarklyService {
  client: LaunchDarklyClient.LDClient | undefined;
  clientLoaded: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(undefined);
  errorOnInit: boolean = false;
  ldFlagSubject: BehaviorSubject<LaunchDarklyClient.LDFlagSet> = new BehaviorSubject<LaunchDarklyClient.LDFlagSet>({});
  ldFlag$: Observable<LaunchDarklyClient.LDFlagSet> = this.ldFlagSubject.asObservable();

  constructor(
    private configurationService: ConfigurationService<ILaunchDarklyAppConfig>
  ) {}

  /**
   * If a LaunchDarkly client connection doesn't exist, open one.
   * We receive a user object in the shape of an ILaunchDarklyUser and send that data on client connection init.
   * Check connection to LaunchDarkly, but fail silently if connection fails.
   * We don't want LD issues to prevent any portal functionality.
   * When connection succeeds, check state of feature flags.
   */
  initClient(user: ILaunchDarklyUser): void {
    // We should only initialize a connection if one doesn't exist or it errored previously.
    if (!this.client || this.errorOnInit) {
      if (this.errorOnInit) {
        this.errorOnInit = false;
        this.closeClient();
      }

      const launchDarklyClientSdkId = this.configurationService.env.app?.launchDarklyClientSideId;

      if (launchDarklyClientSdkId) {
        let options = {};
        if (!!this.configurationService.env.dataDogApplicationID &&
          !!this.configurationService.env.dataDogClientToken &&
          this.configurationService.env.enableDataDogSessionReplay) {
          // Set feature flag tracking for datadog if we have enabled session replay
          options = {
            inspectors: [
              {
                type: 'flag-used',
                name: 'dd-inspector',
                method: (key: string, detail: LaunchDarklyClient.LDEvaluationDetail) => {
                  datadogRum.addFeatureFlagEvaluation(key, detail.value);
                },
              },
            ],
          };
        }

        options['allAttributesPrivate'] = true;

        this.client = LaunchDarklyClient.initialize(launchDarklyClientSdkId, user, options);

        // Check that initialization succeeds.
        from(this.client.waitForInitialization()).pipe(
          catchError(() => {
            this.clientLoaded.next(false);
            this.errorOnInit = true;
            return of(undefined);
          }),
          map(() => this.clientLoaded.next(true))
        ).subscribe();
      }
    }
  }

  /**
   * This function will watch for live changes. This will automatically update the LD cache to get the newest value.
   * @param flagName
   */
  watchFlagForChanges(flagName: string): void {
    this.client?.on(`change:${flagName}`, (val, prev) => {});
  }

  /**
   * Send a custom metric event to the LaunchDarkly client.
   * Do not attempt to send if there was an errorOnInit.
   * Usage of ? is probably overly cautious, but in case of a timing issue with initialization.
   *
   * additionalData is optional and used for more context around an event if needed.
   */
  sendMetricEvent(ldMetric: LaunchDarklyMetricEvent, additionalData: ILaunchDarklyMetricContext = {}): void {
    if (!this.errorOnInit) {
      this.client?.track(ldMetric, additionalData);
    }
  }

  // Check if a boolean-based Feature Flag is on.
  checkFlagEnabled$(flag: LDBooleanFeatureFlags): Observable<boolean> {
    return this.clientLoaded.pipe(first((loaded) => loaded !== undefined),
      map((loaded) => {
        if (!loaded) return false;
        const flagValue = this.client?.variation(flag, false);
        // We can't guarantee LaunchDarkly will send a boolean down
        if (typeof flagValue === 'boolean') {
          return flagValue;
        }
        return false;
      }));
  }

  /**
   * Get the value of a number-based Feature Flag.
   */
  checkNumberFlagValue$(flag: LDNumberFeatureFlags, defaultFlag: number = 0): Observable<number> {
    return this.clientLoaded.pipe(first((loaded) => loaded !== undefined),
      map((loaded) => {
        if (!loaded) return defaultFlag;
        const flagValue = this.client?.variation(flag, defaultFlag);
        // We can't guarantee LaunchDarkly will send a number down
        if (typeof flagValue === 'number') {
          return flagValue;
        }
        return defaultFlag;
      }));
  }

  /**
   * Get the value of a string-based Feature Flag.
   */
  checkFlagValue$(flag: LDStringFeatureFlags, defaultFlag: string = ''): Observable<string> {
    return this.clientLoaded.pipe(first((loaded) => loaded !== undefined),
      map((loaded) => {
        if (!loaded) return defaultFlag;
        const flagValue = this.client?.variation(flag, defaultFlag);
        // We can't guarantee LaunchDarkly will send a string down
        if (typeof flagValue === 'string') {
          return flagValue;
        }
        return defaultFlag;
      }));
  }

  /**
   * Get the value of a JSON-based Feature Flag.
   */
  checkFlagJsonValue$<T>(flag: LDJsonFeatureFlags): Observable<T | null> {
    return this.clientLoaded.pipe(first((loaded) => loaded !== undefined),
      map((loaded) => {
        if (!loaded) return null;

        const flagValue = this.client?.variation(flag, null) as T;

        if (typeof flagValue === 'object') {
          return flagValue;
        }

        return null;
      }));
  }

  /**
   * Get all flags active for a user
   */
  getAllFlags$(): Observable<LaunchDarklyClient.LDFlagSet> {
    return this.clientLoaded.pipe(first((loaded) => loaded !== undefined),
      map((loaded) => {
        if (!loaded) return null;

        return this.client.allFlags();
      }));
  }

  /**
   * Used to close the LDClient connection when the AppComponent is destroyed.
   */
  closeClient(): void {
    this.client?.close();
  }

  /**
   * @deprecated use `checkFlag` instead
   * This method will not work on page refresh without calling `waitForInitialization` first.
   */
  isFlagEnabled(flag: LDBooleanFeatureFlags): boolean {
    if (this.errorOnInit || !this.client) { return false }

    const flagValue = this.client.variation(flag, false);

    // We can't guarantee LaunchDarkly will send a boolean down
    if (typeof flagValue === 'boolean') {
      return flagValue;
    }
    return false;
  }

  /**
   * @deprecated use `checkFlagJsonValue` instead
   * This method will not work on page refresh without calling `waitForInitialization` first.
   */
  getFlagJsonValue<T>(flag: LDJsonFeatureFlags): T | null {
    if (!this.client) { return null }

    const flagValue = this.client.variation(flag, null) as T;

    if (typeof flagValue === 'object') {
      return flagValue;
    }
    return null;
  }

  /**
   * @deprecated use `checkFlagValue` instead
   * This method will not work on page refresh without calling `waitForInitialization` first.
   */
  getFlagValue(flag: LDStringFeatureFlags, defaultFlag: string = ''): string {
    if (this.errorOnInit || !this.client) { return defaultFlag }

    const flagValue = this.client.variation(flag, defaultFlag);

    // We can't guarantee LaunchDarkly will send a string down
    if (typeof flagValue === 'string') {
      return flagValue;
    }
    return defaultFlag;
  }
}
