import { MatchAssignmentType } from '@sondermind/utilities/models-matching';
import {
  DirectScheduleStatus,
  IMatchHighlight,
  ITherapistLocation,
  ITherapistMatch,
  ITherapistTemplate,
  ITherapistTemplateApiResponse,
  MatchHighlightKey
} from '@sondermind/utilities/models-therapist';
import { camelCaseTransformer } from '@sondermind/utilities/tools';

/**
 * (PE-23276) Translates BE age groups and certain specialties into display list
 * and properly cased sentence.
 * Order is ages, service (couples), specialties
 * NOTE: it may make more sense to determine these on the back end if it becomes a permanent feature.
 */
function generateSpecializedSupportList(
  ages: string[] = [],
  servicesOffered: string[] = [],
  specialties: Array<{ name: string; desc: string; }> = []
): string[] {
  const specializedSupportList: string[] = [];
  // The following if statements are used to set order of specialized topics
  if (ages.find((age) => age === '0-5 Years' || age === '6-12 Years')) {
    specializedSupportList.push('Children');
  }

  if (ages.includes('13-17 Years')) {
    specializedSupportList.push('Adolescents');
  }

  if (ages.includes('18-65 Years')) {
    specializedSupportList.push('Adults');
  }

  if (ages.includes('66+ Years')) {
    specializedSupportList.push('Older adults');
  }

  if (servicesOffered.includes('Couples')) {
    specializedSupportList.push('Couples');
  }

  if (specialties.find((spec) => spec.name === 'LGBTQIA+ support')) {
    specializedSupportList.push('LGBTQIA+ mental health');
  }

  if (specialties.find((spec) => spec.name === 'Military / Veteran support')) {
    specializedSupportList.push('Military and veteran mental health');
  }

  if (specialties.find((spec) => spec.name === 'Perinatal support: pregnancy, prenatal, postpartum')) {
    specializedSupportList.push('Perinatal mental health');
  }

  return specializedSupportList;
}

/**
 * (PE-23276) Takes a list of supported groups/specialties (should be
 * generated by generateSpecializedSupportList), and generates a single
 * line string listing out all items in the list. The list will be formatted
 * differently based on whether there are 1, 2, or more items in the list.
 *  1 item = 'A'
 *  2 items = 'A and B'
 *  3+ items = 'A, B, ..., Y, and Z'
 * Asside from the initialism "LGBTQIA," only the first letter of the first item is capitalized.
 */
function generateSpecializedSupportStatement(specializedSupportList: string[]): string {
  let specializedSupportStatement = '';
  if (specializedSupportList.length === 1) {
    [specializedSupportStatement] = specializedSupportList;
  }

  if (specializedSupportList.length === 2) {
    const firstSpec = specializedSupportList[0];
    const lastSpec = specializedSupportList[1].includes('LGBTQIA')
      ? specializedSupportList[1] : specializedSupportList[1].toLowerCase();

    specializedSupportStatement = `${firstSpec} and ${lastSpec}`;
  }

  if (specializedSupportList.length > 2) {
    const firstSpec = specializedSupportList[0];
    const middleSpecs = specializedSupportList
      .slice(1, -1).join(', ').toLowerCase();
    const lastSpec = specializedSupportList[specializedSupportList.length - 1].toLowerCase();

    specializedSupportStatement = `${firstSpec}, ${middleSpecs}, and ${lastSpec}`
      .replace('lgbtqia', 'LGBTQIA');
  }

  return specializedSupportStatement;
}

export function parseTherapistTemplate(json: ITherapistTemplateApiResponse): ITherapistTemplate {
  const specializedSupportList = generateSpecializedSupportList(
    json.ages,
    json.services_offered,
    json.specialties
  );

  return {
    userId: json.user_id,
    personaProviderId: json.persona_provider_id,
    platformIdentity: json.provider_platform_identity,
    firstName: json.first_name,
    middleName: json.middle_name,
    lastName: json.last_name,
    initials: json.initials,
    fullName: json.full_name,
    displayFirstName: json.display_first_name,
    displayName: json.display_name,
    pronouns: json.pronouns,
    therapistGender: json.therapist_gender,
    gendersServed: json.genders_served,
    profilePhotoUrl: json.profile_photo_url,
    phoneNumber: json.phone_number,
    proxyPhoneNumberFeatureFlag: json.proxy_phone_number_feature_flag,
    email: json.email,
    bio: json.bio,
    bioExcerpt: json.bio_excerpt,
    practiceName: json.practice_name,
    psychiatrist: json.psychiatrist,
    careProviderType: json.psychiatrist ? 'Psychiatric provider' : 'Therapist',
    locations: json.locations
      ? json.locations.map((loc) => camelCaseTransformer<ITherapistLocation>(loc))
      : [],
    practiceState: json.practice_state,
    certifications: json.certifications,
    certificationsAbbrev: json.certifications?.map((cert) => cert.abbrev).join(', '),
    specialties: json.specialties,
    topSpecialties: json.top_specialties,
    matchingSpecialties: json.matching_specialties,
    insurances: json.insurances,
    matchingInsurances: json.matching_insurances,
    noPhysicalLocation: json.no_physical_location,
    directSchedulingEnabled: json.direct_scheduling_enabled === DirectScheduleStatus.ENABLED,
    matches: json.matches?.map((match) => (
      {
        ...match,
        match_highlights: Object.entries(match?.match_highlights ?? {}).reduce((x, [key, value]) => {
          const newObj = x;
          newObj[key] = [];
          value.forEach((matchHighlight) => {
            const newMatchHighlight = <IMatchHighlight> {
              tag: matchHighlight.tag,
              title: matchHighlight.title,
              key
            };
            newObj[key].push(newMatchHighlight);
          });
          return newObj;
        }, {} as Record<MatchHighlightKey, IMatchHighlight[]>)
      } as ITherapistMatch)
    ),
    chatProfileUuid: json.chat_profile_uuid,
    nonClinicalChatRoomUuid: json.non_clinical_chat_room_uuid,
    schedulingMinimumAdvanceHours: json.scheduling_minimum_advance_hours,
    schedulingMaximumAdvanceWeeks: json.scheduling_maximum_advance_weeks,
    timeProposalMinimumAdvanceHours: json.time_proposal_minimum_advance_hours,
    timeProposalMaximumAdvanceWeeks: json.time_proposal_maximum_advance_weeks,
    directSchedulingSessionDuration: json.direct_scheduling_session_duration,
    directSchedulingStartInterval: json.direct_scheduling_start_interval,
    directSchedulingBuffer: json.direct_scheduling_buffer,
    sessionTypesOffered: json.session_types_offered,
    treatmentApproaches: json.treatment_approaches,
    servicesOffered: json.services_offered,
    ages: json.ages,
    inScheduledState: json.in_scheduled_state,
    hasPendingPatSession: json.has_pending_pat_session,
    hasScheduledSession: json.has_scheduled_session,
    proposeATimeEnabled: json.propose_a_time_enabled,
    proposeATimeForInvitedClientsFeatureFlag: json.propose_a_time_for_invited_clients_feature_flag,
    isTopRecommendedMatch: !!json.matches?.some(
      (m) => m.match_assignment === MatchAssignmentType.TOP_RECOMMENDED
    ),
    // NOTE: (PE-23276) specialized support experiment
    specializedSupportList,
    specializedSupportStatement: generateSpecializedSupportStatement(specializedSupportList),
    allowsConsultation: json.allows_consultation
  };
}
