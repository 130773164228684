import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, filter } from 'rxjs/operators';

export interface Flags {
  [key: string]: any;
}

@Injectable({ providedIn: 'root' })
export class FeatureFlags {
  private flags = new BehaviorSubject<Flags | null>(null);

  register(flags: Flags): void {
    this.flags.next(flags);
  }

  check(name: string): boolean | undefined {
    return this.checkInFlags(name, this.flags.getValue());
  }

  check$(name: string): Observable<boolean | undefined> {
    return this.flags.pipe(
      filter((flags) => flags != null),
      map((flags) => this.checkInFlags(name, flags))
    );
  }

  private checkInFlags(name: string, flags: Flags | null): boolean {
    return Boolean(flags?.[name]);
  }
}
