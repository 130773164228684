/**
 * Common tags that can be applied to a client user. Sent up via the `tags` list on the User object:
 * import { User } from '@sondermind/utilities/models-user-client';
 */
export enum ClientUserTags {
  VA_CLIENT = 'VA client',
  PSYCHIATRY_CLIENT = 'PSYCHIATRY CLIENT',
  ONLINE_SCHEDULING_BADGE = 'EXPERIMENT BADGE ONLINE SCHEDULING',
  TREATMENT_APPROACH_BADGE = 'EXPERIMENT BADGE TREATMENT APPROACH',
  PSL_SOURCED = 'PSL client'
}
