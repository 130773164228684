import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private cache = {};
  private reported = false;

  set(key: string, value: any): void {
    this.setItem(key, JSON.stringify(value));
  }

  setItem(key: string, value: string): void {
    this.cache[key] = value;
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      this.reportError(e);
    }
  }

  get(key: string, def: any = null): any | null {
    try {
      const str = this.getItem(key);
      if (str) {
        return JSON.parse(str);
      }
      return def;
    } catch (SyntaxError) {
      return def;
    }
  }

  getItem(key: string): string {
    try {
      this.cache[key] = localStorage.getItem(key);
    } catch (e) {
      this.reportError(e);
    }

    return this.cache[key];
  }

  removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      this.reportError(e);
    }

    delete this.cache[key];
  }

  private reportError(e) {
    if (this.reported) { return }

    console.error('local storage access is failing, falling back to cache', e);
  }
}
