import { Inject, Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import {
  BehaviorSubject, Observable, of, Subject
} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap, takeUntil,
  tap
} from 'rxjs/operators';
import jwt_decode from 'jwt-decode';

import { GtmDataLayerService } from '@sondermind/google-tag-manager';
import { LAUNCH_DARKLY_SERVICE, LaunchDarklyClientUser, LaunchDarklyService } from '@sondermind/launch-darkly';
import { AUTH_SERVICE, IAuthService } from '@sondermind/utilities/models-auth';
import { IClientMatchingProfile } from '@sondermind/utilities/models-matching';

import { AppErrorHandler } from '@sondermind/error-handling';
import { ClientUser, ClientUserProfile } from '@sondermind/utilities/models-user-client';
import { BillingClientProfile, UserDataService } from '@sondermind/data-access/client-facing/user-data';

import { ClientUserTags } from '../enums/client-user-tags.enum';
import { MatchingProfileHttpService } from './matching-profiles.service';

interface ITokenInfo {
  sub: string;
}

@Injectable({
  providedIn: 'root'
})
export class CurrentUser implements Resolve<ClientUser> {
  constructor(
    private userData: UserDataService,
    @Inject(AUTH_SERVICE)
    private authSvc: IAuthService,
    private matchingProfileSvc: MatchingProfileHttpService,
    @Inject(LAUNCH_DARKLY_SERVICE)
    private launchDarklyService: LaunchDarklyService,
    private gtmDataLayerService: GtmDataLayerService,
    private errorHandler: AppErrorHandler
  ) {
    this.user$.subscribe((user) => {
      this.errorHandler.handleUserChange(user?.id?.toString(), user?.email);
    });
  }

  private userSub = new BehaviorSubject<ClientUser>(null as unknown as ClientUser);
  private pslClient = new BehaviorSubject<boolean>(false);
  private matchingProfileSubj: BehaviorSubject<IClientMatchingProfile> = new BehaviorSubject<IClientMatchingProfile>(null as unknown as IClientMatchingProfile);
  private multipayorSub = new BehaviorSubject<boolean>(false);
  private initialized = false;
  private destroyed$ = new Subject<void>();
  private accountCompleteSubj = new BehaviorSubject<boolean>(false);
  private isFirstLogin = new BehaviorSubject<boolean>(false);

  user$ = this.userSub.asObservable();
  isPslClient$ = this.pslClient.asObservable();
  isMultipayor$ = this.multipayorSub.asObservable();
  matchingProfile$: Observable<IClientMatchingProfile> = this.matchingProfileSubj.asObservable();
  loggedIn$ = this.user$.pipe(map((u) => !!u));
  accountComplete$ = this.accountCompleteSubj.asObservable().pipe(distinctUntilChanged());
  isVeteransAffairs: boolean = false;
  isPsychiatryClient: boolean = false;
  isHideProviderPhoto: boolean = false;
  isFirstLogin$ = this.isFirstLogin.asObservable();

  resolve(): ClientUser | Observable<ClientUser> {
    if (this.initialized) {
      return this.userSub.getValue();
    }
    return this.user$;
  }

  get user(): ClientUser {
    return this.userSub.getValue();
  }

  get isPslClient(): boolean {
    return this.pslClient.getValue();
  }

  get isMultipayor(): boolean {
    return this.multipayorSub.getValue();
  }

  get loggedIn(): boolean {
    return this.authSvc.isAuthenticated() && !!this.userSub.getValue();
  }

  get personaUri(): string {
    return `sondermind://persona_client/${this.user.personaId}`;
  }

  get allowScheduling(): boolean {
    return this.loggedIn && this.user.allowScheduling;
  }

  get accountComplete(): boolean {
    return this.user != null && !this.user.needsSetup;
  }

  get matchingProfileData(): IClientMatchingProfile {
    return this.matchingProfileSubj.getValue();
  }

  get mostRecentReferralUnserviceable(): boolean {
    return this.user?.mostRecentReferralUnserviceable;
  }

  initialize$(): Observable<void> {
    // when oauth completes a round, refetch the user
    this.authSvc.authFlowComplete$.pipe(
      this.authSvc.initialized$.waitO(),
      switchMap((auth) => (auth ? this.fetch$() : of(null))),
      takeUntil(this.destroyed$),
    ).subscribe((user) => this.refresh(user as unknown as ClientUser));

    // when oauth is destroyed, reset the user
    this.authSvc.authDestroyed$.pipe(
      this.authSvc.initialized$.waitO(),
      takeUntil(this.destroyed$),
    ).subscribe(() => this.refresh(null as unknown as ClientUser));

    // make user fetching a part of the initialization pipe
    return this.authSvc.initialize().pipe(
      switchMap((auth) => (auth ? this.fetch$() : of(null))),
      tap((user) => {
        if (user) {
          // TODO: this doesn't seem to get called on first login after password set
          this.loadMatchingProfile();
          this.checkABTags(user);
          this.checkIdentifierTags(user);

          // Not needed on first login - looking for claims data, which can only be present after
          // a client has a scheduled therapist
          this.getClientConversionData();
          const ldUser = new LaunchDarklyClientUser(user);
          this.launchDarklyService.initClient(ldUser);
        }

        this.refresh(user as unknown as ClientUser);
        this.initialized = true;
      }),
      switchMap((u) => (u ? this.reloadMultipayor$(u) : of(null))),
      tap((allowMultipayor) => this.refreshMultipayor(!!allowMultipayor)),
      map(() => undefined)
    );
  }

  // get matching profile data to add to user object
  loadMatchingProfile(): void {
    this.matchingProfileSvc.getMatchingProfileData().subscribe((resp) => {
      this.matchingProfileSubj.next(resp);
    });
  }

  // tear down the CurrentUser service
  destroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // noreturn
  login(returnTo: string): void {
    this.authSvc.login(returnTo ?? null);
  }

  logout$(): Observable<void> {
    return this.authSvc.logout();
  }

  setHasVisitedAccountSetup(personaClientId: number): void {
    this.userData.sendVisitedAccountSetupUrl(personaClientId);
  }

  getClientConversionData(): void {
    this.userData.getClientConversionData()
      .subscribe((hasClaim) => {
        if (hasClaim) {
          this.gtmDataLayerService.sendClientClaimExists();
        }
      });
  }

  refresh(user: ClientUser): void {
    this.accountCompleteSubj.next(user != null && !user.needsSetup);
    this.userSub.next(user);
  }

  refreshProfile(profile: ClientUserProfile): void {
    this.userSub.next({ ...this.userSub.value, profile });
  }

  refreshMultipayor(allowsMultipayor: boolean): void {
    this.multipayorSub.next(allowsMultipayor);
  }
  getPlatformUuid(): string {
    const token = this.authSvc.getAuthToken();
    if (!token) return '';
    const decoded: ITokenInfo = jwt_decode(token);
    return decoded?.sub || '';
  }
  reload$(): Observable<void> {
    const isAccountComplete = this.accountComplete;
    // on forbidden, null out the user (not logged in)
    return this.fetch$().pipe(
      tap((u) => {
        this.checkABTags(u);
        this.checkIdentifierTags(u);
        this.loadMatchingProfile();
        const ldUser = new LaunchDarklyClientUser(u);
        this.launchDarklyService.initClient(ldUser);
        this.refresh(u);
      }),
      switchMap((u) => this.reloadMultipayor$(u)),
      tap((allowMultipayor) => this.refreshMultipayor(!!allowMultipayor)),
      tap(() => {
        // check if the user has newly completed their account
        // send event to GTM
        if (!isAccountComplete && this.accountComplete) {
          this.gtmDataLayerService.sendUserCompletedAccount();
        }
      }),
      map(() => undefined)
    );
  }

  reloadMultipayor$(user: ClientUser): Observable<boolean | unknown> {
    return this.userData.billingProfileAllowsMultipayor(user.personaId);
  }

  setIsPslClient(isPslClient: boolean) {
    this.pslClient.next(isPslClient);
  }
  updateBillingClientProfile$(data: Partial<BillingClientProfile>): Observable<void> {
    if (this.user) {
      return this.userData.updateBillingClientProfileRequest(this.user.personaId, data).pipe(
        tap((allowsMultipayor) => this.multipayorSub.next(!!allowsMultipayor)),
        map(() => undefined)
      );
    }
    return this.reload$().pipe(
      switchMap(() => this.userData.updateBillingClientProfileRequest(this.user.personaId, data)),
      tap((allowsMultipayor) => this.multipayorSub.next(!!allowsMultipayor)),
      map(() => undefined)
    );
  }

  setFirstLogin(): void {
    this.isFirstLogin.next(true);
  }

  private fetch$(): Observable<ClientUser> {
    return this.userData.fetch({ null401: true }) as Observable<ClientUser>;
  }

  /**
   * Helper function for setting flags for A/B tests.
   *
   * EXPERIMENT HIDE PROVIDER PHOTO - PE-20447
   *
   */
  private checkABTags(user: ClientUser): void {
    this.isHideProviderPhoto = user.tags?.includes('EXPERIMENT HIDE PROVIDER PHOTO');
  }

  /**
   * Helper function to set flags based on tags not associated with AB tests.
   */
  private checkIdentifierTags(user: ClientUser): void {
    this.isVeteransAffairs = user.tags?.includes(ClientUserTags.VA_CLIENT);
    this.isPsychiatryClient = user.tags?.includes(ClientUserTags.PSYCHIATRY_CLIENT);
  }
}
