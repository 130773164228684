import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from '@sondermind/http';
import { IAPIFetchRequestOptions, IAPIListResponse } from '@sondermind/utilities/models-http';
import {
  IClientMatchingProfile,
  IClientMatchingProfileApiResponse,
  clientMatchingResponseTransformer
} from '@sondermind/utilities/models-matching';
import { ConfigurationService } from '@sondermind/configuration';
import { IProviderNetworkCompany } from '@sondermind/utilities/models-insurance/client';

@Injectable({
  providedIn: 'root'
})
export class MatchingProfileHttpService {
  urlBase: string = `${this.configurationService.env.svcBase}/referrals/matching_profiles`;

  constructor(
    private configurationService: ConfigurationService,
    private http: HttpService
  ) {}

  /**
   * Retrieve all matching profile data associated with a client user.
   * If a client does not have a matching profile (such as for provider-invited),
   * return an empty profile.
   */
  getMatchingProfileData(opts: Partial<IAPIFetchRequestOptions> = {}): Observable<IClientMatchingProfile> {
    const params = this.http.generateListParams(opts);

    return this.http
      .get<IAPIListResponse<IClientMatchingProfileApiResponse>>(this.urlBase, params)
      .pipe(
        // Only use the most recent NCR for the matching profile
        map((resp) => (resp?.data?.length > 0
          ? clientMatchingResponseTransformer(resp.data[resp.data.length - 1])
          : {
            providerNetworkCompanies: [] as IProviderNetworkCompany[],
            reasons: [] as Array<{ name: string; desc: string; }>
          } as IClientMatchingProfile
        )),
        this.http.handleHttpError(opts.error)
      );
  }
}
