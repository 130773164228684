import { Injectable } from '@angular/core';

import {
  BehaviorSubject, Observable, from, of
} from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import * as LaunchDarklyClient from 'launchdarkly-js-client-sdk';
import { v4 as uuidv4 } from 'uuid';

import { ConfigurationService, ILaunchDarklyAppConfig } from '@sondermind/configuration';
import { ResponseUpdateItem } from '@sondermind/utilities/models-flows';
import { FlowsLaunchDarklyFeatureFlags, ILaunchDarklyService } from '@sondermind/launch-darkly';
import { UserType } from '../models/launch-darkly-user.enum';

@Injectable()
export class FlowsLaunchDarklyService implements ILaunchDarklyService {
  uuid: string = '';
  userType = UserType.CLIENT;
  client: LaunchDarklyClient.LDClient | undefined;
  ldFlagSubject: BehaviorSubject<LaunchDarklyClient.LDFlagSet> = new BehaviorSubject<LaunchDarklyClient.LDFlagSet>({});
  ldFlag$: Observable<LaunchDarklyClient.LDFlagSet> = this.ldFlagSubject.asObservable();
  // Used if LaunchDarkly is telling us what slug to use in Match Flow
  ldSlug: string = '';

  constructor(
    private configurationService: ConfigurationService<ILaunchDarklyAppConfig>,
  ) { }

  /**
   * If a client already exists, close it.
   * If a UUID doesn't exist, get one.
   * Connect to LaunchDarkly, but fail silently if connection fails.
   * We don't want LD issues to prevent any Match Flow functionality.
   */
  initClient(): Observable<null> | Observable<undefined> {
    if (this.client) { this.client.close().catch(() => {}) }
    if (!this.uuid) { this.fetchOrGenerateUuid() }

    // NOTE: there is a `Shared Local` environment on LaunchDarkly for general local development
    // if you want to use a personal LD environment, temporarily put your key
    const launchDarklyClientSdkId = this.configurationService.env.app?.launchDarklyClientSideId;

    if (launchDarklyClientSdkId) {
      this.client = LaunchDarklyClient.initialize(
        launchDarklyClientSdkId,
        { key: this.uuid, custom: { userType: this.userType } }, { allAttributesPrivate: true }
      );

      return from(this.client.waitForInitialization()).pipe(
        tap(() => {
          this.ldSlug = this.client?.variation(FlowsLaunchDarklyFeatureFlags.MATCH_FLOW_SLUG, '') as string;

          // variationDetail is passed a default value as the second argument,
          // but UI handling of showing vs hiding features should also take into account case where flag is `undefined`,
          // for the fringe case of failing to connect to LaunchDarkly
          const flags = {} as { [key: string]: boolean | string; };
          flags[FlowsLaunchDarklyFeatureFlags.MATCH_FLOW_SLUG] = this.client?.variation(
            FlowsLaunchDarklyFeatureFlags.MATCH_FLOW_SLUG,
            ''
          ) as string;

          flags[FlowsLaunchDarklyFeatureFlags.CALL_COMPONENT] = this.client?.variation(
            FlowsLaunchDarklyFeatureFlags.CALL_COMPONENT,
            false
          ) as boolean;

          // only emit if flag values have changed
          if (this.flagValueChanged(flags)) {
            this.ldFlagSubject.next(flags);
          }
        }),
        catchError(() => of(undefined)),
        map(() => undefined)
      );
    }

    return of(null);
  }

  /**
   * TODO: (PE-23675) check for existance of UUID cookie, use it if it exists
   * Generate and set UUID for session.
   */
  fetchOrGenerateUuid(): void {
    this.uuid = uuidv4();
  }

  /**
   * Compare current ldFlag$ to new ones fetched to determine if any have changed.
   */
  flagValueChanged(newFlags: { [key: string]: boolean | string; }): boolean {
    const currentFlags = this.ldFlagSubject.getValue();
    const flagKeys = Object.keys(newFlags);

    return !!flagKeys.find((key) => currentFlags[key] !== newFlags[key]);
  }

  /**
   * Generate the LaunchDarkly-related data that should be included in raw form data for use with processors, etc.
   * Add additional flags or data here as needed.
   */
  getInitData(): ResponseUpdateItem[] {
    const uuidInitData = {
      human: 'LaunchDarkly key',
      key: 'experimentationKey',
      value: this.uuid,
    };

    const callComponentData = {
      human: 'LaunchDarkly call component test',
      key: 'callComponent',
      value: this.ldFlagSubject.getValue()[FlowsLaunchDarklyFeatureFlags.CALL_COMPONENT] as boolean,
    };

    return [uuidInitData, callComponentData];
  }

  /**
   * Used to send an event to LaunchDarkly on completion of Math Flow.
   * Generally all other analysis such as drop off should be done via Raw Form Data,
   * but if we need to expand custom metrics in Match Flow,
   * see the LaunchDarklyService implementation in Client Portal.
   * Usage of ? is probably overly cautious, but prevents errors when client has not been initializd.
   *
   * NOTE: For simplicity this is called from the ResendComponent, which loads after a successful MF submission.
   * If that final page ever changes, the location of where this is called will also need to change.
   */
  sendMatchFlowSubmitEvent(): void {
    this.client?.track('match-flow-submitted');
  }

  /**
   * Used to close the LDClient connection when the AppBootComponent is destroyed.
   */
  closeClient(): void {
    this.client?.close();
  }
}
