import { Inject, Optional } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import {
  HTTP_ERROR_HANDLER,
  IHttpErrorHandler,
} from '@sondermind/utilities/models-http';

import { defaultHandleHttpError } from '../utilities/default-error-handler.utility';

export interface HandleErrorOptions {
  null404: boolean;
  null401: boolean;
}

export abstract class BaseHTTPService {
  constructor(
    @Optional()
    @Inject(HTTP_ERROR_HANDLER)
    protected errorHandlers: IHttpErrorHandler[]
  ) { }

  static defaultHandleErrorOptions: HandleErrorOptions = {
    null401: false,
    null404: true,
  };

  protected handleError<T>(
    opts: Partial<HandleErrorOptions> = {}, err: HttpErrorResponse | Error
  ): Observable<T | null> {
    // when an error is raised in the observable pipe
    // since it occured outside of the  HTTP stuff, it'll be an Error, not HttpErrorResponse
    if (err instanceof Error) {
      console.error(err);
      return throwError({ base: ['Something didn\'t work, please try again.'] });
    }

    // otherwise, let the generic http error handler take it
    const incoming: HandleErrorOptions = { ...opts, ...BaseHTTPService.defaultHandleErrorOptions };
    const errOptions = { ...incoming, rawErrors: false };

    if (this.errorHandlers) {
      // eslint-disable-next-line no-restricted-syntax
      for (const handler of this.errorHandlers) {
        const next = handler.handleHttpError<T>(err, errOptions);
        if (next) {
          return next;
        }
      }
    }

    // no idea at this point, really
    return defaultHandleHttpError(err, errOptions);
  }
}
