export enum GtmCustomEvents {
  CLIENT_REGISTRATION = 'client-registration',
  CLIENT_SAM_SIGNUP = 'client-sam-signup',
  MATCH_FLOW = 'match-flow',
  MATCH_FLOW_DEFAULT = 'match-flow-default',
  CAL_CONNECT_CLICKED = 'calendar-events',
  CAL_EVENT_CLICKED = 'calendar-event-clicked',
  CTA_ELEMENT_CLICKED = 'cta-element-clicked',
  USER_SETTINGS_DEFAULT_FONT_SIZE = 'user-settings-default-font-size',
  CLIENT_VIDEO_SESSION_LAUNCHED = 'video-session',
  CLIENT_ACCOUNT_COMPLETE = 'client-account-complete',
  CLIENT_THERAPY_READINESS = 'client-therapy-readiness',
  CLIENT_CLAIM_SUBMITTED = 'client-claim-submitted',
  PROVIDER_PAGE_VIEW = 'provider-page-viewed',
  PROVIDER_DIRECTORY_INITIAL_STATE = 'provider-directory-initial-state',
  PROVIDER_DIRECTORY_VIEW_PROFILE = 'provider-directory-view-profile',
  PROVIDER_DIRECTORY_LOAD_NEW_PROVIDERS = 'provider-directory-load-new-providers',
  PROVIDER_DIRECTORY_SHOW_MORE = 'provider-directory-show-more',
  PROVIDER_DIRECTORY_REQUEST_SESSION = 'provider-directory-request-session'
}

export enum GtmIntakeActions {
  SETUP_COMPLETE = 'Setup Complete',
  SUBMIT_THERAPY_READINESS = 'Submit Ready for Therapy',
  CLAIM_SUBMITTED = 'Claim Submitted for Client'
}

export enum GtmExternalCalActions {
  CLICK_ADD_EXTERNAL_CALS = 'Click button to add an external calendar',
  SUCCESSFULLY_CONNECT_EXTERNAL = 'Successfully connected external calendar',
  ERROR_CONNECTING_EXTERNAL = 'Error connecting to external calendar',
  CLICK_EXTERNAL_EVENT = 'Clicked on an external event',
  CLICK_EVENT = 'Clicked an internal event',
  DISCONNECT_EXTERNAL_CAL = 'Disconnected an external calendar'
}
