import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconColors, IconSizes, IrisIconModule } from '@sondermindorg/iris-design-system-angular';

import { LandingPageContact } from '../../models/enums/landing-page-contact.enum';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IrisIconModule,
  ],
  selector: 'support-phone-number-card',
  templateUrl: './support-phone-number-card.component.html',
  styleUrls: ['./support-phone-number-card.component.scss'],
})

export class SupportPhoneNumberCardComponent {
  supportPhoneNumberLink: string = LandingPageContact.MATCH_BY_PHONE_LINK;
  supportPhoneNumberDisplay: string = LandingPageContact.MATCH_BY_PHONE_DISPLAY;

  iconSize = IconSizes.LG;
  iconColor = IconColors.BRAND1;
}
