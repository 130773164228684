import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  catchError, combineLatest, from,
  Observable,
  of, shareReplay,
  Subject,
  take,
  takeUntil,
} from 'rxjs';

import { ConfigurationService, IProviderShareableLinkAppConfig } from '@sondermind/configuration';
import { GtmCustomEvents, GtmDataLayerService } from '@sondermind/google-tag-manager';
import { LAUNCH_DARKLY_SERVICE, LaunchDarklyService, LDBooleanFeatureFlags } from '@sondermind/launch-darkly';
import { SegmentService } from '@sondermind/segment';
import { IAPIFetchResponse } from '@sondermind/utilities/models-http';
import { WINDOW } from '@sondermind/utilities/tools';

import { IPslPageLoadEventData } from '../../models/segment-event-data.interface';
import {
  IPslSegmentSchema,
  PslSegmentEvents,
} from '../../models/segment/psl-segment-schema.interface';
import { IProviderShareableProfile, StateInfoType } from '../../shareable-link.interfaces';
import { ShareableLinksHttpService } from '../../shareable-links.httpservice';

@Component({
  selector: 'landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})

export class LandingPageComponent {
  providerShareableProfile: IProviderShareableProfile = {} as IProviderShareableProfile;
  isValid: boolean = false;
  experienceSupportingList: string[] = [];
  isLoading: boolean = true;
  therapyMatchFlowUrl: string = 'https://start.sondermind.com';
  isBioTruncated: boolean = false;
  providerBio: string = '';
  allSortedInsurances: string[] = [];
  queryParamMap!: ParamMap;
  routeParamMap!: ParamMap;
  showSimilarProvidersFf$: Observable<boolean>;
  useCheckoutFormFf$: Observable<boolean>;

  destroyed$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private shareableLinksHttpService: ShareableLinksHttpService,
    private configurationService: ConfigurationService<IProviderShareableLinkAppConfig>,
    private titleService: Title,
    private metaService: Meta,
    private gtmDataLayerService: GtmDataLayerService,
    private segmentService: SegmentService<IPslSegmentSchema>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LAUNCH_DARKLY_SERVICE) private launchDarklyService: LaunchDarklyService,
    @Inject(WINDOW) private window: Window
  ) {}

  ngOnInit(): void {
    combineLatest([this.route.paramMap, this.route.queryParamMap]).pipe(
      take(1),
    ).subscribe(([params, queryParams]) => {
      this.routeParamMap = params;
      this.queryParamMap = queryParams;
      this.therapyMatchFlowUrl = this.configurationService.env.app.flowHost;

      this.shareableLinksHttpService.fetch$(this.routeParamMap.get('share_code')).pipe(
        takeUntil(this.destroyed$),
        catchError((error) => of(error))
      ).subscribe((response: IAPIFetchResponse<IProviderShareableProfile>) => {
        if (Object.prototype.hasOwnProperty.call(response, 'error')) {
          this.isLoading = false;
          return;
        }

        this.providerShareableProfile = response.data;
        this.gtmDataLayerService.dataLayer = {
          event: GtmCustomEvents.PROVIDER_PAGE_VIEW,
          id: this.providerShareableProfile.id,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          full_name: this.providerShareableProfile.full_name
        };
        this.sendPageViewSegmentEvent();

        this.updateMetaInfo(
          this.providerShareableProfile.display_name,
          this.providerShareableProfile.certifications,
          this.providerShareableProfile.bio
        );

        this.updateCanonicalUrl(window.location.href);

        this.isValid = true;
        this.isLoading = false;
      });
    });

    this.checkFeatureFlags();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  scrollToElement(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  private checkFeatureFlags(): void {
    this.showSimilarProvidersFf$ = this.launchDarklyService
      .checkFlagEnabled$(LDBooleanFeatureFlags.PSL_SHOW_SIMILAR_PROVIDERS)
      .pipe(shareReplay(1));

    this.useCheckoutFormFf$ = this.launchDarklyService
      .checkFlagEnabled$(LDBooleanFeatureFlags.PSL_USE_CHECKOUT_FORM)
      .pipe(shareReplay(1));
  }

  private sendPageViewSegmentEvent(): void {
    const activeLaunchDarklyFlags: { [key: string]: unknown; } = {};
    this.launchDarklyService.getAllFlags$().pipe(take(1)).subscribe((flags) => {
      // For every active FF we want to track on this page, add the key + FF value to the map
      const trackingFlags = [
        LDBooleanFeatureFlags.PSL_SHOW_SIMILAR_PROVIDERS,
        LDBooleanFeatureFlags.FIND_PROVIDER_PUBLIC_HEADER_REDIRECT,
        LDBooleanFeatureFlags.PSL_SEND_REQUEST_A_SESSION_EMAIL
      ];
      trackingFlags.forEach((flagName) => {
        activeLaunchDarklyFlags[flagName] = flags[flagName] ?? false;
      });

      // Get UTM parameters
      const urlParams = new URLSearchParams(this.window.location.href);
      const utmParams = Array.from(urlParams.entries()).filter(([key, value]) => key.startsWith('utm'));

      // Get params from directory, if they exist
      const directoryFilters = {
        state: this.queryParamMap.get('state'),
        insurance: this.queryParamMap.get('paymentTag'),
        modalities: this.queryParamMap.get('modalities'),
        specialties: this.queryParamMap.get('specialties'),
        genders: this.queryParamMap.get('genders'),
      };

      // Cut off any query params
      const referringDomain = this.document.referrer.slice(0, this.document.referrer.indexOf('?'));
      const referringUrl: string = this.queryParamMap.get('referringUrl') ?? '';

      const eventData = {
        accepting: this.providerShareableProfile.accepting_clients,
        activeLaunchDarklyFlags,
        directoryFilters,
        internalReferringPage: referringUrl,
        personaProviderId: this.providerShareableProfile.id,
        referringDomain,
        utm: utmParams,
      } as IPslPageLoadEventData;

      from(this.segmentService.track<PslSegmentEvents.PSL_PAGE_VIEW>(
        PslSegmentEvents.PSL_PAGE_VIEW,
        eventData
      )).subscribe();
    });
  }

  private updateMetaInfo(
    providerName: string,
    certifications: Array<{ name: string; abbrev: string; }>,
    bio: string
  ): void {
    const certs: string = certifications?.map((obj) => obj.name).join(', ');
    const title = `${providerName}, ${certs} | SonderMind`;
    // extract the first sentence of the bio;
    const sentence = /[^.!?]*[.!?]/.exec(bio);
    const firstSentenceofBio = sentence ? sentence[0] : bio;
    const first160CharsofBio = firstSentenceofBio ? firstSentenceofBio.substring(0, 159) : ''; // bio can be null
    const description = `${firstSentenceofBio ?? ''}`;

    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ property: 'og:url', content: this.window.location.href });
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:description', content: first160CharsofBio });
    this.metaService.updateTag({ property: 'twitter:url', content: this.window.location.href });
    this.metaService.updateTag({ name: 'twitter:title', content: title });
    this.metaService.updateTag({ name: 'twitter:description', content: first160CharsofBio });
  }

  private updateCanonicalUrl(url: string): void {
    let link: HTMLLinkElement = this.document.querySelector('link[rel="canonical"]');
    if (link) {
      link.href = url;
    } else {
      link = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', url);
      this.document.head.appendChild(link);
    }
  }
}
