import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IAPIFetchRequestOptions } from '@sondermind/utilities/models-http';
import { HttpService } from '@sondermind/http';
import { ConfigurationService } from '@sondermind/configuration';

import {
  IQuickCheckoutApiResponse,
  IQuickCheckoutReferralCreationRequest
} from '../models/quick-checkout-form.model';
import {
  IResendEmailVerificationRequest,
  IResendEmailVerificationResponse
} from '../models/resend-email-verification.model';

@Injectable({
  providedIn: 'root'
})
export class QuickCheckoutHttpService {
  constructor(
    private httpService: HttpService,
    private configurationService: ConfigurationService
  ){}

  /* TODO in ORCA-926
  resendEmailVerification$(
    body: IResendEmailVerificationRequest,
    opts: Partial<IAPIFetchRequestOptions> = {}
  ): Observable<IResendEmailVerificationResponse> {
    // TODO: What is this url?
    const apiUrl = `${this.configurationService.env.svcBase}/quick_checkout/hypothetical_url`;
    const params = this.httpService.generateFetchParams(opts);

    return this.httpService.post<IAPIFetchResponse<IResendEmailVerificationResponse>>(apiUrl, body, params)
      .pipe(
        this.httpService.handleHttpError()
      );
  }
  */

  submitQuickCheckout$(
    body: IQuickCheckoutReferralCreationRequest,
    opts: Partial<IAPIFetchRequestOptions> = {}
  ): Observable<IQuickCheckoutApiResponse | null> {
    // TODO: Change URL with changes in ORCA-927
    const apiUrl = `${this.configurationService.env.dataExchangeSvcBase}/public/v1/referrals`;
    const params = this.httpService.generateFetchParams(opts);

    return this.httpService.post<IQuickCheckoutApiResponse>(apiUrl, body, params)
      .pipe(
        this.httpService.handleHttpError()
      );
  }
}
