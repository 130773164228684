import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@sondermind/utilities/models-environment';
import { Logger } from '@sondermind/datadog-logging';
import { LocalStorageService } from '@sondermind/local-storage';
import { NativeAppContextHelpers } from '@sondermind/native-app-interface';
import { SentryClient } from '@sondermind/sentry-client';

export const SUPPRESS_ERRORS: RegExp[] = [
  /Firebase: Firebase App named '\[DEFAULT\]' already exists/,
  /Missing Stripe.js!/
];

export const RELOAD_ERROR: RegExp = /ChunkLoadError: Loading chunk (.*) failed/;

export const VIDEO_SESSION_ACTIVE = 'video-session-active';

@Injectable({
  providedIn: 'root'
})
export class AppErrorHandler extends ErrorHandler {
  constructor(
    @Inject(ENVIRONMENT)
    private env: Environment,
    private localStorageService: LocalStorageService,
    private logger: Logger
  ) {
    super();
  }

  handleUserChange(id: string | undefined, email: string | undefined): void {
    // if there is no id or email, clear scope
    if (!id && !email) {
      SentryClient.configureScope((scope) => scope.clear());
    } else {
      // otherwise, set the user
      SentryClient.setUser({ id, email });
    }
  }

  override handleError(err: any): void {
    const videoSessionActive = this.localStorageService.getItem(VIDEO_SESSION_ACTIVE);

    // reload the page on chunk loading errors
    if (RELOAD_ERROR.test(err.message)) {
      // log the error with datadog first
      this.logger.log('Chunk loading error, reloading', undefined, 'error');

      if (!videoSessionActive) {
        this.windowLocationReload();
      }
    }

    const suppressed = SUPPRESS_ERRORS.some((pat) => err.message?.match(pat));

    // ignore errors defined in SUPPRESS_ERRORS
    if (err.message && suppressed) {
      return;
    }

    // format http errors slightly differently
    const errorToCapture = err instanceof HttpErrorResponse
      ? `HTTP Error ${err.status} - ${err.message}: ${err.url}`
      : err.originalError || err.error || err;

    // Add tag for whether the user is in native mobile app
    const isNativeApp = NativeAppContextHelpers.isHostedInNativeApp().toString();

    // include special indicator if error occurs during video session.
    if (videoSessionActive) {
      SentryClient.captureException(
        errorToCapture,
        {
          tags: {
            videoSessionActive: 'true',
            isNativeApp
          }
        }
      );
    } else {
      SentryClient.captureException(errorToCapture,
        {
          tags: {
            isNativeApp
          }
        });
    }

    if (!this.env.production) {
      super.handleError(err);
    }
  }

  /**
   * Reloads the window location, abstracted for easier unit testing.
   */
  windowLocationReload(): void {
    window.location.reload();
  }
}
