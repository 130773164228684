<div class="app-container">
  <public-header [providerPracticeState]="providerShareableProfile?.practice_state?.abbrev"></public-header>
  <ng-container *ngIf="!isLoading; else loading">
    <ng-container *ngIf="isValid; else notFound">
      <main class="main-content">
        <landing-page-header
          *ngIf="providerShareableProfile"
          [profile]="providerShareableProfile"
          [routeParams]="routeParamMap"
          [queryParams]="queryParamMap"
          [showSimilarProvidersFf]="(showSimilarProvidersFf$ | async)"
          [useCheckoutFormFf]="(useCheckoutFormFf$ | async)"
        ></landing-page-header>
        <similar-providers-banner
          *ngIf="!providerShareableProfile.accepting_clients && (showSimilarProvidersFf$ | async)"
          [providerProfile]="providerShareableProfile"
          [queryParams]="queryParams"
        ></similar-providers-banner>
    
        <provider-details [providerShareableProfile]="providerShareableProfile"></provider-details>

        <!-- Footer is contained within main to have body + footer be scrollable together -->
        <footer >
          <public-footer 
            [currentStateAbbrev]="providerShareableProfile?.practice_state?.abbrev || null" 
            [matchflowBaseUrl]="therapyMatchFlowUrl">
          </public-footer>
        </footer>
      </main>
    </ng-container>

    <ng-template #notFound>
      <div class="page-not-found-container">
        <page-not-found [therapyMatchFlowUrl]="therapyMatchFlowUrl"></page-not-found>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #loading>
    <mat-spinner [diameter]="40"></mat-spinner>
  </ng-template>
</div>
  