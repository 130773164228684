import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phoneNumber' })
export class PhoneNumberPipe implements PipeTransform {
  /**
   * Format into XXX-XXX-XXXX or (XXX) XXX-XXXX depending on useParans.
   * If phoneNumber is not 10 digits, return without modifying.
   */
  transform(phoneNumber: string | number, useParens: boolean = false) {
    const pn = phoneNumber?.toString();
    const pnRegex = /^\d{10}$/;

    if (!pnRegex.test(pn)) { return phoneNumber }

    const areaCode = pn.substr(0, 3);
    const threeDigit = pn.substr(3, 3);
    const fourDigit = pn.substr(6, 4);

    if (useParens) {
      return `(${areaCode}) ${threeDigit}-${fourDigit}`;
    }
    return `${areaCode}-${threeDigit}-${fourDigit}`;
  }
}
