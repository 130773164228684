import { SpecializedSupport, SpecializedSupportDisplay } from '@sondermind/constants';

export const generateExperienceSupportingList = (
  ages: string[] = [],
  servicesOffered: string[] = [],
  specialties: string[] = []
): string[] => {
  const specializedSupportList: string[] = [];
  // The following if statements are used to set order of specialized topics
  if (ages.find((age) => age === SpecializedSupport.INFANTS || age === SpecializedSupport.CHILDREN)) {
    specializedSupportList.push(SpecializedSupportDisplay.INFANTS);
  }

  if (ages.includes(SpecializedSupport.ADOLESCENTS)) {
    specializedSupportList.push(SpecializedSupportDisplay.ADOLESCENTS);
  }

  if (ages.includes(SpecializedSupport.ADULTS)) {
    specializedSupportList.push(SpecializedSupportDisplay.ADULTS);
  }

  if (ages.includes(SpecializedSupport.SENIORS)) {
    specializedSupportList.push(SpecializedSupportDisplay.SENIORS);
  }

  if (servicesOffered.includes(SpecializedSupport.COUPLES)) {
    specializedSupportList.push(SpecializedSupportDisplay.COUPLES);
  }

  if (specialties.find((spec) => spec === SpecializedSupport.LGBTQIA)) {
    specializedSupportList.push(SpecializedSupportDisplay.LGBTQIA);
  }

  if (specialties.find((spec) => spec === SpecializedSupport.VETERAN)) {
    specializedSupportList.push(SpecializedSupportDisplay.VETERAN);
  }

  if (specialties.find((spec) => spec === SpecializedSupport.PERINATAL)) {
    specializedSupportList.push(SpecializedSupportDisplay.PERINATAL);
  }
  return specializedSupportList;
};
