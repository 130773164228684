import {
  Observable,
  defer,
  from,
  of
} from 'rxjs';
import { NativeAppContext } from '../models/native-app-context.model';

/**
 * These helper functions handle interactions with the "native app context" global object.
 */
export abstract class NativeAppContextHelpers {
  /**
   * Gets the "native app context" global object (defined on `Window.sondermind_native` in the browser).
   * The native app context object serves as the interface
   * between a microfrontend and its native app host (if one exists).
   *
   * @returns the native app context object if the microfrontend is hosted in a native app;
   * otherwise returns undefined.
   */
  static getNativeAppContextObject(): NativeAppContext | undefined {
    return (globalThis as { sondermind_native?: NativeAppContext }).sondermind_native;
  }

  /**
   * Determines if the current running application is hosted in a native application.
   */
  static isHostedInNativeApp(): boolean {
    return !!(NativeAppContextHelpers.getNativeAppContextObject()?.getAppVersion?.());
  }

  /**
   * When hosted in a native application returns the initial auth token passed from the native host to this application
   * (otherwise returns undefined if not hosted in a native application or the auth token does not exist for any reason).
   */
  static getInitialAuthToken(): string | undefined {
    return NativeAppContextHelpers.getNativeAppContextObject()?.getAuthToken?.();
  }

  /**
   * Determines whether a navigation event should proceed.
   * This leverages a callback with the native mobile app.
   * The native app may change tabs - i.e. navigate to a different webview -
   * in which case we should stop the navigation in this app (the current webview).
   */
  static canNavigate$(targetUrl: string): Observable<boolean> {
    const canNavigatePromise = NativeAppContextHelpers.getNativeAppContextObject()?.canNavigate;

    return canNavigatePromise
      ? defer(() => from(canNavigatePromise(targetUrl)))
      : of(true);
  }

  /**
   * Communicates whether a modal is open in the web app, so that
   * the native app can hide its "< back" navigation when appropriate.
   * @param isModalOpen designates whether the modal is opening or closing.
  */
  static onModalChanged(isModalOpen: boolean): void {
    const nativeContext = NativeAppContextHelpers.getNativeAppContextObject();
    if (!nativeContext?.onModalClosed || !nativeContext?.onModalOpened) { return; }

    if (isModalOpen) {
      nativeContext.onModalOpened();
    } else {
      nativeContext.onModalClosed();
    }
  }

  /**
   * Communicates when a the video session modal is opened and we're connected to Twilio.
   * Currently only the Android app provides and depends upon `onAvBtnLaunchClicked`.
   * The params all come from the TwilioVideoToken.
  */
  static onVideoSessionConnected(token: string, roomName: string, displayName: string): void {
    const onAvBtnLaunchClicked = NativeAppContextHelpers.getNativeAppContextObject()?.onAvBtnLaunchClicked;

    if (onAvBtnLaunchClicked) {
      onAvBtnLaunchClicked(token, roomName, displayName);
    }
  }

  /**
   * Communicates matching flow termination message
   * depends upon `onMatchFlowMessage`
  */
  static onMatchingFlowTermination(message: string): void {
    const onMatchFlowMessage = NativeAppContextHelpers.getNativeAppContextObject()?.onMatchFlowMessage;
    if (onMatchFlowMessage) {
      onMatchFlowMessage(message);
    }
  }
}
