<a
  class="provider-listing-container mini"
  [href]="profileLinkUrl"
  target="_blank"
  data-test="provider-card"
>
  <div class="mobile-id-container mini">
    <div class="photo-container">
      <provider-photo
        [initials]="providerListing.display_name | initials"
        [photoUrl]="providerListing.profile_photo_url"
        [providerName]="providerListing.display_name">
      </provider-photo>
    </div>

    <div class="mobile-id-panel mini">
      <div class="name">
        <a
          [href]="profileLinkUrl"
          target="_blank"
          (click)="onClickViewProfile()"
        >
          {{ providerListing.display_name }}
        </a>
        <span class="pronouns" *ngIf="providerListing.pronouns">({{ providerListing.pronouns }})</span>
      </div>

      <div class="insurance">
        <img
          src="../assets/shield.png"
          alt=""
          fetchpriority="low"
        >
        <span>Accepts {{ insurance }}</span>
      </div>
    </div>
  </div>

  <div class="mobile-bio-panel mini">
    <div class="credentials">
      <p *ngFor="let credential of providerListing.certifications">
        {{ credential.name }}
      </p>
    </div>

    <p
      class="specialties"
      *ngIf="specialtyNamesArray.length > 0"
    >
      <span class="label">Specialties include</span> {{ specialtyNamesArray | stringArrayToCommaList: true:'and':2 }}
    </p>

    <div class="session-types">
      <div
        class="labeled-image"
        *ngIf="offersSessionsVideo"
      >
        <img
          src="../assets/videocam.png"
          fetchpriority="low"
          alt=""
        >
        <span>Video sessions</span>
      </div>
      <div
        class="labeled-image"
        *ngIf="offersSessionsInPerson"
      >
        <img
          src="../assets/location.png"
          fetchpriority="low"
          alt=""
        >
        <span>In-person sessions</span>
      </div>
    </div>
  </div>

  <div class="mobile-action-panel mini">
    <div class="button-container" data-test="action-buttons">
        <a
          [href]="profileLinkUrl"
          target="_blank"
          class="iris-button iris-button--secondary view-profile"
          (click)="onClickViewProfile()"
          attr.aria-label="View {{ providerListing.display_name }}'s profile"
        >
          View profile
        </a>
      </div>
  </div>
</a>
