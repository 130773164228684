import { Injectable } from '@angular/core';
import {
  map, Observable, of, shareReplay, tap
} from 'rxjs';

import { ProviderNetworkCompanyHttpService } from '@sondermind/data-access/provider-network-company';
import { indexArray } from '@sondermind/utilities/tools';

import { IInsurance, ProviderNetworkCompany } from '@sondermind/utilities/models-provider-network-company';

@Injectable({ providedIn: 'root' })
export class ProviderNetworkCompanyStore {
  private cachedCompanies$?: Observable<ProviderNetworkCompany[]>;
  private cachedInsuranceOptionsByState: Map<string, IInsurance[]> = new Map<string, IInsurance[]>();

  constructor(private http: ProviderNetworkCompanyHttpService) {}

  /**
   * Fetches the detail view for all ProviderNetworkCompany records.
   * @returns An observable that emits an array of all PNCs
   */
  getAll$(): Observable<ProviderNetworkCompany[]> {
    // eslint-disable-next-line no-return-assign
    return this.cachedCompanies$ ??= this.http.list({
      page: 'disabled',
      view: 'detail'
    }).pipe(
      map((response) => response.data),
      shareReplay(1)
    );
  }

  getInsuranceOptionsByState$(state: string, forceReload = false): Observable<IInsurance[]> {
    const cache = this.cachedInsuranceOptionsByState.get(state);

    return cache && !forceReload
      ? of(cache)
      : this.http.listInsuranceOptions$({
        page: 'disabled',
        filter: { key: 'network_state', value: state }
      }).pipe(
        map((resp) => resp.data.sort((a, b) => a.title.localeCompare(b.title))),
        tap((data) => { this.cachedInsuranceOptionsByState.set(state, data) })
      );
  }

  /**
   * Fetches a lookup record for all ProviderNetworkCompany records.
   * @returns An observable that emits a lookup record for all PNCs
   */
  getLookup$(): Observable<Record<number, ProviderNetworkCompany>> {
    return this.getAll$().pipe(
      map((companies) => indexArray(companies, 'id'))
    );
  }

  reset(): void {
    delete this.cachedCompanies$;
  }
}
