import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@sondermind/utilities/models-environment';
import { IAPIFilterOption } from '@sondermind/utilities/models-http';
import { IDataLayer, IUserLoadedDataLayer } from '../models/gtm-data-layer.interface';
import {
  GtmCustomEvents,
  GtmExternalCalActions,
  GtmIntakeActions,
} from '../models/gtm-events.enum';

declare function trackEvent(eventType: string, details: any): void;

interface IGtmLayerWindow extends Window {
  dataLayer?: IDataLayer[];
}

declare let window: IGtmLayerWindow;

@Injectable({
  providedIn: 'root'
})
export class GtmDataLayerService {
  constructor(
    @Inject(ENVIRONMENT)
    private env: Environment
  ) {}

  set dataLayer(data: IDataLayer) {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!window.dataLayer) {
      window.dataLayer.push(data);
    }
  }

  sendPendoEvent(action: string): void {
    this.dataLayer = {
      event: 'pendo-track-event',
      action
    };
  }

  sendUserCompletedAccount(): void {
    this.dataLayer = {
      event: GtmCustomEvents.CLIENT_ACCOUNT_COMPLETE,
      category: GtmCustomEvents.CLIENT_ACCOUNT_COMPLETE,
      action: GtmIntakeActions.SETUP_COMPLETE,
      label: 'account-complete'
    };
  }

  sendUserTherapyReadiness(eventType: string, label: string): void {
    this.dataLayer = {
      event: eventType,
      category: eventType,
      action: GtmIntakeActions.SUBMIT_THERAPY_READINESS,
      label
    };
  }

  sendClientClaimExists(): void {
    this.dataLayer = {
      event: GtmCustomEvents.CLIENT_CLAIM_SUBMITTED,
      category: GtmCustomEvents.CLIENT_CLAIM_SUBMITTED,
      action: GtmIntakeActions.CLAIM_SUBMITTED,
      label: 'claim-submitted'
    };
  }

  /*
     * wrapper for better unit testing
     */
  getCurrentISOTime(): string {
    const currentDate = new Date();

    return currentDate.toISOString();
  }

  sendCalEvent(action: GtmExternalCalActions): void {
    this.dataLayer = {
      event: GtmCustomEvents.CAL_CONNECT_CLICKED,
      category: GtmCustomEvents.CAL_CONNECT_CLICKED,
      action,
      label: this.getCurrentISOTime()
    };
  }

  sendCalType(calType: GtmExternalCalActions): void {
    this.dataLayer = {
      event: GtmCustomEvents.CAL_EVENT_CLICKED,
      category: GtmCustomEvents.CAL_EVENT_CLICKED,
      type: calType,
      label: this.getCurrentISOTime()
    };
  }

  sendDefaultFontSize(fontSize: string): void {
    this.dataLayer = {
      event: GtmCustomEvents.USER_SETTINGS_DEFAULT_FONT_SIZE,
      category: GtmCustomEvents.USER_SETTINGS_DEFAULT_FONT_SIZE,
      label: fontSize
    };
  }

  sendUserLoadedEvent(values: IUserLoadedDataLayer): void {
    let idWithPrefix: string = '';
    if (this.env.staging) {
      idWithPrefix = `staging-${values.user_id}`;
      // eslint-disable-next-line no-param-reassign
      values.user_id = idWithPrefix;
    }
    this.dataLayer = values;
  }

  sendProviderDirectoryInitialState(state: string, selectedIn: 'route' | 'modal'): void {
    this.dataLayer = {
      event: GtmCustomEvents.PROVIDER_DIRECTORY_INITIAL_STATE,
      category: GtmCustomEvents.PROVIDER_DIRECTORY_INITIAL_STATE,
      providerDirectoryInitialState: state,
      providerDirectoryStateSelectionMethod: selectedIn
    };
  }

  sendProviderDirectorySessionRequested(
    matchFlowUrl: string,
    providerIndex: number,
    providerUrl: string
  ): void {
    this.dataLayer = {
      event: GtmCustomEvents.PROVIDER_DIRECTORY_REQUEST_SESSION,
      category: GtmCustomEvents.PROVIDER_DIRECTORY_REQUEST_SESSION,
      providerDirectoryMatchFlowUrl: matchFlowUrl,
      providerDirectoryIndex: providerIndex,
      providerDirectoryUrl: providerUrl
    };
  }

  sendClickViewProfileEvent(profileLinkUrl: string, pageIndex: number): void {
    this.dataLayer = {
      event: GtmCustomEvents.PROVIDER_DIRECTORY_VIEW_PROFILE,
      category: GtmCustomEvents.PROVIDER_DIRECTORY_VIEW_PROFILE,
      providerDirectoryUrl: profileLinkUrl,
      providerDirectoryIndex: pageIndex
    };
  }

  sendNewDirectoryProviderSetLoadedEvent(selectedFilters: IAPIFilterOption[], providerCount: number): void {
    const selectedStateFilter = selectedFilters.find((f) => f.key === 'practice_state')?.value;
    const selectedGendersFilters = selectedFilters.find((f) => f.key === 'genders')?.value;
    const selectedSpecialtiesFilters = selectedFilters.find((f) => f.key === 'specialties')?.value;
    const selectedModalitiesFilters = selectedFilters.find((f) => f.key === 'modalities')?.value;
    const selectedInsuranceIdFilter = selectedFilters.find((f) => f.key === 'insurance_id')?.value;
    const selectedInsuranceTypeFilter = selectedFilters.find((f) => f.key === 'insurance_type')?.value;

    this.dataLayer = {
      event: GtmCustomEvents.PROVIDER_DIRECTORY_LOAD_NEW_PROVIDERS,
      category: GtmCustomEvents.PROVIDER_DIRECTORY_LOAD_NEW_PROVIDERS,
      totalProviderCount: providerCount,
      selectedStateFilter,
      selectedGendersFilters,
      selectedSpecialtiesFilters,
      selectedModalitiesFilters,
      selectedInsuranceIdFilter,
      selectedInsuranceTypeFilter
    };
  }

  sendShowMoreProvidersEvent(selectedFilters: IAPIFilterOption[], isFinalResultPage: boolean): void {
    const selectedStateFilter = selectedFilters.find((f) => f.key === 'practice_state')?.value;
    const selectedGendersFilters = selectedFilters.find((f) => f.key === 'genders')?.value;
    const selectedSpecialtiesFilters = selectedFilters.find((f) => f.key === 'specialties')?.value;
    const selectedModalitiesFilters = selectedFilters.find((f) => f.key === 'modalities')?.value;
    const selectedInsuranceIdFilter = selectedFilters.find((f) => f.key === 'insurance_id')?.value;
    const selectedInsuranceTypeFilter = selectedFilters.find((f) => f.key === 'insurance_type')?.value;

    this.dataLayer = {
      event: GtmCustomEvents.PROVIDER_DIRECTORY_SHOW_MORE,
      category: GtmCustomEvents.PROVIDER_DIRECTORY_SHOW_MORE,
      isFinalResultPage,
      selectedStateFilter,
      selectedGendersFilters,
      selectedSpecialtiesFilters,
      selectedModalitiesFilters,
      selectedInsuranceIdFilter,
      selectedInsuranceTypeFilter
    };
  }
}
