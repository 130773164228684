import { TextMaskConfig } from 'angular2-text-mask';
import { createAutoCorrectedDatePipe } from 'text-mask-addons';

function cvcMask(value: string = ''): Array<RegExp | string> {
  const len = value.length < 4 ? 3 : 4;
  return new Array(len).fill(/\d/);
}

export interface TextMasks {
  [key: string]: TextMaskConfig;
}

export const SharedMasks: TextMasks = {

  phoneMask: {
    mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    guide: false,
    placeholderChar: '\u2000'
  },

  expirationMask: {
    mask: [/\d/, /\d/, '/', /\d/, /\d/],
    pipe: createAutoCorrectedDatePipe('mm/yy'),
    keepCharPositions: true,
    placeholderChar: '\u2000'
  },

  birthdayMask: {
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    pipe: createAutoCorrectedDatePipe('mm/dd/yyyy'),
    keepCharPositions: true,
    placeholderChar: '\u2000'
  },

  cvcMask: {
    mask: cvcMask,
    guide: false,
    placeholderChar: '\u2000'
  },

  zipcodeMask: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
    guide: false,
    placeholderChar: '\u2000'
  }
};
