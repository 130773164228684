import { ClientUser } from '@sondermind/utilities/models-user-client';
import { ILaunchDarklyUser } from './launch-darkly-user.interface';
import { UserType } from './launch-darkly-user.enum';

export class LaunchDarklyClientUser implements ILaunchDarklyUser {
  key: string;
  email: string;
  // NOTE: this can be expanded to include more keys as needed for LD experiments
  custom: {
    state?: string;
    accountCreatedAt?: string;
    userType?: UserType;
  } = {};

  constructor(user: ClientUser) {
    this.key = user.profile?.experimentationKey || '';
    this.email = user.email;
    this.custom.state = user.insuranceState;
    this.custom.accountCreatedAt = user.profile?.createdAt;
    this.custom.userType = UserType.CLIENT;
  }
}
