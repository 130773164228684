import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DirectoryComponent } from './components/directory/directory.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';

const routes: Routes = [
  {
    path: '',
    component: DirectoryComponent,
  },
  {
    path: 'match/:share_code',
    loadChildren: () => import('./modules/match-flow/match-flow.module').then((m) => m.MatchFlowModule),
  },
  {
    path: 'match/:share_code/:name',
    loadChildren: () => import('./modules/match-flow/match-flow.module').then((m) => m.MatchFlowModule),
  },
  {
    path: ':share_code',
    component: LandingPageComponent
  },
  {
    path: ':share_code/:name',
    component: LandingPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
