<section class="provider-profile">
  <div class="provider-header">
    <br *ngIf="noProfilePhoto">
    <div *ngIf="!noProfilePhoto" class="image-container">
      <img 
        src="{{ profile.profile_photo_url }}" 
        alt="{{ profile.display_name }} profile image" 
        class="provider-image"
        fetchpriority="high"
      >
    </div>

    <div class="provider-header-details">
      <div class="names-and-pronouns-container">
        <h1 class="provider-name" data-test="provider-display-name">{{ profile.display_name }}</h1>
        <span class="provider-pronouns" *ngIf="profile?.pronouns?.length > 0">
          ({{ profile.pronouns }})
        </span>
      </div>
      <p class="provider-credentials" *ngFor="let certification of profile.certifications">
        {{ certification.name }}
      </p>
      
      <div class="type-and-insurance-container">
        <p class="provider-session-type">
          <iris-icon
            name="location_on"
            [size]="iconSizes.MD"
          >
          </iris-icon>
          {{ sessionTypeString }}
        </p>
        <p *ngIf="profile?.credentialed_states?.length > 0" class="provider-state">
          Practicing in {{ credentialStatesFormatted }}
        </p>
        <div *ngIf="profile.insurances.length > 0; else selfPay">
          <p class="provider-header-insurance-title">
            <iris-icon
              name="verified_user"
              [size]="iconSizes.MD"
            >
            </iris-icon>
            Insurance
          </p>
          <p class="provider-header-insurances-accepted">
            {{ providerInsurances | slice:0:maxLengthOfInsuranceListString }}{{providerInsurances.length > maxLengthOfInsuranceListString ? '...' : ''}}
          </p>
        </div>
        <button 
          class="show-insurance-list-button" 
          *ngIf="providerInsurances.length > maxLengthOfInsuranceListString" 
          (click)="scrollToElement('insurances-section')"
          data-test="show-insurance-list-button"
          tabindex="0"
        >
          See all below
        </button>
      </div>

      <div
        *ngIf="profile.psl_feature_flag"
        class="scheduling-container"
        [ngClass]="{ 'hide-margin': (!profile.accepting_clients && showSimilarProvidersFf) }"
        data-test="scheduling-container"
      >
        <ng-container *ngIf="profile.accepting_clients">
          <button
            *ngIf="useCheckoutFormFf; else linkToFlow"
            (click)="openRequestASessionModal()"
            class="request-session-button"
            data-test="request-session-button"
          >
            Request a session
          </button>
          <ng-template #linkToFlow>
            <a
              [routerLink]="['/match', paramsShareCode, paramsName]"
              [queryParams]="requestSessionQueryParams"
              class="request-session-button"
              data-test="request-session-button"
              tabindex="0"
              (click)="onRequestASessionClick()"
            >
              Request a session
            </a>
          </ng-template>
        </ng-container>

        <div 
          class="not-accepting-clients-cta-desktop"
          *ngIf="!profile.accepting_clients && !showSimilarProvidersFf"
          data-test="not-accepting-clients"
        >
          <non-accepting-match-options data-test="non-accepting-match-options"></non-accepting-match-options>
        </div>
      </div>
    </div>
  </div>
</section>
<div
  *ngIf="profile.psl_feature_flag && !profile.accepting_clients && !showSimilarProvidersFf" 
  class="not-accepting-clients-cta-mobile"
>
  <non-accepting-match-options></non-accepting-match-options>
</div>

<ng-template #selfPay>
  <p class="provider-header-insurance-title">
    <iris-icon
      name="verified_user"
      [size]="iconSizes.MD"
    >
    </iris-icon>
    Accepts self-pay only
  </p>
  <br/>
</ng-template>
