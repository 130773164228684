import { AbstractControl } from '@angular/forms';

export class EmailValidator {
  // eslint-disable-next-line max-len, no-useless-escape
  static pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  static format(c: AbstractControl): { [key: string]: any; } {
    if ((typeof c.value === 'undefined') || c.value === null || c.value.toString().length === 0) {
      return null;
    }

    if (!EmailValidator.pattern.test(c.value)) {
      return { emailFormat: 'Emails must be in correct format of xxx@xxx.xxx' };
    }
    return null;
  }
}
