<div class="support-phone-number-container">
  <iris-icon
    name="call"
    [size]="iconSize"
    [color]="iconColor"
  ></iris-icon>
  <span>
    <strong>Need support?</strong> Our Care Coordinators can assist you in booking a session.
    <a href="tel:{{ supportPhoneNumberLink }}">{{supportPhoneNumberDisplay}}</a>
  </span>
</div>