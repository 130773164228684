/* eslint-disable import/no-extraneous-dependencies */
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { Logger, MessageStatus } from './logger';

/** An implementation of a simple logger, which pushes to datadog */
export class DatadogLogger extends Logger {
  log(message: string, context?: object, status?: MessageStatus): void {
    console.log(`[DD][${status}] ${message}`, context);
    datadogLogs.logger.log(message, context, status);
  }

  addAction(name: string, context?: object): void {
    datadogRum.addAction(name, context);
  }

  addError(error: unknown, context?: object): void {
    datadogRum.addError(error, context);
  }
}
