export enum ProviderMatchStatus {
  ACCEPTED = 'accepted',
  NOTIFIED = 'notified',
  NOTIFICATION_DELAYED = 'notification_delayed',
  SCHEDULED = 'scheduled',
  DECLINED = 'declined',
  EXPIRED = 'expired',
  ON_HOLD = 'on_hold',
  MEMBER_SELECTED = 'member_selected',
}
