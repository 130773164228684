import { Injectable } from '@angular/core';
import { AnalyticsBrowser } from '@segment/analytics-next';

import { ConfigurationService, ISegmentAppConfig } from '@sondermind/configuration';
import { CurrentUser } from '@sondermind/data-access/client-user';
import { Logger } from '@sondermind/datadog-logging';

export type SegmentSchema = Record<string, unknown>;

@Injectable({
  providedIn: 'root'
})
export class SegmentService<TSchema extends SegmentSchema> {
  analytics: AnalyticsBrowser | undefined;

  constructor(
    private configurationService: ConfigurationService<ISegmentAppConfig>,
    private logger: Logger,
    private currentUser: CurrentUser,
  ) {
  }

  private async init(): Promise<boolean> {
    if (!this.configurationService.env.app?.segmentWriteKey) {
      this.logger.warn('Missing Segment Write Key..');
      return false;
    }
    if (!this.analytics) {
      this.analytics = AnalyticsBrowser.load({ writeKey: this.configurationService.env.app?.segmentWriteKey });

      // if the user is a known client, we want to bind the segment identity to that
      // if they do not exist, we can still rely on the implicit segment anonymousId
      const platformUuid = this.currentUser?.user
        ? this.currentUser.getPlatformUuid()
        : null;

      if (platformUuid) {
        await this.analytics.identify(platformUuid);
      }
    }
    return true;
  }

  /**
   * This registers an event with segment, posting tracking data along with the name of the action.
   *
   * One important note is that segment collects anonymousId implicitly. You do not need to include that as
   * part of your changesets.
   */
  async track<T extends keyof TSchema>(event: T, data: TSchema[T]): Promise<void> {
    if (!this.analytics) {
      // if initialization fails (like when no segmentWriteKey is provided) and returns false
      // we do nothing with the tracking event/data
      if (!await this.init()) {
        return;
      }
    }

    // if analytics exists (implying init succeeded already at any point)
    // we track the event and post the data.
    await this.analytics.track(event.toString(), data);
  }
}
