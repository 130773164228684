import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IrisIconModule } from '@sondermindorg/iris-design-system-angular';

import { PublicHeaderComponent } from './public-header.component';

@NgModule({
  declarations: [PublicHeaderComponent],
  imports: [
    CommonModule,
    IrisIconModule
  ],
  exports: [PublicHeaderComponent]
})
export class PublicHeaderModule { }
