import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IrisCardModule } from '@sondermindorg/iris-design-system-angular';

@Component({
  selector: 'mini-provider-listing-card-loading',
  standalone: true,
  imports: [
    CommonModule,
    IrisCardModule
  ],
  templateUrl: './mini-provider-listing-card-loading.component.html',
  styleUrls: ['./mini-provider-listing-card-loading.component.scss']
})
export class MiniProviderListingCardLoadingComponent {}
