import { Component, Inject } from '@angular/core';
import { ILaunchDarklyUser, LAUNCH_DARKLY_SERVICE, LaunchDarklyService } from '@sondermind/launch-darkly';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent {
  constructor(
    @Inject(LAUNCH_DARKLY_SERVICE)
    private launchDarklyService: LaunchDarklyService,
  ) {
    this.initLaunchDarklyClient();
  }

  private initLaunchDarklyClient() {
    this.launchDarklyService.initClient(this.buildNewLaunchDarklyClient());
  }

  private buildNewLaunchDarklyClient(): ILaunchDarklyUser {
    return {
      kind: 'anonUserId',
      anonymous: true
    };
  }
}
