import { MatchAssignmentType } from "@sondermind/utilities/models-matching";
import { MatchHighlightKey } from "./match-highlight-key.enum";
import { IMatchHighlight } from "./match-highlight.interface";
import { MatchCategory } from "./match-category.enum";

export interface ITherapistMatch {
  status: string;
  referral_id: number;
  client_referral_member_id: number;
  auto_matched: boolean;
  session_requested: boolean;
  requested_session_type: RequestedSessionType;
  client_sent_ncc: boolean;
  match_assignment: MatchAssignmentType;
  additional_match: boolean;
  /**
   * Indicates whether the match is actionable (pending but not scheduled)
   */
  actionable: boolean;
  category: MatchCategory | null;
  additional_categories: MatchCategory[];
  match_highlights: Record<MatchHighlightKey, IMatchHighlight[]>;
}

export enum RequestedSessionType {
  FREE_CONSULTATION = 'free_consultation',
  SESSION = 'session'
}
