import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpErrorResponse, HttpClient, HttpHeaders, HttpParams
} from '@angular/common/http';

import {
  throwError, of, OperatorFunction, Observable
} from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  DEFAULT_FETCH_HANDLING_OPTIONS,
  DEFAULT_SERVICE_HANDLING_OPTIONS,
  HTTP_ERROR_HANDLER,
  IAPIErrorHandlingOptions,
  IAPIFetchRequestOptions,
  IAPIListRequestOptions,
  IAPIServiceRequestOptions,
  IHttpErrorHandler,
  IHttpOptions
} from '@sondermind/utilities/models-http';
import { defaultHandleHttpError } from '../utilities/default-error-handler.utility';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private defaultErrorHandlingOptions: IAPIErrorHandlingOptions = {
    null401: false,
    null404: true,
    rawErrors: false
  };

  private defaultListHandlingOptions: IAPIListRequestOptions = {
    error: this.defaultErrorHandlingOptions
  };

  constructor(
    public http: HttpClient,

    @Optional()
    @Inject(HTTP_ERROR_HANDLER)
    private errorHandlers: IHttpErrorHandler[],
  ) {}

  generateListParams(opts: Partial<IAPIListRequestOptions>): HttpParams {
    const options = { ...this.defaultListHandlingOptions, ...opts };
    let params = new HttpParams();

    if (options.view) {
      params = params.set('view', options.view);
    }

    if (options.filter) {
      const filters = Array.isArray(options.filter) ? options.filter : [options.filter];
      filters.forEach((f) => {
        params = params.set(`filter[${f.key}]`, f.value);
      });
    }

    if (options.filterArray) {
      const filters = Array.isArray(options.filterArray) ? options.filterArray : [options.filterArray];
      filters.forEach((f) => {
        params = params.append(`filter[${f.key}][]`, f.value);
      });
    }

    if (options.sort) {
      const sorts = Array.isArray(options.sort) ? options.sort : [options.sort];
      if (sorts.length) {
        params = params.set(
          'sort',
          sorts.map((s) => (s.asc ? '' : '-') + s.key).join(','));
      }
    }

    if (options.page === 'disabled') {
      params = params.set('page[enabled]', 'false');
    } else if (options.page) {
      params = params.set('page[enabled]', 'true');

      if (options.page.number != null) {
        params = params.set('page[number]', `${options.page.number}`);
      }

      if (options.page.size != null) {
        params = params.set('page[size]', `${options.page.size}`);
      }
    }

    return params;
  }

  generateFetchParams(opts: Partial<IAPIFetchRequestOptions>): HttpParams {
    const options = { ...DEFAULT_FETCH_HANDLING_OPTIONS, ...opts };
    let params = new HttpParams();

    if (options.view) {
      params = params.set('view', options.view);
    }

    return params;
  }

  generateServiceParams(opts: Partial<IAPIServiceRequestOptions>): HttpParams {
    const options = { ...DEFAULT_SERVICE_HANDLING_OPTIONS, ...opts };
    let params = new HttpParams();

    if (options.view) {
      params = params.set('view', options.view);
    }

    return params;
  }

  get<T>(
    url: string,
    params: HttpParams = new HttpParams(),
    options: IHttpOptions = {}
  ): Observable<T> {
    return this.http.get<T>(
      url,
      { params, ...this.resolveHttpOptions(options) }
    );
  }

  post<T>(
    url: string,
    body: unknown,
    params: HttpParams = new HttpParams(),
    options: IHttpOptions = {}
  ): Observable<T> {
    return this.http.post<T>(
      url,
      body,
      { params, ...this.resolveHttpOptions(options) }
    );
  }

  put<T>(
    url: string,
    body: unknown,
    params: HttpParams = new HttpParams(),
    options: IHttpOptions = {}
  ): Observable<T> {
    return this.http.put<T>(
      url,
      body,
      { params, ...this.resolveHttpOptions(options) }
    );
  }

  patch<T>(
    url: string,
    body: unknown,
    params: HttpParams = new HttpParams(),
    options: IHttpOptions = {}
  ): Observable<T> {
    return this.http.patch<T>(
      url,
      body,
      { params, ...this.resolveHttpOptions(options) }
    );
  }

  delete<T>(
    url: string,
    params: HttpParams = new HttpParams(),
    options: IHttpOptions = {}
  ): Observable<T> {
    return this.http.delete<T>(
      url,
      { params, ...this.resolveHttpOptions(options) }
    );
  }

  private resolveHttpOptions(options: IHttpOptions) {
    // eslint-disable-next-line no-param-reassign
    let headers = options.headers || new HttpHeaders();
    if (options.skipAuthorization) {
      // eslint-disable-next-line no-param-reassign
      headers = headers.set('Authorization', 'None');
    }

    headers = headers.append('ngsw-bypass', 'true');

    return {
      withCredentials: options.withCredentials,
      headers
    };
  }

  handleHttpErrorStrict<T>(): OperatorFunction<T, T> {
    const options: IAPIErrorHandlingOptions = {
      null401: false,
      null404: false,
      rawErrors: false
    };

    return catchError((err) => {
      // an error was raised in the observable pipe
      // it's not an http error, so we'll let it go on through
      if (!(err instanceof HttpErrorResponse)) {
        return throwError(() => new Error(err));
      }

      // allow a registered error handler to handle it (maybe)
      if (this.errorHandlers) {
        // eslint-disable-next-line no-restricted-syntax
        for (const handler of this.errorHandlers) {
          const next = handler.handleHttpErrorStrict<T>(err, options);
          if (next) {
            return next;
          }
        }
      }

      // but if there's no handler, or the handler declines to process the event,
      // we have the original behavior as fallback
      return defaultHandleHttpError(err, options);
    });
  }

  handleHttpError<T>(opts: Partial<IAPIErrorHandlingOptions> = {}): OperatorFunction<T, T | null> {
    const options: IAPIErrorHandlingOptions = { ...this.defaultErrorHandlingOptions, ...opts };

    return catchError((err) => {
      // an error was raised in the observable pipe
      // it's not an http error, so we'll let it go on through
      if (!(err instanceof HttpErrorResponse)) {
        return throwError(() => new Error(err));
      }

      // depending on options
      // 401 and 403 might be nulls, not errors
      if (options.null401 && (err.status === 401 || err.status === 403)) {
        return of(null as unknown as T);
      }

      // same for 404s
      if (options.null404 && err.status === 404) {
        return of(null as unknown as T);
      }

      // allow a registered error handler to handle it (maybe)
      if (this.errorHandlers) {
        // eslint-disable-next-line no-restricted-syntax
        for (const handler of this.errorHandlers) {
          const next = handler.handleHttpError<T>(err, options);
          if (next) {
            return next;
          }
        }
      }

      // but if there's no handler, or the handler declines to process the event,
      // we have the original behavior as fallback
      return defaultHandleHttpError(err, options);
    });
  }
}
