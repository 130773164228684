<div class="not-found-container" data-test="not-found-container">
  <h1>Oops! It looks like the page you've landed on does not exist or is not yet available.</h1>
    
  <p class="not-found-subtitle">
    SonderMind is a mental health care provider, connecting you with a therapist 
    or psychiatrist who takes your insurance and has availability within a week.
  </p>
  <br>
  <div class="not-found-button">
    <iris-button-container mobileLayout="full-width">
      <a 
      href="{{therapyMatchFlowUrl}}"
      target="_blank"
      rel="noopener noreferrer"
      data-test="match-flow-url"
    >
      <iris-button class="find-care-button" typeAttribute="button">
        Find care
      </iris-button>
    </a>
    </iris-button-container>
  </div>
</div>