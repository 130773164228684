import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable, OnDestroy } from '@angular/core';
import { BREAKPOINT, BreakPoint, BreakPointRegistry } from '@angular/flex-layout';
import { NativeAppContextHelpers } from '@sondermind/native-app-interface';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  share,
  shareReplay,
  startWith,
  takeUntil
} from 'rxjs/operators';

const CUSTOM_BREAKPOINTS = [
  {
    alias: 'top-nav-breakpoint',
    suffix: 'TopNavBreakpoint',
    mediaQuery: '(max-width: 1002px)',
    overlapping: false
  },
  {
    alias: 'match-builder-mobile-breakpoint',
    suffix: 'MatchBuilderMobileBreakpoint',
    mediaQuery: '(max-width: 641px)',
    overlapping: false
  }
];

export const CUSTOM_BREAK_POINTS_PROVIDER = {
  provide: BREAKPOINT,
  useValue: CUSTOM_BREAKPOINTS,
  multi: true
};

@Injectable({
  providedIn: 'root'
})
export class BreakpointsService implements OnDestroy {
  suffixes$: Observable<string[]>;
  isTiny$: Observable<boolean>;
  isMobile$: Observable<boolean>;
  isMobileOrTablet$: Observable<boolean>;
  isTopNavBreakpoint$: Observable<boolean>;
  isMatchBuilderMobileBreakpoint$: Observable<boolean>;
  private isModalSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isModal$: Observable<boolean> = this.isModalSubj.asObservable();
  private destroyed = new Subject<void>();
  hasMultipleModals: boolean = false;
  // todo: update when flex-layout#426 is fixed
  // https://github.com/angular/flex-layout/issues/426
  // observableMedia.asObservable() doesn't always fire when initialized, meaning
  // we have to wait for a resize to occur to reliably get the currently active
  // mediaquery. rather, find the active breakpoints by iterating through the registry
  // and call `startWith` on the observable to force something to happen on load

  private xsBreakpoint;
  private mobileBreakpoint;
  private mobileOrTabletBreakpoint;
  private topNavBreakpoint;
  private activeBreakpoints$: Observable<BreakPoint[]>;
  private matchBuilderMobileBreakpoint;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private breakpoints: BreakPointRegistry,
  ) {
    this.xsBreakpoint = this.breakpoints.findByAlias('xs')?.mediaQuery ?? '';
    this.mobileBreakpoint = this.breakpoints.findByAlias('lt-md')?.mediaQuery ?? '';
    this.mobileOrTabletBreakpoint = this.breakpoints.findByAlias('lt-lg')?.mediaQuery ?? '';
    this.topNavBreakpoint = this.breakpoints.findByAlias('top-nav-breakpoint')?.mediaQuery ?? '';
    this.matchBuilderMobileBreakpoint = this.breakpoints
      .findByAlias('match-builder-mobile-breakpoint')?.mediaQuery ?? '';

    const allBreakpoints = this.breakpoints.items.map((bp) => bp.mediaQuery);
    this.activeBreakpoints$ = this.breakpointObserver.observe(allBreakpoints).pipe(
      map((_) => this.currentlyActiveBreakpoints()),
      startWith(this.currentlyActiveBreakpoints()),
      takeUntil(this.destroyed.asObservable()),
      share(),
    );

    this.suffixes$ = this.activeBreakpoints$.pipe(
      map((bps) => bps.map((bp) => bp.alias)),
      startWith(this.currentlyActive()),
      shareReplay(1),
    );

    this.isTiny$ = this.setupBreakpointObservable(this.xsBreakpoint);
    this.isMobile$ = this.setupBreakpointObservable(this.mobileBreakpoint);
    this.isMobileOrTablet$ = this.setupBreakpointObservable(this.mobileOrTabletBreakpoint);
    this.isTopNavBreakpoint$ = this.setupBreakpointObservable(this.topNavBreakpoint);
    this.isMatchBuilderMobileBreakpoint$ = this.setupBreakpointObservable(this.matchBuilderMobileBreakpoint);
  }

  get isMobile(): boolean {
    return this.breakpointObserver.isMatched(this.mobileBreakpoint);
  }

  get isMobileOrTablet(): boolean {
    return this.breakpointObserver.isMatched(this.mobileOrTabletBreakpoint);
  }

  get isTopNavBreakpoint(): boolean {
    return this.breakpointObserver.isMatched(this.topNavBreakpoint);
  }

  get isMatchBuilderMobileBreakpoint(): boolean {
    return this.breakpointObserver.isMatched(this.matchBuilderMobileBreakpoint);
  }

  ngOnDestroy(): void {
    this.destroyed.next();
  }

  currentlyActive(): string[] {
    return this.currentlyActiveBreakpoints().map((b) => b.alias);
  }

  private currentlyActiveBreakpoints(): BreakPoint[] {
    return this.breakpoints.items
      .filter((b) => this.breakpointObserver.isMatched(b.mediaQuery));
  }

  private setupBreakpointObservable(breakpointValue: string): Observable<boolean> {
    return this.activeBreakpoints$.pipe(
      map((_) => this.breakpointObserver.isMatched(breakpointValue)),
      startWith(this.breakpointObserver.isMatched(breakpointValue)),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }

  toggleModal(isModal: boolean): void {
    if (NativeAppContextHelpers.isHostedInNativeApp()){
      NativeAppContextHelpers.onModalChanged(isModal);
    }
    this.isModalSubj.next(isModal);
  }
}
