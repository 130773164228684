<div class="directory-container">
  <public-header></public-header>
  
  <div class="page-not-found-container">
    <page-not-found [therapyMatchFlowUrl]="matchflowBaseUrl"></page-not-found>
  </div>

  <div class="list-container">
    <ul>
      <h1>Provider Profile Pages</h1>
      <li *ngFor="let provider of providers">
        <a [href]="[provider.link]">{{ provider.name }}</a>
      </li>
    </ul>
    <button (click)="goToPreviousPage()" [disabled]="currentPage === 1">
      Previous
    </button>
    <button (click)="goToNextPage()" [disabled]="currentPage === totalPages">
      Next
    </button>
  </div>
</div>


<router-outlet></router-outlet>
