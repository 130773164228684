/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { IAPIListResponse } from '@sondermind/utilities/models-http';
import { IProviderPreview } from '../models/provider-preview.interface';

export const BILL_THOMPKINS: IProviderPreview = {
  id: 134,
  display_name: 'Claudia Trevithick',
  pronouns: 'she/her',
  share_code: 'bwbr6z07',
  profile_photo_url: 'https://dpevr0lj19odd.cloudfront.net/000/000/142/medium/IMG_1414_%282%29.JPG?X-Amz-Expires=600\u0026X-Amz-Date=20240422T115857Z\u0026X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIATZW7KYENNL4VBAP5/20240422/us-east-1/s3/aws4_request\u0026X-Amz-SignedHeaders=host\u0026X-Amz-Signature=ddbdb00799fad81e5ee326a91996175c2b634cddba8046996e623082cd137b60',
  accepting_clients: true,
  certifications: [
    {
      name: 'Licensed Professional Counselor',
      abbrev: 'LPC'
    },
    {
      name: 'Art Therapist, Board Certified',
      abbrev: 'AT-BC'
    }
  ],
  session_types_offered: [
    'in_person'
  ],
  specialties: [
    {
      id: 2872,
      title: 'PTSD (Posttraumatic Stress Disorder)'
    },
    {
      id: 2852,
      title: 'Adolescent \u0026 teen mental/behavioral health'
    },
    {
      id: 2844,
      title: 'Anger Management'
    },
    {
      id: 2846,
      title: 'Anxiety'
    },
    {
      id: 2848,
      title: 'Autism spectrum support'
    },
    {
      id: 2853,
      title: 'Depression'
    },
    {
      id: 2858,
      title: 'Family relationships'
    },
    {
      id: 2861,
      title: 'Grief / Bereavement'
    },
    {
      id: 2863,
      title: 'Life transitions'
    },
    {
      id: 2864,
      title: 'Medical issues / Physical health'
    },
    {
      id: 2869,
      title: 'OCD (Obsessive-Compulsive Disorder)'
    },
    {
      id: 2874,
      title: 'Intimate / Romantic relationships'
    },
    {
      id: 2880,
      title: 'Spirituality'
    },
    {
      id: 2881,
      title: 'Stress'
    },
    {
      id: 2883,
      title: 'Trauma'
    }
  ],
  bio: 'Have you been experiencing the pain of sorrow, grief or loneliness? Perhaps you are functioning well, but feel the tears on the inside. Sometimes worries seem like they will never stop. I have worked successfully with adults, teens and children for the last 30 years, helping turn depression and anxiety into hope and optimism. You are resilient, can heal your relationships, watch your children blossom and find family life enriching again.\n\nI always talk with you. However, when words, alone, are not enough, art therapy can be a holding place for deep feelings.  When you draw or clay your feelings, they can be seen, owned, and learned from. I also do sandtray therapy with both adults and kids. Children usually play in the sand. Adults resolve longstanding issues. Teens enjoy the non-verbal quality.\n\nI incorporate talk therapy, art therapy, sandtray therapy and also play, depending on the needs of each client. I also work with bereavement, divorce issues, and relationships.\n\nIN-PERSON OFFICE LOCATION:\n2101 S Blackhawk St, Suite 240, Aurora, CO, 80014-1475',
  url: 'https://providers.sondermind.com/providers/bwbr6z07'
};

const MARY_CONSTANTINOPLE: IProviderPreview = {
  id: 943,
  display_name: 'Ayonna Chappell',
  pronouns: 'she/her',
  share_code: 'tqj3bij2',
  profile_photo_url: 'https://dpevr0lj19odd.cloudfront.net/001/547/870/medium/A1A3EF6E-35E2-4CA5-BB55-BE9ECEC11C7E.jpeg?X-Amz-Expires=600\u0026X-Amz-Date=20240422T121130Z\u0026X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIATZW7KYENNL4VBAP5/20240422/us-east-1/s3/aws4_request\u0026X-Amz-SignedHeaders=host\u0026X-Amz-Signature=9db7794b69b5dee145dafdea54ae9619a6866305a8b94dec0a8721fa8841982b',
  accepting_clients: true,
  certifications: [
    {
      name: 'Licensed Professional Counselor',
      abbrev: 'LPC'
    },
    {
      name: 'Licensed Professional Clinical Counselor',
      abbrev: 'LPCC'
    },
    {
      name: 'SonderMind Baseline Veteran Education Program',
      abbrev: ''
    },
    {
      name: 'Licensed Clinical Mental Health Counselor',
      abbrev: 'LCMHC'
    },
    {
      name: 'Licensed Mental Health Counselor',
      abbrev: 'LMHC'
    }
  ],
  session_types_offered: [
    'video'
  ],
  specialties: [
    {
      id: 2852,
      title: 'Adolescent \u0026 teen mental/behavioral health'
    },
    {
      id: 2844,
      title: 'Anger Management'
    },
    {
      id: 2845,
      title: 'Antisocial behavior'
    },
    {
      id: 2846,
      title: 'Anxiety'
    },
    {
      id: 2849,
      title: 'Bipolar Disorder'
    },
    {
      id: 2850,
      title: 'Borderline Personality Disorder'
    },
    {
      id: 2851,
      title: "Children's mental/behavioral health"
    },
    {
      id: 2853,
      title: 'Depression'
    },
    {
      id: 2858,
      title: 'Family relationships'
    },
    {
      id: 2860,
      title: 'Gender identity'
    },
    {
      id: 2862,
      title: 'LGBTQIA+ support'
    },
    {
      id: 2863,
      title: 'Life transitions'
    },
    {
      id: 2866,
      title: 'Military / Veteran support'
    },
    {
      id: 2867,
      title: 'Multicultural / Diversity issues'
    },
    {
      id: 2871,
      title: 'Phobias'
    },
    {
      id: 2872,
      title: 'PTSD (Posttraumatic Stress Disorder)'
    },
    {
      id: 2874,
      title: 'Intimate / Romantic relationships'
    },
    {
      id: 2914,
      title: 'SonderMind Video Telehealth'
    },
    {
      id: 2880,
      title: 'Spirituality'
    },
    {
      id: 2881,
      title: 'Stress'
    },
    {
      id: 2882,
      title: 'Suicidal ideation and/or self-harming'
    },
    {
      id: 2883,
      title: 'Trauma'
    }
  ],
  bio: "I am a licensed professional counselor with over 7 years of experience working as a mental health counselor. I have worked with clients with a wide range of concerns including depression, anxiety, relationship issues, parenting problems, and divorce issues. I also helped many people who have experienced physical trauma or emotional abuse.\r\n\r\nMy counseling style is diverse and non-judgmental. I believe in treating everyone with respect, sensitivity, and compassion, and I don't believe in stigmatizing labels. My approach combines cognitive-behavioral, humanistic, psychodynamic and rational-emotive counseling. I will tailor our dialog and treatment plan to meet your unique and specific needs. I have experience with working with domestic violence, the child welfare system, the correctional setting, and juvenile justice system. \r\n\r\nIt takes courage to seek for a more fulfilling and happier life and to take the first steps towards a change. If you are ready to take that step I am here to support and empower you.\r\n\r\nI look forward to working with you!",
  url: 'https://www.sondermind.com/providers/tqj3bij2/ayonna-chappell'
};

const BETHANY_LEWIS_JR: IProviderPreview = {
  id: 3092,
  display_name: 'Diane Policelli',
  pronouns: null,
  share_code: '8xrjom19',
  profile_photo_url: 'https://dpevr0lj19odd.cloudfront.net/000/508/039/medium/B9794E92-A280-45EA-9925-C056F0EEDFCE_1_201_a.jpeg?X-Amz-Expires=600\u0026X-Amz-Date=20240422T121130Z\u0026X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIATZW7KYENNL4VBAP5/20240422/us-east-1/s3/aws4_request\u0026X-Amz-SignedHeaders=host\u0026X-Amz-Signature=e8ed458492073dae2f0ddb6490a8a328fa35d8c5c221dcf9f7b6f11eb6bfae64',
  accepting_clients: true,
  certifications: [
    {
      name: 'Licensed Marriage and Family Therapist',
      abbrev: 'LMFT'
    },
    {
      name: 'Licensed Clinical Marriage and Family Therapist',
      abbrev: 'LCMFT'
    }
  ],
  session_types_offered: [
    'video'
  ],
  specialties: [
    {
      id: 2851,
      title: "Children's mental/behavioral health"
    },
    {
      id: 2855,
      title: 'Dissociative Disorders'
    },
    {
      id: 2869,
      title: 'OCD (Obsessive-Compulsive Disorder)'
    },
    {
      id: 2882,
      title: 'Suicidal ideation and/or self-harming'
    },
    {
      id: 2884,
      title: 'Traumatic Brain Injury'
    },
    {
      id: 2842,
      title: 'Addiction'
    },
    {
      id: 2844,
      title: 'Anger Management'
    },
    {
      id: 2845,
      title: 'Antisocial behavior'
    },
    {
      id: 2846,
      title: 'Anxiety'
    },
    {
      id: 2847,
      title: 'ADHD (Attention-Deficit / Hyperactivity Disorder)'
    },
    {
      id: 2849,
      title: 'Bipolar Disorder'
    },
    {
      id: 2850,
      title: 'Borderline Personality Disorder'
    },
    {
      id: 2852,
      title: 'Adolescent \u0026 teen mental/behavioral health'
    },
    {
      id: 2853,
      title: 'Depression'
    },
    {
      id: 2858,
      title: 'Family relationships'
    },
    {
      id: 2860,
      title: 'Gender identity'
    },
    {
      id: 2862,
      title: 'LGBTQIA+ support'
    },
    {
      id: 2863,
      title: 'Life transitions'
    },
    {
      id: 2866,
      title: 'Military / Veteran support'
    },
    {
      id: 2867,
      title: 'Multicultural / Diversity issues'
    },
    {
      id: 2868,
      title: 'Narcissistic Personality Disorder'
    },
    {
      id: 2872,
      title: 'PTSD (Posttraumatic Stress Disorder)'
    },
    {
      id: 2874,
      title: 'Intimate / Romantic relationships'
    },
    {
      id: 2875,
      title: 'Psychosis / Schizophrenia'
    },
    {
      id: 2880,
      title: 'Spirituality'
    },
    {
      id: 2881,
      title: 'Stress'
    },
    {
      id: 2883,
      title: 'Trauma'
    },
    {
      id: 2914,
      title: 'SonderMind Video Telehealth'
    }
  ],
  bio: 'Licensed in Colorado since 1995, my experience over the past 25 years has focused on the treatment of individuals, adolescents, and families with substance misuse, behavioral issues, depression, anxiety, and other problems using an integrative systemic approach, which includes direct and indirect interventions, IFS, CBT, and mindfulness exercises.  Explorations into multigenerational problems, attachment, and trauma issues are also part of an integrative approach to Behavioral Health treatment. I am also a licensed MFT in California and have been since 2010.  I recently moved from California to New York, so am in the Eastern time zone.  In 2022 I also became a licensed Marriage and Family therapist in Vermont, and New York.\n\nI enjoy working with a variety of people and have worked in multiple private and public clinical settings, including community mental health, IOP/PHP, and co-occurring disorders Residential treatment.',
  url: 'https://www.sondermind.com/providers/8xrjom19'
};

const EDGAR_HOPKINS_III: IProviderPreview = {
  accepting_clients: true,
  bio: 'It is important to me that I start by telling you that sessions with me start off with sudden yelling and progress immediately to sneeze therapy. You will find',
  certifications: [
    { abbrev: 'MLB', name: 'Starting Pitcher' },
    { abbrev: 'ABC', name: 'News Anchor' },
  ],
  display_name: 'Bethany Lewis Jr.',
  id: 3,
  profile_photo_url: '',
  pronouns: 'they, them',
  session_types_offered: ['Video', 'In_Person'],
  share_code: '45678',
  specialties: [
    { title: 'Life Changes', id: 1 },
    { title: 'Accidents', id: 99 }
  ],
  url: ''
};

// This is all just hardcoded data for the service to return, so we can make progress without waiting on the backend
export const RESPONSE_DATA: IProviderPreview[] = [
  BILL_THOMPKINS,
  MARY_CONSTANTINOPLE,
  BETHANY_LEWIS_JR,
  EDGAR_HOPKINS_III
];

export const RESPONSE_META = {
  view: '',
  links: {
    self: '',
    first: '',
    last: '',
    next: '',
    prev: '',
  },
  counts: {
    total: 250,
    filtered: 75,
    pages: 15,
  },
  filter: [],
  sort: [],
};

export const RESPONSE: IAPIListResponse<IProviderPreview> = {
  data: RESPONSE_DATA,
  meta: RESPONSE_META
};
