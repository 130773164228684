import { Injectable } from '@angular/core';
import Bowser from 'bowser';

export type SystemInfo = Bowser.Parser.ParsedResult;

@Injectable({
  providedIn: 'root'
})
export class UserSystemInfoService {
  private cachedSystemInfo!: SystemInfo;

  /**
   * Retrieves system information for the current user (browser, OS, etc.)
   * Note: this method uses a caching mechanism to store the user's system information
   * after it is first parsed. If you wish to hard reload the system information you can do
   * so via `forceReloadUserSystemInfo()`.
   */
  get userSystemInfo(): SystemInfo {
    if (!this.cachedSystemInfo) {
      this.forceReloadUserSystemInfo();
    }
    return this.cachedSystemInfo;
  }

  /**
   * Reloads the internal system user info object that stores the user's system info,
   * which is returned by `userSystemInfo()`.
   *
   * Generally the user's user agent should not change throughout the lifetime of the application unless
   * they manually change it via a user agent switcher, in which case you can use this method to refresh
   * the system info object.
   */
  forceReloadUserSystemInfo(): void {
    if (window?.navigator?.userAgent) {
      this.cachedSystemInfo = Bowser.parse(window.navigator.userAgent);
    }
  }

  isAndroid(): boolean {
    const androidString = 'ANDROID';
    const os = this.userSystemInfo.os.name?.toUpperCase() || '';
    return os.includes(androidString);
  }
}
