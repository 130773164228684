<div class="provider-details" [ngClass]="multiColumnDisplay ? 'wide-provider-details' : ''">
  <div class="modules" [ngClass]="multiColumnDisplay ? 'multi-column' : 'single-column'">
    <div
      class="left-column"
    >
      <section class="detail-tabs-header" data-test="tabs-header">
        <ng-container *ngFor="let module of moduleNames">
          <button
            [ngClass]="{'selected-section' : module === lastSelectedSection}"
            class="detail-section-link"
            (click)="scrollToSection(module)"
            [attr.data-test]="'section-link-' + module"
          >
            {{module}}
          </button>
        </ng-container>
      </section>
    
      <section
      *ngIf="providerBio?.length"
      id="about-section"
      class="about info-module"
      data-test="about-section"
      >
      <a id="about-link" [href]="currentRoute + '#about'"><h2>Meet {{providerShareableProfile.display_first_name}}</h2></a>
        <p data-test="provider-bio">{{providerBio}}</p>
        <button
          *ngIf="isBioTruncated && providerBio.length >= providerBioTruncationLength"
          (click)="toggleBioTruncation()"
          class="read-more"
        >
          read more
        </button>
        <button
          *ngIf="!isBioTruncated && providerBio.length >= providerBioTruncationLength"
          (click)="toggleBioTruncation()"
          class="read-more"
        >
          read less
        </button>
      </section>

      <section 
        *ngIf="providerShareableProfile?.specialties?.length > 0" 
        class="specialties info-module"
        id="specialties-section"
        data-test="specialties-section"
      >
        <section data-test="specialties">
          <a id="specialties-link" [href]="currentRoute + '#specialties'"><h2>Specialties & Experience</h2></a>
          <h4>Specialties</h4>
          <ul data-test="specialties-section-list">
            <li *ngFor="let specialty of allSortedSpecialties">
              {{ specialty }}
            </li>
          </ul>

          <section
            *ngIf="experienceSupportingList?.length > 0"
            class="experience"
          >
            <h4>Experience Supporting</h4>
            <ul>
              <li *ngFor="let experience of experienceSupportingList">
                {{ experience }}
              </li>
            </ul>
          </section>
        </section>
      </section>

      <section *ngIf="allSortedInsurances.length > 0"
        id="insurances-section"
        class="insurance info-module"
        data-test="insurance-section"
      >
        <a id="insurances-link" [href]="currentRoute + '#insurances'"><h2>Insurances</h2></a>
        <ul data-test="insurances-section-list">
          <li *ngFor="let insurance of allSortedInsurances">
            {{ insurance }}
          </li>
        </ul>
      </section>

      <section
        class="locations info-module"
        id="locations-section"
        data-test="location-section"
      >
        <a id="locations-link" [href]="currentRoute + '#locations'"><h2>Locations</h2></a>
        <ul>
          <li *ngFor="let state of providerShareableProfile.credentialed_states">
            {{ state.name }}
          </li>
        </ul>
      </section>
    </div>

    <!-- If we want to show a two-column set of modules, add right column items here -->
    <div class="right-column"></div>
  </div>
</div>