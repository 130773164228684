import { InjectionToken } from '@angular/core';

export const STORAGE = new InjectionToken<Storage>('a usable storage implementation');

/* the minimal surface for Storage classes */
export interface Storage {
  getItem: (key: string) => string;
  setItem: (key: string, data: string) => void;
  removeItem: (key: string) => void;
}

/* enable treating cookies the same as local and session storage in OAuthStorage */
class CookieStorage implements Storage {
  constructor(private prefix: string = '') {}

  getItem(key: string): string {
    const prefix = `${this.prefix + key}=`;
    const pairs = document.cookie.split(/;\s*/);
    const match = pairs.find((v) => v.startsWith(prefix));

    if (match) {
      const value = match.substring(prefix.length);
      return decodeURIComponent(value);
    }
    return '';
  }

  setItem(key: string, value: string, expires: Date = null) {
    let pair = `${this.prefix + key}=${encodeURIComponent(value)}`;
    if (expires) {
      pair += `;expires='${expires.toUTCString()}`;
    }

    document.cookie = pair;
  }

  removeItem(key: string) {
    this.setItem(key, '', new Date(1970, 1, 1));
  }
}

/*
  does the given storage work?
  in safari private mode for example, localStorage/sessionStorage do NOT work
*/
const testStorageKey = '__storage_detect';
function testStorage(storage: Storage) {
  try {
    const nonce = (Math.random() * 10000).toString(32);
    storage.setItem(testStorageKey, nonce);
    if (storage.getItem(testStorageKey) === nonce) {
      storage.removeItem(testStorageKey);
      return true;
    }
  } catch {
    // continue regardless of error
  }

  return false;
}

/* test the available storages and grab one we know works */
export function storageFactory(): Storage {
  if (testStorage(window.sessionStorage)) {
    return window.sessionStorage;
  }

  if (testStorage(window.localStorage)) {
    return window.localStorage;
  }

  const cookieStore = new CookieStorage('__smcp_');
  if (testStorage(cookieStore)) {
    return cookieStore;
  }

  return null;
}
