import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'provider-photo',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './provider-photo.component.html',
  styleUrls: ['./provider-photo.component.scss']
})
export class ProviderPhotoComponent implements OnInit {
  @Input() initials: string = '';
  @Input() photoUrl: string = '';
  @Input() providerName: string = '';

  ngOnInit(): void {
    if (this.photoUrl?.includes('missing.png')) {
      this.photoUrl = '';
    }
  }
}
