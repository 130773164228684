import { Logger, MessageStatus } from './logger';

/** An implementation of a simple logger, which only writes to the console */
export class ConsoleLogger extends Logger {
  log(message: string, context?: object, status?: MessageStatus) {
    console.log(`[DD][${status}] ${message}`, context);
  }

  addAction(name: string, context?: object): void {
    this.log(`::action ${name}`, context, 'info');
  }

  addError(error: unknown, context?: object): void {
    this.log(`::error ${error}`, context, 'error');
  }
}
