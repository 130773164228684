/* eslint-disable no-restricted-globals */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpService } from '@sondermind/http';
import {
  IAPIFetchRequestOptions,
  IAPIFetchResponse,
  IAPIListRequestOptions,
  IAPIListResponse
} from '@sondermind/utilities/models-http';
import { ConfigurationService } from '@sondermind/configuration';
import { IProviderShareableLink, IProviderShareableProfile } from './shareable-link.interfaces';

@Injectable({ providedIn: 'root' })
export class ShareableLinksHttpService {
  constructor(
    private configurationService: ConfigurationService,
    private http: HttpService
  ) {}

  list$(opts: Partial<IAPIListRequestOptions> = {}): Observable<IAPIListResponse<IProviderShareableLink>> {
    const url = `${this.configurationService.env.svcBase}/provider_profiles/public_shareable_links`;
    const params = this.http.generateListParams(opts);
    return this.http
      .get<IAPIListResponse<IProviderShareableLink>>(url, params)
      .pipe(
        map((response) => ({ ...response }))
      );
  }

  fetch$(shareCode: string, opts: Partial<IAPIFetchRequestOptions> = {}): Observable<IAPIFetchResponse<IProviderShareableProfile>> {
    const url = `${this.configurationService.env.svcBase}/provider_profiles/public_shareable_links/${shareCode}`;
    const params = this.http.generateListParams(opts);
    return this.http
      .get<IAPIFetchResponse<IProviderShareableProfile>>(url, params)
      .pipe(
        map((response) => ({ ...response })),
        catchError((error) => {
          this.http.handleHttpError(error);
          return of(error);
        })
      );
  }
}
