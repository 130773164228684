import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { IAPIErrorHandlingOptions } from '@sondermind/utilities/models-http';

export function defaultHandleHttpError(
  err: HttpErrorResponse,
  options: IAPIErrorHandlingOptions
): Observable<never> {
  // server couldn't respond at all - network errors, bug in httpclient, etc.
  if (err.error instanceof ErrorEvent) {
    return throwError(() => ({ base: ['Something didn\'t work, please try again.'], status: 0 }));
  }

  // server responded, but it may be coming from proxies, etc, so it might not be in the right format
  if (typeof err.error === 'string') {
    return throwError(() => ({ base: [err.error], status: err.status }));
  }

  // server responded and it looks nominally like it's in our format, (and we don't want raw errors)
  if (typeof err.error === 'object' && err.error.errors) {
    if (options.rawErrors) {
      return throwError(() => ({ status: err.status, ...err.error }));
    }

    return throwError(() => ({ status: err.status, ...err.error.errors }));
  }

  // no idea at this point, really
  return throwError(() => ({
    base: ['Some request didn\'t work, please try again.'],
    status: err.status,
    statusText: err.statusText,
    url: err.url
  }));
}
