import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringArrayToCommaList',
  standalone: true,
})
export class StringArrayToCommaListPipe implements PipeTransform {
  transform(value: string[],
    useOxfordComma: boolean = true,
    conjunction: 'and' | 'or' | 'and/or' = 'and',
    truncateListLength?: number
  ): string {
    // if the array is undefined or empty, return an empty string
    if (!value || value.length === 0) {
      return '';
    }

    // if the array is one-item long
    if (value.length === 1) {
      return value[0].toString();
    }

    // if we want to truncate the list, the first group will have the specified length
    // Don't truncate lists with 2 items or less
    if (truncateListLength && value.length > truncateListLength && value.length > 2) {
      const firstGroup: string[] = value.slice(0, truncateListLength);

      const commaSeparatedFirstGroup: string = firstGroup.length > 1 ? firstGroup.join(', ') : firstGroup[0];

      // if we want the array truncated with oxford comma, 'a, a, and more'
      // only allowed in cases where the first group is multiple items
      if (useOxfordComma && firstGroup.length > 1) {
        return `${commaSeparatedFirstGroup}, ${conjunction} more`;
      }

      return `${commaSeparatedFirstGroup} ${conjunction} more`;
    }

    // otherwise, the array is 2+, so split the last one from the first set: aaa b
    const lastIndex: number = value.length - 1;
    const lastItem: string = value[lastIndex];
    const firstGroup: string[] = value.slice(0, lastIndex);

    // convert the first set into a comma separated list: a, a, a
    const commaSeparatedFirstGroup: string = firstGroup.join(', ');

    // add an oxford comma for arrays of 2+ length, when you want it: a, a, a, and b
    if (useOxfordComma && value.length > 2) {
      return `${commaSeparatedFirstGroup}, ${conjunction} ${lastItem}`;
    }

    // otherwise return the last one after an and: a, a, a and b
    return `${commaSeparatedFirstGroup} ${conjunction} ${lastItem}`;
  }
}
