<section class="carousel-section">
  <p class="no-availability">{{ providerProfile.display_first_name }} doesn't have availability currently.</p>
  <h2 class="connect-with-similar">Connect with similar providers.</h2>
  <similar-providers-carousel
    *ngIf="!isErrorLoadingProviders
      && (loadingSimilarProviders || similarProviders.length)"
    [loadingSimilarProviders]="loadingSimilarProviders"
    [similarProviders]="similarProviders"
  ></similar-providers-carousel>
  <iris-button
    class="see-more-button"
    iconPosition="right"
    (click)="onClickLinkToProviderDirectory()"
    [linkHref]="providerDirectoryLink"
    [target]="newTabTarget"
  >
    See more providers 
    <iris-icon
      name="arrow_line_right"
      size="sm"
      color="white"
    ></iris-icon>
  </iris-button>
</section>
<section class="need-support-section">
  <iris-icon
    name="call"
    size="lg"
    color="white"
  ></iris-icon>
  <span>
    <strong>Need support?</strong> Our Care Coordinators can assist you in finding a therapist.
    <a href="tel:{{ bookSessionPhoneLink }}">{{bookSessionPhoneDisplay}}</a>
  </span>
</section>
