// eslint-disable-next-line import/no-extraneous-dependencies
import { StatusType } from '@datadog/browser-logs';

/** Re-exported type-level enum of log levels (info, debug, etc.) */
export type MessageStatus = StatusType;

/** A concrete default implementation for a simple logger */
export abstract class Logger {
  abstract log(message: string, context?: object, status?: MessageStatus): void;
  abstract addAction(name: string, context?: object): void;
  abstract addError(error: unknown, context?: object): void;

  debug(message: string, context?: object): void {
    this.log(message, context, 'debug');
  }

  info(message: string, context?: object): void {
    this.log(message, context, 'info');
  }

  warn(message: string, context?: object): void {
    this.log(message, context, 'warn');
  }

  error(message: string, context?: object): void {
    this.log(message, context, 'error');
  }
}
