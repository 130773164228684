import { AbstractControl } from '@angular/forms';

export class PasswordValidator {
  static format(c: AbstractControl): { [key: string]: any; } | null {
    if (typeof c.value === 'undefined' || c.value === null || c.value.toString().trim().length === 0) {
      return null;
    }

    if (c.value.toString().length < 9) {
      return { passwordFormat: 'Password length must be more than 9' };
    }
    if (!c.value.toString().match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)) {
      return { passwordFormat: 'Passwords MUST have at least 1 Number, 1 Uppercase Letter and 1 Lowercase Letter' };
    }
    return null;
  }
}
