import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ConfigurationService, IProviderShareableLinkAppConfig } from '@sondermind/configuration';
import { ShareableLinksHttpService } from '../../shareable-links.httpservice';

export interface IProviderLink {
  name: string;
  link: string;
}

@Component({
  selector: 'directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss'],
})
export class DirectoryComponent {
  providers: IProviderLink[] = [];
  currentPage: number = 1;
  totalPages: number;
  matchflowBaseUrl: string;
  constructor(
    private shareableLinksHttpService: ShareableLinksHttpService,
    private metaTagService: Meta,
    private configurationService: ConfigurationService<IProviderShareableLinkAppConfig>,
  ) {}

  ngOnInit(): void {
    this.matchflowBaseUrl = this.configurationService.env.app.flowHost;
    this.loadPage(this.currentPage);
  }

  private loadPage(page: number): void {
    this.providers = [];
    this.currentPage = page;

    this.shareableLinksHttpService.list$(
      {
        page: { enabled: true, size: 50, number: this.currentPage },
        sort: [{ key: 'display_name', asc: true }]
      }
    ).subscribe((response) => {
      response.data.forEach((provider) => {
        const link = provider.url;
        const name = provider.display_name;
        this.providers.push({ name, link });
      });
      this.totalPages = Math.ceil(response.meta.counts.total / 50);
      this.updatePaginationLinks();
    });
  }

  goToNextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.loadPage(this.currentPage + 1);
    }
  }

  goToPreviousPage(): void {
    if (this.currentPage > 1) {
      this.loadPage(this.currentPage - 1);
    }
  }

  private updatePaginationLinks(): void {
    const baseUrl = window.location.href.split('?')[0]; // Replace with your base URL
    const prevPage = this.currentPage > 1 ? `${baseUrl}?page=${this.currentPage - 1}` : null;
    const nextPage = this.currentPage < this.totalPages ? `${baseUrl}?page=${this.currentPage + 1}` : null;

    this.metaTagService.updateTag({ name: 'robots', content: 'follow, index' });

    if (prevPage) {
      this.metaTagService.updateTag({ rel: 'prev', href: prevPage });
    } else {
      this.metaTagService.removeTag("rel='prev'");
    }

    if (nextPage) {
      this.metaTagService.updateTag({ rel: 'next', href: nextPage });
    } else {
      this.metaTagService.removeTag("rel='next'");
    }
  }
}
