import { AbstractControl } from '@angular/forms';

export class SimpleDateValidator {
  static format(c: AbstractControl): { [key: string]: any; } {
    if (typeof c.value === 'undefined' || c.value === null || c.value.toString().trim().length === 0) {
      return null;
    }
    try {
      if (c.value.match(/^\d{2}\/\d{2}\/\d{4}$/) === null) {
        throw new Error('Format Error');
      }

      const splitArray = c.value.split('/');
      const mm = splitArray[0];
      const dd = splitArray[1];
      const yy = splitArray[2];

      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

      if (yy <= 0 || yy > 2200) {
        throw new Error('Range Error');
      }

      if (mm <= 0 || mm > 12) {
        throw new Error('Range Error');
      }

      if (dd <= 0) {
        throw new Error('Range Error');
      }

      if (mm === 2) {
        let leap = false;
        if ((!(yy % 4) && yy % 100) || !(yy % 400)) {
          leap = true;
        }

        if (leap === false && dd >= 29) {
          throw new Error('Range Error');
        }

        if (leap === true && dd >= 30) {
          throw new Error('Range Error');
        }
      } else if (dd > ListofDays[mm - 1]) {
        throw new Error('Range Error');
      }
    } catch (ex) {
      if (ex.message === 'Format Error') {
        return { dateFormat: 'Dates must be in correct format of MM/DD/YYYY Month/Day/Year' };
      }

      if (ex.message === 'Range Error') {
        return { dateRange: 'Please enter a valid date range' };
      }
    }

    return null;
  }
}
