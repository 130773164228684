import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject, catchError, map, Observable, of
} from 'rxjs';

import { IEnvironmentConfig } from './configuration.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService<T = void> {
  configSubj: BehaviorSubject<IEnvironmentConfig<T>> = new BehaviorSubject({} as IEnvironmentConfig<T>);
  private httpClient: HttpClient;

  constructor(
    private httpBackend: HttpBackend
  ) {
    this.httpClient = new HttpClient(httpBackend);
  }

  load(): Observable<boolean> {
    return this.httpClient.get(
      this.configUrl,
    ).pipe(
      map((res) => {
        const castedObj = res as IEnvironmentConfig<T>;
        this.configSubj.next(castedObj);
        return true;
      }),
      catchError((err) => {
        // log an error for cases that aren't karma test suites
        if (this.isKarmaTestSuiteRunning === false) {
          console.error(`Error loading environment configuration: ${this.configUrl}`, err);
        }
        return of(false);
      })
    );
  }

  private get configUrl(): string {
    return `/assets/config/${this.host}.json`;
  }

  private get host(): string {
    // eslint-disable-next-line no-restricted-globals
    return location.host;
  }

  private get isKarmaTestSuiteRunning(): boolean {
    return this.host.includes('localhost:98');
  }

  get env(): IEnvironmentConfig<T> {
    return this.configSubj.value;
  }
}
