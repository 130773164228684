import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IMiniProviderPreview } from '@sondermind/features/provider-listing-card';
import { SimilarProvidersHttpService } from '../services/similar-providers.httpservice';
import { ISimilarProvidersParams } from '../models/similar-providers-params.interface';

@Injectable({
  providedIn: 'root'
})
export class SimilarProvidersStore {
  constructor(private similarProvidersHttpService: SimilarProvidersHttpService) {}

  loadSimilarProviders$(
    similarProvidersParams: ISimilarProvidersParams
  ): Observable<IMiniProviderPreview[]> {
    return this.similarProvidersHttpService
      .listSimilarProviders$(similarProvidersParams)
      .pipe(
        map((response) => response.data)
      );
  }
}
