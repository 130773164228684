import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { ParamMap, RouterModule } from '@angular/router';

import {
  IQuickCheckoutModalConfigData, QuickCheckoutComponent
} from '@sondermind/features/public-facing/quick-checkout';
import {
  IconColors, IconSizes, IrisIconModule, IrisModalService, IrisModalSize
} from '@sondermindorg/iris-design-system-angular';
import { SegmentService } from '@sondermind/segment';
import { SessionType, SessionTypeDisplay } from '@sondermind/utilities/models-session';
import { BreakpointsService } from '@sondermind/utilities/services';

import { IProviderShareableProfile, StateInfoType } from '../../shareable-link.interfaces';
import { IRequestSessionParams } from '../../models/request-session-params.interface';
import {
  NonAcceptingMatchOptionsComponent
} from '../non-accepting-match-options/non-accepting-match-options.component';
import {
  IPslSegmentSchema,
  PslRequestSessionClickData,
  PslSegmentEvents
} from '../../models/segment/psl-segment-schema.interface';

enum LandingPageHeaderParam {
  ANON_USER_ID = 'anonUserId',
  NAME = 'name',
  PAYMENT_PREF = 'paymentPref',
  PAYMENT_TAG = 'paymentTag',
  SHARE_CODE = 'share_code',
  STATE = 'state',
  REFERRING_URL = 'referringUrl'
}

@Component({
  selector: 'landing-page-header',
  standalone: true,
  imports: [
    CommonModule,
    IrisIconModule,
    RouterModule,
    NonAcceptingMatchOptionsComponent
  ],
  templateUrl: './landing-page-header.component.html',
  styleUrls: ['./landing-page-header.component.scss']
})
export class LandingPageHeaderComponent implements OnInit {
  @Input() queryParams: ParamMap;
  @Input() routeParams: ParamMap;
  @Input() profile!: IProviderShareableProfile;
  @Input() showSimilarProvidersFf: boolean = false;
  @Input() useCheckoutFormFf: boolean = false;

  credentialStatesFormatted: string = '';
  maxLengthOfInsuranceListString = 56;
  noProfilePhoto: boolean = true;
  paramsShareCode: string = '';
  paramsName: string = '';
  providerInsurances: string = '';
  requestSessionQueryParams: IRequestSessionParams = null; // For tracking the user coming from .com site
  sessionTypeString: string = '';

  iconColor: IconColors = IconColors.BRAND1;
  iconSizes = IconSizes;

  constructor(
    public breakpointService: BreakpointsService,
    private irisModalService: IrisModalService,
    private segmentService: SegmentService<IPslSegmentSchema>
  ) {}

  ngOnInit(): void {
    this.noProfilePhoto = this.profile.profile_photo_url.endsWith('/missing.png');

    this.paramsShareCode = this.routeParams.get(LandingPageHeaderParam.SHARE_CODE) ?? '';

    this.paramsName = this.routeParams.get(LandingPageHeaderParam.NAME) ?? '';

    this.requestSessionQueryParams = this.handleIncomingQueryParams(this.queryParams);

    this.sessionTypeString = this.formatSessionTypeList(this.profile.session_types_offered);

    this.providerInsurances = this.profile.insurances.join(', ');

    if (this.profile.credentialed_states.length > 0) {
      this.credentialStatesFormatted = this.formatCredStates(this.profile.credentialed_states);
    }
  }

  private formatSessionTypeList(sessionTypes: string[]): string {
    const arrayToJoin: string[] = [];

    if (sessionTypes.includes(SessionType.IN_PERSON)) {
      arrayToJoin.push(SessionTypeDisplay.IN_PERSON);
    }
    if (sessionTypes.includes(SessionType.VIDEO)) {
      arrayToJoin.push(SessionTypeDisplay.VIDEO);
    }

    return arrayToJoin.join(', ');
  }

  private formatCredStates(states: StateInfoType[]): string {
    let formattedString = '';

    states.forEach((state, i) => {
      if (i !== 0 && i < states.length - 1) {
        formattedString += ', ';
      }
      if (states.length > 2 && i === states.length - 1) {
        // oxford comma
        formattedString += ',';
      }
      if (states.length > 1 && i === states.length - 1) {
        formattedString += ' and ';
      }
      formattedString += state.name;
    });

    return formattedString;
  }

  scrollToElement(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  openRequestASessionModal(): void {
    const quickCheckoutInputData = {
      providerCredentialedStates: this.profile.credentialed_states.map((state) => (state.name)),
      providerAcceptedInsuranceIds: this.profile.provider_network_company_ids,
      providerId: this.profile.id,
      requestedTimeSlot: null // TODO: When calendar is hooked up, pass it in here
    };

    const modalRef = this.irisModalService.open<QuickCheckoutComponent, IQuickCheckoutModalConfigData, string>(
      QuickCheckoutComponent,
      {
        size: IrisModalSize.MD,
        data: quickCheckoutInputData,
      }
    );
  }

  onRequestASessionClick(): void {
    from(this.sendRequestSessionSegmentEvent()).subscribe();
  }

  async sendRequestSessionSegmentEvent(): Promise<void> {
    const clickData: PslRequestSessionClickData = {
      accepting: this.profile?.accepting_clients,
      flow: 'shareable-link-match-flow',
      personaProviderId: this.profile.id,
      showSimilarProviders: this.showSimilarProvidersFf
    };

    return this.segmentService.track<PslSegmentEvents.PSL_REQUEST_A_SESSION_CLICKED>(
      PslSegmentEvents.PSL_REQUEST_A_SESSION_CLICKED,
      clickData
    );
  }

  private handleIncomingQueryParams(queryParams: ParamMap): IRequestSessionParams {
    const incomingState: string | null = queryParams.get(LandingPageHeaderParam.STATE);
    const incomingPaymentPreference: string | null = queryParams.get(LandingPageHeaderParam.PAYMENT_PREF);
    const incomingInsuranceTag: string | null = queryParams.get(LandingPageHeaderParam.PAYMENT_TAG);

    let requestSessionQueryParams: IRequestSessionParams = {
      anonUserId: queryParams.get(LandingPageHeaderParam.ANON_USER_ID),
      referringUrl: queryParams.get(LandingPageHeaderParam.REFERRING_URL)
        ?? encodeURIComponent(window.location.href),
    };
    if (incomingState) {
      requestSessionQueryParams = { ...requestSessionQueryParams, state: incomingState };
    }
    if (incomingPaymentPreference) {
      requestSessionQueryParams = { ...requestSessionQueryParams, paymentPref: incomingPaymentPreference };
    }
    if (incomingInsuranceTag) {
      requestSessionQueryParams = { ...requestSessionQueryParams, paymentTag: incomingInsuranceTag };
    }

    return requestSessionQueryParams;
  }
}
