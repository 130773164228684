/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable import/no-extraneous-dependencies */
import { ModuleWithProviders, NgModule } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum, RumEvent, RumInitConfiguration } from '@datadog/browser-rum';
import { IEnvironmentConfig } from '@sondermind/configuration';
import { Environment } from '@sondermind/utilities/models-environment';

import { Logger, MessageStatus } from './loggers/logger';
import { ConsoleLogger } from './loggers/console-logger';
import { DatadogLogger } from './loggers/datadog-logger';
import { DataDogConfiguration } from './configuration.interface';

export { Logger, MessageStatus };

@NgModule({})
export class DatadogLoggingModule {
  constructor() {}

  static bootstrap(env: IEnvironmentConfig) {
    // if there is no token, skip all of this.
    if (env.dataDogClientToken == null) { return }

    // create a config
    const tracingUrls = [
      env.urlBase,
      env.apiBase,
      env.svcBase,
      env.authBase
    ];

    const tracingOrigins = tracingUrls
      .filter((x) => x != null && x !== '')
      .map((x) => new URL(x).origin);

    const dataDogConfig: DataDogConfiguration = {
      applicationId: env.dataDogApplicationID,
      clientToken: env.dataDogClientToken,
      environment: env.dataDogEnvironment || 'local',
      tracingOrigins,
      urlBase: env.urlBase,
      enableDataDogSessionReplay: env.enableDataDogSessionReplay || false,
      enableDataDogRum: env.enableDataDogRum || false
    };

    // initialize
    this.initializeDataDog(dataDogConfig);
  }

  static initializeDataDog(config: DataDogConfiguration): void {
    const options: RumInitConfiguration = {
      applicationId: config.applicationId,
      clientToken: config.clientToken,
      service: config.urlBase,
      defaultPrivacyLevel: 'mask',
      env: config.environment,
      trackUserInteractions: config.enableDataDogSessionReplay,
      allowedTracingOrigins: config.tracingOrigins,
      sampleRate: 100
    };
    options.enableExperimentalFeatures = ['feature_flags'];
    // eslint-disable-next-line consistent-return
    options.beforeSend = (event: RumEvent, context) => {
      if (event.type === 'action' && event.action.type === 'click') {
        const ev: any | null = 'events' in context
          ? context['events']?.[0]
          : null;

        if (ev?.target) {
          // By default we want to mask all action names.
        // We don't need to mask if:
        // 1) somewhere in the path to element we find name set in data-dd-action-name
        // 2) somehwere in the path to element we find data-dd-privacy set to 'allow'
          let node = ev?.target;
          let maskIt = true;
          while (node) {
            if (node.getAttribute &&
                      (node.getAttribute('data-dd-action-name') || node.getAttribute('data-dd-privacy') === 'allow')) {
              maskIt = false;
              break;
            }
            node = node.parentElement;
          }
          if (maskIt && event.action?.target?.name) {
            const name = event.action?.target?.name || '';
            // Replace all non space characters with X to obfuscate this action name
            // eslint-disable-next-line no-use-before-define, no-param-reassign
            event.action.target.name = name.replace(/\S/g, 'X');
          }
        }
      }
    };

    if (config.enableDataDogRum) {
      datadogRum.init(options);
    }

    if (config.enableDataDogSessionReplay) {
      datadogRum.startSessionReplayRecording();
    }

    datadogLogs.init({
      clientToken: config.clientToken,
      service: config.urlBase,
      env: config.environment,

      // don't forward anything by default, we're only sending our own
      forwardConsoleLogs: [],
      forwardErrorsToLogs: false,
      forwardReports: [],
    });
  }

  static forRoot(env: Environment): ModuleWithProviders<DatadogLoggingModule> {
    const provider = env.production
      ? { provide: Logger, useClass: DatadogLogger }
      : { provide: Logger, useClass: ConsoleLogger };

    return { ngModule: DatadogLoggingModule, providers: [provider] };
  }
}
