/* eslint-disable import/no-extraneous-dependencies */
import * as Sentry from '@sentry/angular';
import { CaptureContext } from '@sentry/types';
import { IEnvironmentConfig } from '@sondermind/configuration';

export class SentryClient {
  static captureException(exception: any, captureContext?: CaptureContext): string {
    return Sentry.captureException(exception, captureContext);
  }

  static captureMessage(message: string, captureContext?: CaptureContext | Sentry.Severity): string {
    return Sentry.captureMessage(message, captureContext);
  }

  static configureScope(callback: (scope: Sentry.Scope) => void): void {
    Sentry.configureScope(callback);
  }

  static initialize(config: IEnvironmentConfig, commitHash: string): void {
    Sentry.init({
      ...config.sentryDSN,
      release: commitHash,
      ignoreErrors: [
        'Non-Error exception captured',
        'Handled unknown error'
      ],
      beforeSend: (event) => {
        if (config.sentryDebug) {
          // eslint-disable-next-line no-console
          console.error('Sentry Error: ', event);
          return null;
        }

        return event;
      }
    });
  }

  static setUser(user: Sentry.User): void {
    Sentry.setUser(user);
  }
}
