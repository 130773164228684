import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigurationService } from '@sondermind/configuration';
import { HttpService } from '@sondermind/http';
import {
  IAPIListRequestOptions,
  IAPIListResponse
} from '@sondermind/utilities/models-http';
import { IMiniProviderPreview } from '@sondermind/features/provider-listing-card';
import { ISimilarProvidersParams } from '../models/similar-providers-params.interface';
import { SimilarProvidersParam } from '../models/enums/similar-providers-param.enum';

@Injectable({ providedIn: 'root' })
export class SimilarProvidersHttpService {
  private readonly defaultMaxNumberOfRequestedProviders = 5;

  constructor(
    private configurationService: ConfigurationService,
    private http: HttpService
  ) {}

  listSimilarProviders$(
    similarProvidersParams: ISimilarProvidersParams,
    opts: Partial<IAPIListRequestOptions> = {}
  ): Observable<IAPIListResponse<IMiniProviderPreview>> {
    // eslint-disable-next-line max-len
    const rootUrl = `${this.configurationService.env.svcBase}/provider_profiles/similar_providers`;
    const url = `${rootUrl}/${similarProvidersParams.personaProviderId}`;
    let params = this.http.generateListParams(opts);
    params = this.parseSimilarProviderParams(similarProvidersParams, params);

    return this.http
      .get<IAPIListResponse<IMiniProviderPreview>>(url, params)
      .pipe(
        this.http.handleHttpErrorStrict()
      );
  }

  private parseSimilarProviderParams(
    similarProviderParams: ISimilarProvidersParams,
    params: HttpParams
  ): HttpParams {
    let resultParams = params;
    resultParams = resultParams.set(SimilarProvidersParam.STATE, similarProviderParams.state);

    if (similarProviderParams.genderId) {
      resultParams = resultParams.set(
        SimilarProvidersParam.GENDER_ID,
        similarProviderParams.genderId.toString()
      );
    }
    if (similarProviderParams.providerNetworkCompanyId) {
      resultParams = resultParams.set(
        SimilarProvidersParam.INSURANCE_ID,
        similarProviderParams.providerNetworkCompanyId.toString()
      );
    }

    if (similarProviderParams.modalityIds?.length > 0) {
      const modalitiesParam = similarProviderParams.modalityIds.join(',');
      resultParams = resultParams.set(
        SimilarProvidersParam.MODALITY_IDS,
        modalitiesParam
      );
    }

    if (similarProviderParams.specialtyIds?.length > 0) {
      const specialtiesParam = similarProviderParams.specialtyIds.join(',');
      resultParams = resultParams.set(
        SimilarProvidersParam.SPECIALTY_IDS,
        specialtiesParam
      );
    }

    const maxNumber = similarProviderParams.maxNumberOfProviders
      ? similarProviderParams.maxNumberOfProviders
      : this.defaultMaxNumberOfRequestedProviders;

    resultParams = resultParams.set(
      SimilarProvidersParam.MAX_NUMBER_OF_PROVIDERS,
      maxNumber
    );

    return resultParams;
  }
}
