<div *ngIf="!loadingSimilarProviders; else loadingCarousel" class="carousel-container">
  <p-carousel
    [ngClass]="{
      'non-scrollable-carousel': loadedProviders.length <= 3,
      'multiple-results': loadedProviders.length > 1
    }"
    [value]="loadedProviders"
    [numVisible]="numVisible"
    [numScroll]="1"
    [responsiveOptions]="responsiveOptions"
    [circular]="loadedProviders.length > 3"
  >
    <ng-template let-provider pTemplate="item">
      <mini-provider-listing-card 
        [providerListing]="provider"
        (click)="onSimilarProviderClick(provider)"
      />
    </ng-template>
  </p-carousel>
</div>

<ng-template #loadingCarousel>
  <div class="loading-carousel">
    <mini-provider-listing-card-loading class="loading-card-1"></mini-provider-listing-card-loading>
    <mini-provider-listing-card-loading class="loading-card-2"></mini-provider-listing-card-loading>
    <mini-provider-listing-card-loading class="loading-card-3"></mini-provider-listing-card-loading>
  </div>
</ng-template>
