<div class="footer-container" data-test="footer-section">
  <div class="dark-sections">
    <div class="first-section">
      <div class="logo-section">
        <a href="https://www.sondermind.com" target="_blank">
          <img
            class="logo"
            src="/assets/sm-logos/logotype-white.svg"
            alt="SonderMind Logo"
            fetchpriority="low"
          >
        </a>
      </div>
      <div class="concerns" *ngIf="currentStateAbbrev">
        <h3 class="header">Find Therapist By Speciality</h3>
        <a href="{{providerDirectoryUrl}}?state={{currentStateAbbrev}}&specialties=Anxiety" target="_blank">Anxiety Specialists</a>
        <a href="{{providerDirectoryUrl}}?state={{currentStateAbbrev}}&specialties=Depression" target="_blank">Depression Specialists</a>
        <a href="{{providerDirectoryUrl}}?state={{currentStateAbbrev}}&specialties=Stress" target="_blank">Stress Specialists</a>
        <a href="{{providerDirectoryUrl}}?state={{currentStateAbbrev}}&specialties=Life Transitions" target="_blank">Life Transitions Specialists</a>
        <a href="{{providerDirectoryUrl}}?state={{currentStateAbbrev}}&specialties=Trauma" target="_blank">Trauma Specialists</a>
      </div>
      <div class="services">
        <h3 class="header">Our Services</h3>
        <a href="https://www.sondermind.com/therapy" target="_blank">Therapy</a>
        <a href="https://www.sondermind.com/psychiatry" target="_blank">Psychiatry</a>
      </div>
      <div class="links">
        <h3 class="header">Helpful Links</h3>
        <a data-test= "meet-therapist-link" href="{{therapyMatchFlowUrl}}" target="_blank">Meet with a Therapist</a>
        <a data-test="meet-psychiatrist-link" href="{{psychiatryMatchFlowUrl}}" target="_blank">Meet with a Psychiatric Provider</a>
        <a href="https://www.sondermind.com/how-sondermind-works" target="_blank" rel="help">How SonderMind Works</a>
        <a href="https://www.sondermind.com/insurance" target="_blank" rel="help">Using your Insurance</a>
        <a href="https://www.sondermind.com/resources/articles-and-content" target="_blank">Articles and Content</a>
      </div>
    </div>
    <div class="second-section">
      <h2>In Case of Emergency</h2>
      <div>
        If you are experiencing a life-threatening emergency, please call 911.
        If you are experiencing a mental health emergency, please call 988 for the Suicide and Crisis Lifeline. 
        Or, use 
        <a href="{{ emergencyResources }}" target="_blank" class="emergency-link" data-test="emergency-link">these resources</a> 
        to get immediate help.
      </div>
    </div>
  </div>

  <div class="gray-section">
    <a href="https://www.sondermind.com/privacy-policy" target="_blank">Privacy Policy</a>
    <a href="https://www.sondermind.com/terms-of-service" target="_blank">Terms of Service</a>
    <a href="https://www.sondermind.com/financial-responsibility-agreement" target="_blank">Financial Responsibility</a>
    <a href="https://www.sondermind.com/notice-of-privacy-practices" target="_blank">Notice of Privacy Practices</a>
    <div class="icons">
      <a href="https://www.facebook.com/SonderMind/" target="_blank">
        <img src="/assets/facebook.svg" alt="Facebook Logo" role="img" class="social-media-logo" fetchpriority="low">
      </a>
      <a href="https://www.instagram.com/sondermind/" target="_blank">
        <img src="/assets/instagram.svg" alt="Instagram Logo" role="img" class="social-media-logo" fetchpriority="low">
      </a>
      <a href="https://www.linkedin.com/company/sondermind/" target="_blank">
        <img src="/assets/linkedin.svg" alt="LinkedIn Logo" role="img" class="social-media-logo" fetchpriority="low">
      </a>
      <a href="https://www.youtube.com/channel/UCSKDPvHeP-30S21iPnQ9Oxw" target="_blank">
        <img src="/assets/youtube.svg" alt="Youtube Logo" role="img" class="social-media-logo" fetchpriority="low">
      </a>
      <a href="https://www.tiktok.com/@_sondermind" target="_blank">
        <img src="/assets/tiktok.svg" alt="TikTok Logo" role="img" class="social-media-logo" fetchpriority="low">
      </a>
    </div>  
  </div>

  <div class="white-section">
    Sondermind Inc. © 2023
  </div>
</div>
