import { Injectable, Inject, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseKeys from 'camelcase-keys';

import {
  HttpService,
  BaseHTTPService,
  HandleErrorOptions
} from '@sondermind/http';
import {
  IHttpErrorHandler,
  HTTP_ERROR_HANDLER
} from '@sondermind/utilities/models-http';
import { ConfigurationService } from '@sondermind/configuration';
import { ClientUser, ClientUserProfile } from '@sondermind/utilities/models-user-client';

import { ApiClientConversionData } from '../models/api-client-conversion-data';
import { BillingClientProfile } from '../models/billing-client-profile';

interface IUpdateProfileOptions extends HandleErrorOptions {
  verify: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserDataService extends BaseHTTPService {
  constructor(
    private configurationService: ConfigurationService,
    private http: HttpService,
    @Optional()
    @Inject(HTTP_ERROR_HANDLER)
    httpErr: IHttpErrorHandler[]
  ) {
    super(httpErr);
  }

  fetch(opts: Partial<HandleErrorOptions> = {}): Observable<ClientUser | unknown> {
    const url = `${this.configurationService.env.apiBase}/v2/users/me`;
    return this.http
      .get<{ user: ClientUser; }>(url)
      .pipe(
        map((p) => p.user),
        catchError(this.handleError.bind(this, opts))
      );
  }

  updatePassword(password: string, opts: Partial<HandleErrorOptions> = {}): Observable<ClientUser | unknown> {
    const url = `${this.configurationService.env.apiBase}/v2/users/me`;
    return this.http
      .patch<{ user: ClientUser; }>(url, { user: { password, passwordConfirmation: password } })
      .pipe(
        map((p) => p.user),
        catchError(this.handleError.bind(this, opts))
      );
  }

  updateProfile(
    data: Partial<ClientUserProfile>, opts: Partial<IUpdateProfileOptions> = {}
  ): Observable<ClientUserProfile | unknown> {
    // TODO: Create and move to new V3 service
    const url = `${this.configurationService.env.svcBase}/core/user_profiles/${data.userId}`;
    const body = { profile: this.convertToRequestFormat<ClientUserProfile>(data) as { verified?: boolean; } };

    if (opts.verify) {
      body.profile.verified = true;
    }

    return this.http
      .patch<{ data: ClientUserProfile; }>(url, body)
      .pipe(
        map((p) => this.convertToApplicationFormat<ClientUserProfile>(p.data)),
        catchError(this.handleError.bind(this, opts))
      );
  }

  updateBillingClientProfileRequest(
    id: number,
    data: Partial<BillingClientProfile>,
    opts: Partial<HandleErrorOptions> = {}
  ): Observable<boolean | unknown> {
    // TODO: Create and move to new V3 service
    const url = `${this.configurationService.env.svcBase}/billing/client_profile/${id}`;
    return this.http
      .patch<{ data: BillingClientProfile; }>(url, this.convertToRequestFormat<BillingClientProfile>(data))
      .pipe(
        map((p) => !!this.convertToApplicationFormat<BillingClientProfile>(p.data)?.allowMultipayor),
        catchError(this.handleError.bind(this, opts))
      );
  }

  billingProfileAllowsMultipayor(id: number, opts: Partial<HandleErrorOptions> = {}): Observable<boolean | unknown> {
    const url = `${this.configurationService.env.svcBase}/billing/client_profile/${id}`;
    return this.http
      .get<{ data: BillingClientProfile; }>(url)
      .pipe(
        map((p) => !!this.convertToApplicationFormat<BillingClientProfile>(p.data).allowMultipayor),
        catchError(this.handleError.bind(this, opts))
      );
  }

  sendVisitedAccountSetupUrl(personaClientId: number, opts: Partial<HandleErrorOptions> = {}): void {
    const url = `${this.configurationService.env.svcBase}/referrals/client_setup/${personaClientId}`;

    this.http
      .post<void>(url, {})
      .pipe(
        map(() => undefined),
        catchError(this.handleError.bind(this, opts))
      ).subscribe(
        () => {
          // no-op
        }
      );
  }

  getClientConversionData(opts: Partial<HandleErrorOptions> = {}): Observable<boolean | unknown> {
    const url = `${this.configurationService.env.svcBase}/metrics/client_conversion_data`;
    return this.http
      .get<{ data: ApiClientConversionData[]; }>(url)
      .pipe(
        map((response) => response.data[0].has_claim),
        catchError(this.handleError.bind(this, opts))
      );
  }

  private convertToRequestFormat<Type>(userData: Partial<Type>): object {
    return snakeCaseKeys(userData);
  }

  private convertToApplicationFormat<Type>(snakeCasedObject: object): Partial<Type> {
    return camelCaseKeys(snakeCasedObject, { deep: true }) as Partial<Type>;
  }
}
