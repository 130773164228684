export enum MatchCategory {
  TOP_RECOMMENDED = 'top_recommended',
  GREAT_WITH_NEW_CLIENTS = 'top_engagement',
  OTHER_TOP_SCORERS = 'other_top_scorers',
  NEEDS_A_MATCH = 'needs_a_match',
  RANDOM = 'random'
}

export const NonDisplayedMatchCategories = new Set([
  MatchCategory.NEEDS_A_MATCH,
  MatchCategory.RANDOM
]);
