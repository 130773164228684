import { NgModule } from '@angular/core';

import { AvailabilityPipe } from './pipes/availability.pipe';
import { CardIconPipe } from './pipes/card-icon.pipe';
import { ChatRoomParticipantNamePipe } from './pipes/chat-room-participant-name.pipe';
import { DateFnsFormatPipe } from './pipes/date-fns-format.pipe';
import { DateTZPipe } from './pipes/datetz.pipe';
import { FormatTimeAgoPipe } from './pipes/format-time-ago.pipe';
import { JoinArrayPipe } from './pipes/join-array.pipe';
import { LineBreakPipe } from './pipes/line-break.pipe';
import { NetworkNameFromTitle } from './pipes/network-name-from-title.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { ResourceEmptyPipe } from './pipes/resource-empty.pipe';
import { ResourceFullPipe } from './pipes/resource-full.pipe';
import { ResourcePristinePipe } from './pipes/resource-pristine.pipe';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { SortMatchHighlightsPipe } from './pipes/sort-match-highlights.pipe';
import { TherapistPronounStringPipe } from './pipes/therapist-pronoun-string.pipe';
import { UnsafeHtmlPipe } from './pipes/unsafe-html.pipe';

const PIPES = [
  AvailabilityPipe,
  CardIconPipe,
  ChatRoomParticipantNamePipe,
  DateFnsFormatPipe,
  DateTZPipe,
  FormatTimeAgoPipe,
  JoinArrayPipe,
  LineBreakPipe,
  NetworkNameFromTitle,
  PhoneNumberPipe,
  PluralizePipe,
  ResourceEmptyPipe,
  ResourceFullPipe,
  ResourcePristinePipe,
  SentenceCasePipe,
  SortMatchHighlightsPipe,
  TherapistPronounStringPipe,
  UnsafeHtmlPipe,
];

@NgModule({
  imports: [],
  declarations: [
    ...PIPES,
  ],
  exports: [
    ...PIPES
  ]
})
export class PipesModule {}
