<div class="provider-listing-container" data-test="provider-card">
  <div class="desktop-tablet-photo-container" data-test="photo-container">
    <provider-photo
      [initials]="providerListing.display_name | initials"
      [photoUrl]="providerListing.profile_photo_url"
      [providerName]="providerListing.display_name">
    </provider-photo>
  </div>

  <div class="mobile-id-container">
    <div class="photo-container">
      <provider-photo
        [initials]="providerListing.display_name | initials"
        [photoUrl]="providerListing.profile_photo_url"
        [providerName]="providerListing.display_name">
      </provider-photo>
    </div>

    <div class="mobile-id-panel">
      <div class="name">
        <a
          [href]="profileLinkUrl"
          target="_blank"
          (click)="onClickViewProfile()"
        >
          {{ providerListing.display_name }}
        </a>
        <span class="pronouns" *ngIf="providerListing.pronouns">({{ providerListing.pronouns }})</span>
      </div>

      <div class="insurance">
        <iris-icon
          name="verified_user"
          [size]="irisIconSizes.SM"
        ></iris-icon>
        <span>Accepts {{ insurance }}</span>
      </div>
    </div>
  </div>


  <div class="mobile-bio-panel">
    <div class="credentials">
      <p *ngFor="let credential of providerListing.certifications">
        {{ credential.name }}
      </p>
    </div>

    <p class="specialties" *ngIf="specialtyNamesArray.length > 0">
      <span class="label">Specialties include</span> {{ specialtyNamesArray | stringArrayToCommaList: true:'and':2 }}
    </p>

    <p class="bio">{{ shortenedBio }}...</p>

    <div class="session-types">
      <div
        class="labeled-image"
        *ngIf="offersSessionsVideo"
      >
        <iris-icon
          name="videocam"
          [size]="irisIconSizes.MD"
        ></iris-icon>
        <span>Video sessions</span>
      </div>
      <div
        class="labeled-image"
        *ngIf="offersSessionsInPerson"
      >
        <iris-icon
          name="location_on"
          [size]="irisIconSizes.MD"
        ></iris-icon>
        <span>In-person sessions</span>
      </div>
    </div>
  </div>

  <div class="desktop-bio-panel">
    <p class="name" data-test="provider-name">
      <a
        [href]="profileLinkUrl"
        target="_blank"
        (click)="onClickViewProfile()"
      >
        {{ providerListing.display_name }}
      </a>
      <span class="pronouns" *ngIf="providerListing.pronouns">({{ providerListing.pronouns }})</span>
    </p>

    <div class="credentials" data-test="credentials">
      <p *ngFor="let credential of providerListing.certifications">
        {{ credential.name }}
      </p>
    </div>

    <div
      class="insurance"
      data-test="insurance"
    >
      <iris-icon
        name="verified_user"
        [size]="irisIconSizes.MD"
      ></iris-icon>
      <span>Accepts {{ insurance }}</span>
    </div>

    <p
      class="specialties"
      data-test="specialties"
      *ngIf="specialtyNamesArray.length > 0"
    >
      <span class="label">Specialties include</span> {{ specialtyNamesArray | stringArrayToCommaList: true:'and':3 }}
    </p>

    <p class="bio" data-test="bio">{{ shortenedBio }}...</p>
  </div>

  <div class="mobile-action-panel">
    <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
  </div>

  <div class="desktop-action-panel" data-test="action-panel">
    <div class="session-types">
      <div
        class="labeled-image"
        *ngIf="offersSessionsVideo"
      >
        <iris-icon
          name="videocam"
          [size]="irisIconSizes.MD"
        ></iris-icon>
        <span>Video sessions</span>
      </div>
      <div
        class="labeled-image"
        *ngIf="offersSessionsInPerson"
      >
        <iris-icon
          name="location_on"
          [size]="irisIconSizes.MD"
        ></iris-icon>
        <span>In-person sessions</span>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
  </div>
</div>

<ng-template #actionButtons>
  <div class="button-container" data-test="action-buttons">
    <a
      [href]="matchLinkUrl"
      target="_blank"
      class="iris-button iris-button--primary request-session"
      (click)="requestSession()"
      attr.aria-label="Request a session with {{ providerListing.display_name }}"
    >
      Request a session
    </a>
    <a
      [href]="profileLinkUrl"
      target="_blank"
      class="iris-button iris-button--secondary view-profile"
      (click)="onClickViewProfile()"
      attr.aria-label="View {{ providerListing.display_name }}'s profile"
    >
      View profile
    </a>
  </div>
</ng-template>
