import { SegmentSchema } from '@sondermind/segment';

export enum PslSegmentEvents {
  PSL_DIRECTORY_LINK_CLICKED = 'Provider directory link on Similar Provider Carousel clicked',
  PSL_REQUEST_A_SESSION_CLICKED = 'Request A Session Clicked',
  PSL_SIMILAR_PROVIDER_VIEWED = 'Viewed a Similar Provider on non-accepting PSL',
  PSL_SEND_REQUEST_A_SESSION_EMAIL = 'send-psl-session-request-email',
  PSL_PAGE_VIEW = 'Viewed a provider shareable link page'
}

export interface PslDirectoryLinkClickData {
  currentProviderId: number;
}

export interface PslRequestSessionClickData {
  accepting: boolean;
  flow: string;
  personaProviderId: number;
  showSimilarProviders: boolean;
}

export interface PslSimilarProviderViewData {
  allCarouselProviderIds: number[];
  currentProviderId: number;
  destinationProviderId: number;
  index: number;
}

export interface IPslSegmentSchema extends SegmentSchema {
  [PslSegmentEvents.PSL_DIRECTORY_LINK_CLICKED]: PslDirectoryLinkClickData;
  [PslSegmentEvents.PSL_REQUEST_A_SESSION_CLICKED]: PslRequestSessionClickData;
  [PslSegmentEvents.PSL_SIMILAR_PROVIDER_VIEWED]: PslSimilarProviderViewData;
  [PslSegmentEvents.PSL_SEND_REQUEST_A_SESSION_EMAIL]: {}
}
