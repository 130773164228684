import {
  Component, Input, OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, UrlSegment } from '@angular/router';

import { Logger } from '@sondermind/datadog-logging';
import { generateExperienceSupportingList } from '@sondermind/helpers/provider';

import { IProviderShareableProfile } from '../../shareable-link.interfaces';

/**
 * This component displays tabbed, linked sections of a provider's profile data
 * To add a new section, make sure to do all of the following:
 * 1. Add the title of the section to moduleNames below
 * 2. Add the HTML of the section in the .html file
 * 3. Add an id to the <section> tag that matches the first word of the title in moduleNames + -section
 * 4. Create a link <a> tag to the section around the header, with the href being
 *   the currentRoute + a fragment of the same first word
 * 5. Add an id to that tag that matches the first word of the title in moduleNames + -link
 * This ensures the section is reachable from the header and is accessible by maintaining tab focus
 */
@Component({
  selector: 'provider-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './provider-details.component.html',
  styleUrls: ['./provider-details.component.scss']
})
export class ProviderDetailsComponent implements OnInit {
  @Input() providerShareableProfile: IProviderShareableProfile = {} as IProviderShareableProfile;
  /**
   * False by default to show everything in a single column,
   * this allows us to programmatically (such as based on FFs) alter the layout of the page
   * */
  @Input() multiColumnDisplay: boolean = false;
  isValid: boolean = false;
  experienceSupportingList: string[] = [];
  isBioTruncated: boolean = false;
  providerBio: string = '';
  allSortedInsurances: string[] = [];
  allSortedSpecialties: string[] = [];

  moduleNames: string[] = ['About', 'Specialties & Experience', 'Insurances', 'Locations'];
  lastSelectedSection: string = this.moduleNames[0];
  fragment: string = '';
  currentRoute: string = '';

  providerBioTruncationLength: number = 500;

  constructor(
    private route: ActivatedRoute,
    private logger: Logger,
  ) {}

  ngOnInit(): void {
    if (this.providerShareableProfile.bio?.length > 500) {
      this.truncateBio();
    } else {
      this.providerBio = this.providerShareableProfile.bio;
      this.isBioTruncated = false;
    }

    this.experienceSupportingList = generateExperienceSupportingList(
      this.providerShareableProfile.ages,
      this.providerShareableProfile.services_offered,
      this.providerShareableProfile.specialties.map(({ name }) => name)
    );

    this.route.snapshot.url.forEach((urlsegment: UrlSegment) => {
      this.currentRoute += `/${urlsegment.path.toString()}`;
    });
    this.route.fragment.subscribe((fragment) => { this.fragment = fragment });

    this.sortProviderData();
  }

  ngAfterViewInit(): void {
    try {
      if (this.fragment) {
        document.getElementById(`${this.fragment}-section`).scrollIntoView();
      }
    } catch (e) { this.logger.error(e) }
  }

  private sortProviderData(): void {
    this.allSortedInsurances = this.providerShareableProfile.insurances.sort(
      (ins1, ins2) => ins1.localeCompare(ins2)
    );

    this.allSortedSpecialties = this.providerShareableProfile.specialties
      .map((specialty) => specialty.name)
      .sort((s1, s2) => s1.localeCompare(s2));
  }

  private truncateBio(): void {
    // Truncate the bio text to 500 characters and add ellipsis
    this.providerBio = `${this.providerShareableProfile.bio.slice(0, 500)}...`;
    this.isBioTruncated = true;
  }

  toggleBioTruncation(): void {
    // Toggle the bio truncation state when "Read more" is clicked
    if (this.isBioTruncated) {
      this.providerBio = this.providerShareableProfile.bio;
      this.isBioTruncated = false;
    } else {
      this.truncateBio();
      this.scrollToSection('about');
    }
  }

  /**
   * Since we are taking in the header strings, they may have a space.
   * This function specifically trims the name down to the first word before a space, as the
   * element id will not have a space in it
   */
  scrollToSection(sectionName: string): void {
    // Section names should not have extra white space, but trim just in case
    const trimmedSectionName = sectionName.trim();
    const firstWordOfElementId = trimmedSectionName.includes(' ')
      ? trimmedSectionName.substring(0, trimmedSectionName.indexOf(' '))
      : trimmedSectionName;
    this.lastSelectedSection = this.moduleNames.find((name) => name.includes(trimmedSectionName));

    const element = document.getElementById(`${firstWordOfElementId.toLocaleLowerCase()}-section`);
    const linkToFocus = document.getElementById(`${firstWordOfElementId.toLocaleLowerCase()}-link`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    if (linkToFocus) {
      linkToFocus.focus();
    }
  }
}
