import { AbstractControl } from '@angular/forms';
import { APIError } from '@sondermind/utilities/models-http';

// eslint-disable-next-line no-var
declare var Stripe: any;

export class CardCVCValidator {
  static format(c: AbstractControl): APIError | null {
    if (typeof c.value === 'undefined' || c.value === null || c.value.toString().trim().length === 0) {
      return null;
    }

    if (typeof Stripe !== 'undefined' && !Stripe.card.validateCVC(c.value.toString())) {
      return { validateCVC: 'CVC number must be valid' };
    }
    return null;
  }
}
