import { InjectionToken } from '@angular/core';

/**
 * An InjectionToken that allows for the window object in a service or component
 * to be mocked in unit tests.
 *
 * Example usage: add this line to your constructor, and then refer to `this.window`:
 * `@Inject(WINDOW) private window: Window`
 *
 * Don't forget to define the useValue of the provider in your app.module.ts:
 * `{ provide: WINDOW, useValue: window }`
 */
export const WINDOW = new InjectionToken<Window>('WindowToken');
