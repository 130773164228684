import { Pipe, PipeTransform } from '@angular/core';

// This copies over functionality from the TherapistInitials pipe,
// which only takes a ITherapist, not a string
@Pipe({
  name: 'initials',
  standalone: true,
})
export class InitialsPipe implements PipeTransform {
  transform(name: string): string {
    if (!name) {
      return '';
    }

    // split by any amount of whitespace between parts of the name
    const nameParts = name.trim().split(/[ ]/);
    let initials = '';

    // eslint-disable-next-line no-restricted-syntax
    for (const part of nameParts) {
      if (part?.length > 0) {
        initials += `${part[0]}`;
      }
    }

    return initials;
  }
}
