import {
  Component, Inject, Input, OnInit
} from '@angular/core';
import { Params } from '@angular/router';
import { CommonModule } from '@angular/common';
import { from } from 'rxjs';

import { ConfigurationService, IProviderShareableLinkAppConfig } from '@sondermind/configuration';
import { IMiniProviderPreview } from '@sondermind/features/provider-listing-card';
import {
  IrisButtonModule,
  IrisButtonTarget,
  IrisIconButtonModule,
  IrisIconModule
} from '@sondermindorg/iris-design-system-angular';
import { SegmentService } from '@sondermind/segment';
import { WINDOW } from '@sondermind/utilities/tools';

import { SimilarProvidersCarousel } from '../similar-providers-carousel/similar-providers-carousel.component';
import { LandingPageContact } from '../../models/enums/landing-page-contact.enum';
import { IProviderShareableProfile } from '../../shareable-link.interfaces';
import { SimilarProvidersStore } from '../../stores/similar-providers.store';
import { IPslSegmentSchema, PslSegmentEvents } from '../../models/segment/psl-segment-schema.interface';

@Component({
  selector: 'similar-providers-banner',
  standalone: true,
  imports: [
    CommonModule,
    IrisButtonModule,
    IrisIconModule,
    IrisIconButtonModule,
    SimilarProvidersCarousel
  ],
  templateUrl: './similar-providers-banner.component.html',
  styleUrls: ['./similar-providers-banner.component.scss']
})
export class SimilarProvidersBannerComponent implements OnInit {
  @Input() providerProfile: IProviderShareableProfile;

  bookSessionPhoneLink: string = LandingPageContact.MATCH_BY_PHONE_LINK;
  bookSessionPhoneDisplay: string = LandingPageContact.MATCH_BY_PHONE_DISPLAY;
  isErrorLoadingProviders = false;
  newTabTarget = IrisButtonTarget.BLANK;
  providerDirectoryLink = '';
  queryParams: Params | null = null;

  loadingSimilarProviders: boolean = false;
  similarProviders: IMiniProviderPreview[] = [];

  constructor(
    private configurationService: ConfigurationService<IProviderShareableLinkAppConfig>,
    private similarProvidersStore: SimilarProvidersStore,
    private segmentService: SegmentService<IPslSegmentSchema>,
    @Inject(WINDOW) private window: Window
  ) {}

  ngOnInit(): void {
    this.loadSimilarProviders();
    this.providerDirectoryLink = this.buildDirectoryLink();
  }

  private buildDirectoryLink(): string {
    const referringUrl = this.queryParams?.['referringUrl'] as string
      ?? encodeURIComponent(this.window.location.host);

    let params = `?referringUrl=${referringUrl}`;

    const providerState = this.providerProfile?.practice_state?.abbrev;
    if (providerState) {
      params += `&state=${providerState}`;
    }

    return `${this.configurationService.env.providerDirectoryBase}${params}`;
  }

  private loadSimilarProviders(): void {
    this.loadingSimilarProviders = true;

    const params = {
      state: this.providerProfile?.practice_state?.abbrev,
      personaProviderId: this.providerProfile.id
    };

    this.similarProvidersStore.loadSimilarProviders$(params)
      .subscribe({
        next: (providers) => {
          this.similarProviders = providers;
          this.loadingSimilarProviders = false;
        },
        error: () => {
          this.isErrorLoadingProviders = true;
        }
      });
  }

  onClickLinkToProviderDirectory(): void {
    from(this.sendPslToDirectorySegmentEvent()).subscribe();
  }

  private sendPslToDirectorySegmentEvent(): Promise<void> {
    const data = { currentProviderId: this.providerProfile.id };

    return this.segmentService.track<PslSegmentEvents.PSL_DIRECTORY_LINK_CLICKED>(
      PslSegmentEvents.PSL_DIRECTORY_LINK_CLICKED,
      data
    );
  }
}
