import {
  Component, Input, OnDestroy, OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService, IDirectoryAppConfig } from '@sondermind/configuration';
import { InfoLinks } from '@sondermind/core/enums/info-links.enum';
import { Subject, takeUntil } from 'rxjs';

/**
 * This footer is used in public-facing applications like the
 * provider-landing-page and provider-directory.
 */
@Component({
  selector: 'public-footer',
  templateUrl: './public-footer.component.html',
  styleUrls: ['./public-footer.component.scss']
})
export class PublicFooterComponent implements OnInit, OnDestroy {
  emergencyResources = InfoLinks.EMERGENCY_RESOURCES;
  destroyed$ = new Subject<void>();

  @Input() matchflowBaseUrl: string = '';
  @Input() currentStateAbbrev: string = '';
  therapyMatchFlowUrl: string = '';
  psychiatryMatchFlowUrl: string = '';
  providerDirectoryUrl: string = '';

  private anonUserId: string = '';

  constructor(
    private route: ActivatedRoute,
    private configurationService: ConfigurationService<IDirectoryAppConfig>,
  ) {}

  ngOnInit(): void {
    const referringUrl = encodeURIComponent(window.location.href);
    this.therapyMatchFlowUrl = `${this.matchflowBaseUrl}?referringUrl=${referringUrl}`;
    this.psychiatryMatchFlowUrl = `${this.matchflowBaseUrl}/?slug=psychiatry-flow&referringUrl=${referringUrl}`;
    this.providerDirectoryUrl = this.configurationService.env.providerDirectoryBase
                                || 'https://www.sondermind.com/provider-directory';

    this.route.queryParams.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((queryParams) => {
      if (queryParams['anonUserId']) {
        this.anonUserId = queryParams['anonUserId'] as string;
        this.therapyMatchFlowUrl = `${this.therapyMatchFlowUrl}&anonUserId=${this.anonUserId}`;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
