export interface IAPIPageOption {
  enabled?: boolean;
  // page number of interest, note paging start index is 1 not 0
  number: number;
  size: number;
}

export interface IAPISortOption {
  key: string;
  asc: boolean;
}

export interface IAPIFetchRequestOptions {
  view?: string;
  error: Partial<IAPIErrorHandlingOptions>;
}

export interface IAPIFetchResponse<T> {
  data: T;
  meta: {
    view: string;
    links: {
      self: string;
    };
  };
}

export interface IAPIFilterOption {
  key: string;
  value: string;
}

export interface IAPIErrorHandlingOptions {
  null404: boolean;
  null401: boolean;
  rawErrors: boolean;
}

export interface IAPIListRequestOptions {
  view?: string;
  startRange?: string;
  endRange?: string;
  filter?: IAPIFilterOption | IAPIFilterOption[];
  filterArray?: IAPIFilterOption | IAPIFilterOption[];
  sort?: IAPISortOption | IAPISortOption[];
  page?: Partial<IAPIPageOption> | 'disabled';
  error?: Partial<IAPIErrorHandlingOptions>;
}

export interface IAPIServiceRequestOptions {
  view?: string;
  error: Partial<IAPIErrorHandlingOptions>;
}

export interface IAPISimpleRequestOptions {
  error: Partial<IAPIErrorHandlingOptions>;
}

export interface IAPIListResponse<T> {
  data: T[];
  meta: {
    view: string;
    links: {
      self: string;
      first?: string;
      last?: string;
      next?: string;
      prev?: string;
    };
    counts: {
      total: number;
      filtered?: number;
      pages?: number;
    };
    filter?: IAPIFilterOption[];
    sort?: IAPISortOption[];
    page?: IAPIPageOption;
  };
}

export const DEFAULT_ERROR_HANDLING_OPTIONS: IAPIErrorHandlingOptions = {
  null401: false,
  null404: true,
  rawErrors: false
};

export const DEFAULT_FETCH_HANDLING_OPTIONS: IAPIFetchRequestOptions = {
  error: DEFAULT_ERROR_HANDLING_OPTIONS
};

export const DEFAULT_SERVICE_HANDLING_OPTIONS: IAPIServiceRequestOptions = {
  error: DEFAULT_ERROR_HANDLING_OPTIONS
};

export interface IAPISimpleResponse<T> {
  data: T;
  meta: { simple: true; };
}

export interface IAPIStatusResponse {
  id: number;
  status: string;
  error: string;
}

export const DEFAULT_SIMPLE_HANDLING_OPTIONS: IAPIServiceRequestOptions = {
  error: DEFAULT_ERROR_HANDLING_OPTIONS,
};
