import { MonoTypeOperatorFunction } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

interface IndexedObject<T> { [key: string]: T; }

export function indexArray<T, U>(target: T[], key: keyof T, transform: (x: T) => U): IndexedObject<U>;
export function indexArray<T>(target: T[], key: keyof T): IndexedObject<T>;

/**
 * Index the given array by the given key
 *
 * @param target - the array to index
 * @param key - the name of a string property on the target items, to index by
 * @returns an object like `{ [target[n][key]]: target[n] }`
 */
export function indexArray<T, U>(target: T[], key: keyof T, transform?: (x: T) => U): IndexedObject<T | U> {
  return target.reduce((memo, item) => {
    // the cast is due to this typescript bug (marked fixed but not)
    // https://github.com/Microsoft/TypeScript/issues/14473
    const index = item[key] as unknown as string;
    const value = transform ? transform(item) : item;
    return { ...memo, [index]: value };
  }, {});
}

/**
 * `distinctUntilChanged` operator that works over array items, rather
 * than just the array instance itself.
 *
 * @remarks
 * This Operator was copied out of \@datorama/akita
 * Copyright 2013 Datorama
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0
 */
export function distinctUntilArrayItemChanged<T>(): MonoTypeOperatorFunction<T[]> {
  return distinctUntilChanged((prevCollection: T[], currentCollection: T[]) => {
    if (prevCollection === currentCollection) {
      return true;
    }

    if (Array.isArray(prevCollection) === false || Array.isArray(currentCollection) === false) {
      return false;
    }

    if ((prevCollection.length === 0) && (currentCollection.length === 0)) {
      return true;
    }

    // if item is new in the current collection but not exist in the prev collection
    const hasNewItem = hasChange(currentCollection, prevCollection);
    if (hasNewItem) {
      return false;
    }

    const hasChangedOrMissingItem = hasChange(prevCollection, currentCollection);
    if (hasChangedOrMissingItem) {
      return false;
    }

    // at this point, nothing's changed
    return true;
  });
}

/**
 * Determines whether there're items in the second array missing from
 * the first, by reference equality.
 *
 * @remarks
 * copied out of \@datorama/akita
 * Copyright 2013 Datorama
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * @param first - the first array to check
 * @param second - the second array to check
 * @returns true if `first` is missing an item in `second`, by reference equality.
 */
function hasChange<T>(first: T[], second: T[]) {
  return second.some((currentItem) => {
    const oldItem = first.find((prevItem) => prevItem === currentItem);
    return oldItem === undefined;
  });
}
