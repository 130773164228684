export enum InfoLinks {
  // Provider facing links
  DOC_DISCLOSURES = 'https://rebrand.ly/SM-Consents',
  THERAPEUTIC_ALLIANCE = 'http://www.sondermind.com/private-practice/therapeutic-alliance',
  PHQ9_OVERVIEW = 'http://www.sondermind.com/private-practice/phq9-overview',
  GAD7_OVERVIEW = 'http://www.sondermind.com/private-practice/gad7-overview',
  PCL5_OVERVIEW = 'https://www.ptsd.va.gov/professional/assessment/documents/using-PCL5.pdf',
  PROVIDER_PORTAL_TERMS_OF_USE = 'https://docs.google.com/document/d/e/2PACX-1vSzsiZWQ2xvKrJqYGkJXie1jBgGSEX4Sb0MJ_tzQOyBKVwoKu0kRV-dHXHFSMhiOWbiPNUEAPFc2raO/pub?embedded=true',
  DISCLOSURE_GUIDELINES_BY_STATE = 'https://www.sondermind.com/private-practice/what-disclosures-do-i-need-to-provide',
  FUNCTIONAL_OVERVIEW = 'https://rebrand.ly/SM-functional-overview',
  PROVIDER_TELEHEALTH_GUIDE = 'https://community.sondermind.com/kb/articles/39-video-telehealth-faq',

  // Client facing links
  INSURANCE_AND_PAYMENT_FAQS = 'https://www.sondermind.com/faqs/payment-insurance',
  PRIVACY_POLICY = 'https://www.sondermind.com/privacy-policy',
  TERMS_OF_SERVICE = 'https://www.sondermind.com/terms-of-service',
  PRIVACY_PRACTICE = 'https://sondermind.com/notice-of-privacy-practices',
  FINANCIAL_RESPONSIBILITY_AGREEMENT = 'https://www.sondermind.com/financial-responsibility-agreement',
  CLIENT_GAD7_DESCRIPTION = 'https://www.sondermind.com/resources/what-is-the-gad-7',
  CLIENT_PHQ9_DESCRIPTION = 'https://www.sondermind.com/resources/what-is-the-phq-9',
  CLIENT_SELF_CARE = 'https://www.sondermind.com/resources/when-do-we-need-self-care',
  CLIENT_CLINICAL_QUESTIONNAIRES_INFO = 'https://www.sondermind.com/resources/clinical-questionnaires-helping-you-get-better-faster',
  CLIENT_AM_I_DEPRESSED = 'https://www.sondermind.com/resources/am-i-depressed',
  CLIENT_FEELING_LIGHTER = 'https://www.sondermind.com/resources/articles-and-content/things-you-should-know-about-seasonal-affective-disorder',
  CLIENT_COPING_WITH_POST_PARTUM_DURING_HOLIDAYS = 'https://www.sondermind.com/resources/coping-with-postpartum-depression-during-the-holidays',
  CLIENT_ANXIETY_VS_PANIC_ATTACK = 'https://www.sondermind.com/resources/anxiety-attack-vs-panic-attack-whats-the-difference',
  CLIENT_ANXIETY_MISCONCEPTIONS = 'https://www.sondermind.com/resources/mental-health-misconceptions-anxiety',
  CLIENT_MEETING_FRIENDS_ANXIETY = 'https://www.sondermind.com/resources/meeting-new-friends-when-you-have-social-anxiety-2',
  CLIENT_TELL_IF_THERAPY_IS_WORKING = 'https://www.sondermind.com/resources/how-to-tell-if-therapy-is-working-for-you',
  CLIENT_THERAPY_BACKSLIDE = 'https://www.sondermind.com/resources/articles-and-content/what-happens-when-you-feel-youre-not-making-progress-in-therapy',
  CLIENT_SELF_CARE_TIPS = 'https://www.sondermind.com/resources/tips-on-starting-your-own-self-care-routine',
  CLIENT_FIRST_SESSION_FAQ = 'https://www.sondermind.com/resources/your-first-therapy-session-faq',
  CLIENT_FINDING_RIGHT_THERAPIST = 'https://www.sondermind.com/resources/finding-the-right-therapist',
  CLIENT_TESTIMONIAL = 'https://gettherapy.sondermind.com/rs/599-HAY-049/images/SM_Client_Testimonial_AdamE__62s_FINAL.mp4',
  CLIENT_WHO_CAN_BENEFIT = 'https://www.sondermind.com/resources/articles-and-content/do-i-need-therapy',
  CLIENT_BENEFICIAL_TO_WELL_BEING = 'https://www.sondermind.com/resources/articles-and-content/is-therapy-worth-it',
  CLIENT_FRESH_TAKE_SELF_CARE = 'https://www.sondermind.com/resources/a-fresh-take-on-self-care',
  CLIENT_FIVE_TIPS = 'https://www.sondermind.com/resources/articles-and-content/how-to-get-the-most-out-of-therapy-5-tips-for-success/',
  CLIENT_IN_PERSON_VS_ONLINE = 'https://www.sondermind.com/resources/articles-and-content/in-person-or-online-therapy/',

  // Both
  ABOUT_SONDERMIND = 'https://www.sondermind.com/about',
  SAM_FAQS = 'https://community.sondermind.com/kb/articles/22-sondermind-anytime-messaging-frequently-asked-questions',
  EMERGENCY_RESOURCES = 'https://www.sondermind.com/emergency-resources',
  FAQ = 'https://www.sondermind.com/faqs/',
  RESOURCES = 'https://www.sondermind.com/resources',
  REFER_TO_THERAPY = 'https://sondermind.tfaforms.net/f/referraltotherapy',
  REFER_TO_PSYCHIATRY = 'https://sondermind.tfaforms.net/f/referraltopsychiatry'
}
