import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { filter, first } from 'rxjs';
import * as Sentry from '@sentry/angular-ivy';

import { ConfigurationService, IEnvironmentConfig } from '@sondermind/configuration';
import { SentryClient } from '@sondermind/sentry-client';
import { DatadogLoggingModule } from '@sondermind/datadog-logging';
import { currentCommitHash, CURRENT_COMMIT_HASH } from './current-commit-sha';

function initLoggingServices(configService: ConfigurationService, commitHash: string): () => Promise<void> {
  return () => new Promise((resolve) => {
    configService.configSubj
      .pipe(
        filter((e) => !!e && Object.keys(e).length !== 0),
        first()
      ).subscribe((config: IEnvironmentConfig) => {
        SentryClient.initialize(config, commitHash);
        DatadogLoggingModule.bootstrap(config);
        resolve();
      });
  });
}

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initLoggingServices,
      multi: true,
      deps: [
        ConfigurationService,
        CURRENT_COMMIT_HASH
      ]
    },
    {
      provide: CURRENT_COMMIT_HASH,
      useValue: currentCommitHash
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    }
  ]
})
export class LoggingInitModule { }
