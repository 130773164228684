import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/* eslint-disable import/first */
// eslint-disable-next-line no-var, no-underscore-dangle
declare var __webpack_public_path__: string;

if (environment.production) {
  __webpack_public_path__ = 'https://providers.sondermind.com/';
} else if (environment.staging) {
  __webpack_public_path__ = 'DYNAMIC_STAGING_PUBLIC_PATH';
} else if (environment.preprod) {
  __webpack_public_path__ = 'https://bowie-providers.sondermind.biz/';
} else {
  __webpack_public_path__ = 'http://localhost:4205/';
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
