export enum MatchHighlightKey {
  CERTIFICATION_TYPE = 'certification_type',
  ETHNICITY = 'ethnicity',
  GENDER = 'gender',
  LANGUAGE = 'language',
  MODALITY = 'modality',
  PARTNER_CERTIFICATION = 'partner_certification',
  RELIGION = 'religion',
  SEXUALITY = 'sexuality',
  LGBTQIA = 'lgbtqia',
  SPECIALTY = 'specialty',
  TREATMENT_PREFERENCE = 'treatment_preference',
  DISTANCE = 'geo_distance_bin_miles',
  AGE_REQUEST = 'provider_age_request',
  AVAILABILITY = 'availability',
  NETWORK = 'network',
  VIDEO = 'video',
}
