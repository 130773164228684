import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LAUNCH_DARKLY_SERVICE, LDBooleanFeatureFlags, LaunchDarklyService } from '@sondermind/launch-darkly';
import {
  Observable,
  Subject, combineLatest,
  takeUntil
} from 'rxjs';

import { ConfigurationService, IProviderShareableLinkAppConfig } from '@sondermind/configuration';
import { BreakpointsService } from '@sondermind/utilities/services';
import { IconColors, IconSizes, NavigationKeys } from '@sondermindorg/iris-design-system-angular';
import { WINDOW } from '@sondermind/utilities/tools';

@Component({
  selector: 'public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss'],
})

export class PublicHeaderComponent {
  @Input() set providerPracticeState(state: string) {
    this.providerPracticeStateValue = state;
    this.buildFindProviderUrl();
  }
  providerPracticeStateValue = '';
  showMobileMenu: boolean = false;
  showExploreCareMenu: boolean = false;
  isMobile: boolean = false;
  findProviderUrl: string = '';
  ctaButtonText: string = '';
  destroyed$ = new Subject<void>();

  iconSizes = IconSizes;
  iconColors = IconColors;
  redirectToProviderDirectoryFeatureFlag: boolean = false;
  anonUserId: string = '';
  queryParams: Params | null = null;

  constructor(
    public breakpointService: BreakpointsService,
    private route: ActivatedRoute,
    @Inject(LAUNCH_DARKLY_SERVICE)
    private ldService: LaunchDarklyService,
    private configurationService: ConfigurationService<IProviderShareableLinkAppConfig>,
    @Inject(WINDOW) private window: Window
  ) { }

  ngOnInit(): void {
    combineLatest([
      this.checkLdFlags(),
      this.route.queryParams
    ])
      .pipe(takeUntil(this.destroyed$)).subscribe(([enabled, queryParams]) => {
        this.redirectToProviderDirectoryFeatureFlag = enabled;
        if (queryParams['anonUserId']) {
          this.anonUserId = queryParams['anonUserId'] as string;
        }
        this.queryParams = queryParams;
        this.buildFindProviderUrl();
      });
    this.breakpointService.isMobileOrTablet$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((isMobileOrTablet) => {
      this.isMobile = isMobileOrTablet;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  buildFindProviderUrl(): void {
    const isLinkToProviderDirectory = this.providerPracticeStateValue?.length > 0;

    if (this.redirectToProviderDirectoryFeatureFlag && isLinkToProviderDirectory) {
      this.findProviderUrl = this.buildProviderDirectoryUrl();
    } else {
      this.findProviderUrl = this.buildMatchFlowUrl();
    }

    if (this.anonUserId) {
      this.findProviderUrl = `${this.findProviderUrl}&anonUserId=${this.anonUserId}`;
    }

    this.ctaButtonText = isLinkToProviderDirectory ? 'Find a provider' : 'Get matched with a provider';
  }

  private buildProviderDirectoryUrl(): string {
    const referringUrl = this.queryParams?.['referringUrl'] as string
      ?? encodeURIComponent(this.window.location.host);

    const providerDirectoryRoot = this.configurationService.env.providerDirectoryBase
      ?? 'https://sondermind.com/provider-directory';

    const params = `referringUrl=${referringUrl}&state=${this.providerPracticeStateValue}`;

    return `${providerDirectoryRoot}?${params}`;
  }

  private buildMatchFlowUrl(): string {
    const matchFlowRoot = this.configurationService.env.app?.flowHost
    ?? 'https://start.sondermind.com/';

    const params = `referringUrl=${encodeURIComponent(this.window.location.href)}`;

    return `${matchFlowRoot}?${params}`;
  }

  checkLdFlags(): Observable<boolean> {
    return this.ldService.checkFlagEnabled$(LDBooleanFeatureFlags.FIND_PROVIDER_PUBLIC_HEADER_REDIRECT);
  }

  toggleMobileMenu(): void {
    this.showMobileMenu = !this.showMobileMenu;
  }

  toggleMobileMenuKeyDown(e: KeyboardEvent): void {
    if (e.key === NavigationKeys[' '] || e.key === NavigationKeys.Enter) {
      this.toggleMobileMenu();
    }
  }

  toggleExploreCare(): void {
    this.showExploreCareMenu = !this.showExploreCareMenu;
  }

  setExploreCare(showMenu: boolean): void {
    this.showExploreCareMenu = showMenu;
  }
}
