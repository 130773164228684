import { AbstractControl } from '@angular/forms';

export class PhoneValidator {
  static format(c: AbstractControl): { [key: string]: any; } {
    const reg = /^\d+$/;
    if (typeof c.value === 'undefined' || c.value === null || c.value.toString().trim().length === 0) {
      return null;
    }
    // unmask '()', ' ' and '-' values inputted into phone number fields
    const unMasked = c.value.toString().trim().replace(/[() -]/g, '');

    if (parseInt(unMasked, 10) === 0 || unMasked.length !== 10 || !reg.test(unMasked)) {
      return { phoneNumberFormat: 'Phone number must be numeric and contain 10 digits' };
    }
    return null;
  }
}
