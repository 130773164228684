<ng-container *ngIf="isMobile; then mobileHeader; else desktopHeader"></ng-container>

<ng-template #mobileHeader>
  <header>
    <div class="mobile-logo-container">
      <a href="https://www.sondermind.com" target="_blank">
        <img
          src="/assets/sm-logos/logotype-lake.svg"
          class="logo"
          alt="Sondermind logo"
          data-test="sondermind-logo"
          fetchpriority="low"
        >
      </a>
      <span class="hamburger-menu">
        <iris-icon 
          name="menu" 
          tabindex="0" 
          (click)="toggleMobileMenu()" 
          (keydown)="toggleMobileMenuKeyDown($event)"
        ></iris-icon>
      </span>
    </div>
  
    <div class="mobile-dropdown" [class.show]="showMobileMenu">
      <div class="header-actions">
        <a [href]="findProviderUrl" target="_blank" class="nav-link find-a-therapist">{{ ctaButtonText }}</a>
      </div>
      <a href="https://www.sondermind.com/why-sondermind" target="_blank">Why SonderMind</a>
      <button class="explore-care-mobile" (click)="toggleExploreCare()" >
        Explore Care
        <iris-icon 
          class="caret-mobile"
          name="expand_more" 
          [color]="iconColors.WHITE" 
          [size]="iconSizes.LG"
        >
        </iris-icon>
      </button>
      <div *ngIf="showExploreCareMenu">
        <a href="https://www.sondermind.com/therapy" target="_blank">Therapy</a>
        <a href="https://www.sondermind.com/psychiatry" target="_blank">Psychiatry</a>
        <a href="https://www.sondermind.com/insurance/" target="_blank">Insurance</a>
        <a href="https://www.sondermind.com/resources/articles-and-content/" target="_blank">Articles and Content</a>
      </div>
      <a href="https://www.sondermind.com/real-results/" class="nav-link find-a-therapist" target="_blank">Real Results</a>
    </div>
  </header>
</ng-template>

<ng-template #desktopHeader>
  <header>
    <div class="header-container">
      <a href="https://www.sondermind.com">
        <img
          src="/assets/sm-logos/logotype-lake.svg"
          class="logo"
          alt="Sondermind logo"
          data-test="sondermind-logo"
          fetchpriority="low"
        >
      </a>
      <div class="nav-actions-container">
        <nav class="main-nav">
          <a 
            href="https://www.sondermind.com/why-sondermind" 
            target="_blank"
            (focus)="setExploreCare(false)"
            class="nav-link" 
            data-test="why-sondermind"
          >
            Why SonderMind?
          </a>
          <div 
            class="dropdown"
            (mouseover)="setExploreCare(true)"
            (mouseleave)="setExploreCare(false)"
          >
            <button 
              class="nav-link dropdown-toggle" 
              data-test="explore-care" 
              id="dropdownMenuButton" 
              (click)="setExploreCare(true)"
              (focus)="setExploreCare(true)"
              [attr.aria-expanded]="showExploreCareMenu" 
              aria-haspopup="true"
            >
              Explore Care
              <span class="caret">
                <iris-icon name="expand_more" [size]="iconSizes.MD"></iris-icon>
              </span>
            </button>
            <div class="dropdown-content" *ngIf="showExploreCareMenu" aria-labelledby="dropdownMenuButton">
              <a href="https://www.sondermind.com/therapy/" target="_blank">Therapy</a>
              <a href="https://www.sondermind.com/psychiatry/" target="_blank">Psychiatry</a>
              <a href="https://www.sondermind.com/insurance/" target="_blank">Insurance</a>
              <a href="https://www.sondermind.com/resources/articles-and-content/" target="_blank">Articles and Content</a>
            </div>
          </div>
        </nav>
        <div class="header-actions">
          <a 
            [href]="findProviderUrl"
            target="_blank"
            (focus)="setExploreCare(false)"
            class="nav-link find-a-therapist" 
            data-test="find-therapist"
          >
            {{ ctaButtonText }}
          </a>
        </div>
      </div>
    </div>
  </header>
</ng-template>