import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { FlagObservable } from '@sondermind/models/rxjs';

/**
 * The source of truth for the contract that specifies auth service behavior.
 *
 * Auth services should implement this interface, and generally applications that use auth services
 * should depend on this interface (as opposed to depending directly on auth service implementations).
 */
export interface IAuthService {
  /**
   * A flag observable that emits `true` once initialization has completed.
   */
  initialized$: FlagObservable<boolean>;

  /**
   * An observable that emits anytime an auth flow has completed.
   * Emitted value is true if authentication was successful, false otherwise.
   */
  authFlowComplete$: Observable<boolean>;

  /**
   * An observable that emits `true` anytime a successful authentication flow completes.
   */
  authorized$: Observable<true>;

  /**
   * An observable that emits whenever auth tokens are destroyed (typically whenever `logout` occurs).
   */
  authDestroyed$: Observable<void>;

  /**
   * Initializes the auth service.
   * @returns An observable that emits the authentication status at the time of initialization.
   * That is, emits true if initialization resulted in a successful authentication; false otherwise.
   * *(Note: to respond to whether or not initialization itself was successful use `initialized$`.)*
   */
  initialize(): Observable<boolean>;

  login(returnTo: string): void;

  /** This exists for backwards compatibility with `AuthTokenService`. */
  loginWithAuthenticationToken(token: string): Observable<void>;

  logout(): Observable<void>;

  /**
   * Reset client side session state (i.e. tokens stored in local storage, etc.)
   *
   * This exists for backwards compatibility with `AuthTokenService`.
   */
  reset(): void;

  /**
   * Attempts to refresh the current access tokens in the background.
   * @returns An observable that emits once the silent refresh flow has completed.
   */
  silentRefresh(): Observable<void>;

  isAuthenticated(): boolean;

  getAuthToken(): string | null;

  /** This exists for backwards compatibility with `AuthTokenService`. */
  isLegacyImpersonating(): boolean;

  isIdleCheckEnabled(): boolean;
}

export const AUTH_SERVICE = new InjectionToken<IAuthService>('Auth Service');
