import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConfigurationModule } from '@sondermind/configuration';
import { CurrentUser } from '@sondermind/data-access/client-user';
import { DatadogLoggingModule } from '@sondermind/datadog-logging';
import { PublicFooterModule } from '@sondermind/features/app-footer';
import { PublicHeaderModule } from '@sondermind/features/app-header';
import { MiniProviderListingCardComponent } from '@sondermind/features/provider-listing-card';
import { QuickCheckoutComponent } from '@sondermind/features/public-facing/quick-checkout';
import { LAUNCH_DARKLY_SERVICE, LaunchDarklyService } from '@sondermind/launch-darkly';
import { LoggingInitModule } from '@sondermind/logging-init';
import { ENVIRONMENT } from '@sondermind/utilities/models-environment';
import { WINDOW } from '@sondermind/utilities/tools';
import {
  IrisButtonModule,
  IrisIconButtonModule,
  IrisIconModule,
  IrisProgressBarModule,
  IrisRadioGroupModule
} from '@sondermindorg/iris-design-system-angular';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DirectoryComponent } from './components/directory/directory.component';
import {
  SimilarProvidersBannerComponent
} from './components/similar-providers-banner/similar-providers-banner.component';
import { LandingPageHeaderComponent } from './components/landing-page-header/landing-page-header.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ProviderDetailsComponent } from './components/provider-details/provider-details.component';
import {
  SupportPhoneNumberCardComponent
} from './components/support-phone-number-card/support-phone-number-card.component';

@NgModule({
  declarations: [
    AppComponent,
    DirectoryComponent,
    LandingPageComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    DatadogLoggingModule.forRoot(environment),
    IrisButtonModule,
    IrisIconButtonModule,
    IrisIconModule,
    IrisRadioGroupModule,
    IrisProgressBarModule,
    LandingPageHeaderComponent,
    MiniProviderListingCardComponent,
    ProviderDetailsComponent,
    PublicFooterModule,
    PublicHeaderModule,
    SimilarProvidersBannerComponent,
    SupportPhoneNumberCardComponent,
    QuickCheckoutComponent,
    AppRoutingModule,
    ConfigurationModule,
    LoggingInitModule,
  ],
  providers: [
    { provide: ENVIRONMENT, useValue: environment },
    { provide: LAUNCH_DARKLY_SERVICE, useClass: LaunchDarklyService },
    // Adding a dummy CurrentUser provider
    // in flows intake module there is a logic which checks if current user is available
    { provide: CurrentUser, useValue: { } as CurrentUser },
    { provide: WINDOW, useValue: window }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
