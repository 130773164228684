import camelcaseKeys from 'camelcase-keys';

type camelCaseInput = { [key: string]: unknown; } | Array< { [key: string]: unknown; } >;

/**
 * Takes an object or array of objects and transforms keys to be camelCase.
 * @example <caption>simple objects</caption>
 * interface SimpleObject { someProp: string, nestedProp: {nestedSimpleProp: number} };
 *
 * camelCaseTransformer<SimpleObject>({some_prop: 'a', nested_prop: {nested_simple_prop: 5}});
 * // returns {someProp: 'a', nestedProp: {nestedSimpleProp: 5}}
 *
 * @example <caption>simple array</caption>
 * camelCaseTransformer<SimpleObject[]>([{some_prop: 'a', nested_prop: {nested_simple_prop: 5}}]);
 * // returns [{someProp: 'a', nestedProp: {nestedSimpleProp: 5}}];
 *
 * @example <caption>object with computed properties</caption>
 * interface ComputedObject { firstName: string; lastName: string; fullName: string;};
 *
 * function computedObjectResponseTransformer(apiResponse: any): ComputedObject {
 *  const transformedResponse = camelCaseTransformer(apiResponse);
 *  return {
 *    ...transformedResponse,
 *    fullName: `${tranformedResponse.firstName} ${transformedResponse.lastName}`
 *  };
 * };
 *
 * computedObjectResponseTransformer({first_name: 'zeds', last_name: 'dead'});
 * // returns {firstName: 'zeds', lastName: 'dead', fullName: 'zeds dead'}
 *
 * @example <caption>array with computed properties</caption>
 * function computedObjectResponseTransformer(apiResponse: any[]): ComputedObject[] {
 *  return apiResponse.map( (element) => computedObjectResponseTransformer(element));
 * };
 *
 * */
export function camelCaseTransformer<T>(input: unknown): T {
  return camelcaseKeys(input as camelCaseInput, { deep: true }) as unknown as T;
}
