import { camelCaseTransformer } from '@sondermind/utilities/tools';
import { IProviderNetworkCompany } from '@sondermind/utilities/models-insurance/client';
import { SessionPreference } from '@sondermind/utilities/models-session';

export interface IClientMatchingProfile {
  id: number;
  clientReferralId: number;
  personaClientId: number;

  notes: string;
  locationName: string;
  lat: number;
  lng: number;
  distancePreference: number;

  therapistGender: { id: number; title: string; tag: string; };
  sessionPreference: SessionPreference;
  intent: string;
  providerNetworkCompanies: IProviderNetworkCompany[];

  // added for Match Builder
  openPreferences?: string;
  therapistMaxAge?: number;
  therapistMinAge?: number;
  therapistEthnicityId?: number;
  therapistGenderId?: number;
  therapistReligionId?: number;
  modalities?: Array<{ referenceDataId: number; }>;
  treatmentPreferences?: Array<{ referenceDataId: number; }>;
  reasons: Array<{ name: string; desc: string; id: number; referenceDataId: number; }>;
  specialties: Array<{ name: string; desc: string; id: number; referenceDataId: number; }>;
}

export interface IClientMatchingProfileApiResponse {
  /* eslint-disable @typescript-eslint/naming-convention */
  id: number;
  client_referral_id: number;
  persona_client_id: number;

  notes: string;
  location_name: string;
  lat: number;
  lng: number;
  distance_preference: number;

  therapist_gender: { id: number; title: string; tag: string; };
  session_preference: SessionPreference;
  intent: string;
  provider_network_companies: IProviderNetworkCompany[];

  // added for Match Builder
  open_preferences: string;
  therapist_max_age: number;
  therapist_min_age: number;
  therapist_ethnicity_id: number;
  therapist_gender_id: number;
  therapist_religion_id: number;
  modalities: Array<{ reference_data_id: number; }>;
  treatment_preferences: Array<{ reference_data_id: number; }>;
  reasons: Array<{ name: string; desc: string; id: number; reference_data_id: number; }>;
  specialties: Array<{ name: string; desc: string; id: number; reference_data_id: number; }>;
  /* eslint-enable @typescript-eslint/naming-convention */
}

export function clientMatchingResponseTransformer(
  apiResponse: IClientMatchingProfileApiResponse
): IClientMatchingProfile {
  const transformedResponse = camelCaseTransformer<IClientMatchingProfile>(apiResponse);
  return {
    ...transformedResponse,
    reasons: transformedResponse.reasons || transformedResponse.specialties || [],
    providerNetworkCompanies: transformedResponse.providerNetworkCompanies || []
  };
}
