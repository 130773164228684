import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl, FormGroup, ReactiveFormsModule, Validators
} from '@angular/forms';
import {
  tap, Subject, switchMap,
  takeUntil,
  catchError,
  of,
} from 'rxjs';

import { USStateOptions } from '@sondermind/constants';
import { ProviderNetworkCompanyStore } from '@sondermind/data-access/provider-network-company';
import {
  DropdownOption,
  IrisButtonContainerAlignments,
  IrisButtonModule,
  IrisButtonTypes,
  IrisCheckboxModule,
  IrisDropdownModule,
  IrisFormFieldModule,
  IrisModalRef,
  IrisTextInputModule
} from '@sondermindorg/iris-design-system-angular';
import { ITimeSlot } from '@sondermind/utilities/models-scheduling';
import { SharedMasks } from '@sondermind/utilities/text-masks';
import { EmailValidator } from '@sondermind/utilities/tools/validators/email.validator';
import { PhoneValidator } from '@sondermind/utilities/tools/validators/phone.validator';
import { SimpleDateValidator } from '@sondermind/utilities/tools/validators/simple-date.validator';

import { CASH_PAY_OPTION_VALUE, IInsurance } from '@sondermind/utilities/models-provider-network-company';
import {
  IQuickCheckoutApiResponse,
  IQuickCheckoutForm,
  IQuickCheckoutReferralCreationRequest
} from '../models/quick-checkout-form.model';
import { QuickCheckoutHttpService } from '../services/quick-checkout.httpservice';
import { IQuickCheckoutModalConfigData } from '../models/quick-checkout-modal-config.model';

enum QuickCheckoutState {
  FORM = 'form',
  ERROR = 'error',
  SUBMITTED = 'submitted'
}

const PSL_REFERRAL_SOURCE = 'psl referral';

@Component({
  selector: 'quick-checkout',
  standalone: true,
  imports: [
    CommonModule,
    IrisCheckboxModule,
    IrisDropdownModule,
    IrisFormFieldModule,
    IrisTextInputModule,
    IrisButtonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './quick-checkout.component.html',
  styleUrls: ['./quick-checkout.component.scss']
})
export class QuickCheckoutComponent implements OnInit {
  providerCredentialedStates: string[] = [];
  providerAcceptedInsuranceIds: number[] = [];
  providerId: number = -1;
  requestedTimeSlot: ITimeSlot | null = null;

  quickCheckoutState: QuickCheckoutState = QuickCheckoutState.FORM;
  quickCheckoutFormGroup: FormGroup<IQuickCheckoutForm> = new FormGroup({
    commPrefs: new FormGroup({
      commPrefsPhone: new FormControl(true),
      commPrefsSms: new FormControl(true)
    }, Validators.required),
    contactSmsAllowed: new FormControl<boolean | null>(true),
    dateOfBirth: new FormControl<string | null>(null, [Validators.required, SimpleDateValidator.format]),
    firstName: new FormControl<string | null>(null, Validators.required),
    lastName: new FormControl<string | null>(null, Validators.required),
    email: new FormControl<string | null>(null, [Validators.required, EmailValidator.format]),
    insurance: new FormControl<number | null>({ value: null, disabled: true }, Validators.required),
    location: new FormControl<string | null>(null, Validators.required),
    phone: new FormControl<string | null>(null, [Validators.required, PhoneValidator.format]),
  });

  irisButtonAlignmentEnd = IrisButtonContainerAlignments.END;
  irisButtonTypeSecondary = IrisButtonTypes.SECONDARY;

  private destroyed$ = new Subject<void>();

  masks = {
    birthdayMask: SharedMasks.birthdayMask,
    phoneMask: SharedMasks.phoneMask
  };

  stateOptions: Array<DropdownOption<string>> = [];
  insuranceOptions: Array<DropdownOption<number>> = [];

  referralCreationResponseUuid: string = '';

  constructor(
    private irisModalRef: IrisModalRef<QuickCheckoutComponent, IQuickCheckoutModalConfigData, string>,
    private pncStore: ProviderNetworkCompanyStore,
    private quickCheckoutHttpService: QuickCheckoutHttpService,
  ) {}

  ngOnInit(): void {
    const { data } = this.irisModalRef.config;
    if (data) {
      this.providerAcceptedInsuranceIds = data.providerAcceptedInsuranceIds;
      this.providerCredentialedStates = data.providerCredentialedStates;
      this.providerId = data.providerId;
      this.requestedTimeSlot = data.requestedTimeSlot;
    }

    this.stateOptions = this.providerCredentialedStates.map((state) => ({ name: state, value: state }));
    this.setUpLocationSubscription();
  }

  /**
   * Insurance form control is dependent on location, and should be disabled when a location has not been selected
   * We should fetch new insurances every time a new location is selected
   */
  private setUpLocationSubscription(): void {
    this.quickCheckoutFormGroup.controls.location.valueChanges.pipe(
      switchMap((location) => {
        this.quickCheckoutFormGroup.controls.insurance.disable();

        if (location === 'DC') {
          return this.pncStore.getInsuranceOptionsByState$(location);
        }

        const abbrev = USStateOptions.find((state) => state.label === location)?.value;
        return abbrev ? this.pncStore.getInsuranceOptionsByState$(abbrev) : [];
      }),
      tap((insurances: IInsurance[]) => {
        this.insuranceOptions = insurances.filter(
          (i) => this.providerAcceptedInsuranceIds.includes(i.pncId)
        ).map((accepted) => ({ name: accepted.title, value: accepted.pncId }));

        this.insuranceOptions.push({ name: 'I\'ll pay out of pocket', value: CASH_PAY_OPTION_VALUE.pncId });
        this.quickCheckoutFormGroup.controls.insurance.enable();
      }),
      takeUntil(this.destroyed$)
    ).subscribe();
  }

  onBack(): void {
    this.quickCheckoutState = QuickCheckoutState.FORM;
  }

  onCancel(): void {
    this.irisModalRef.close();
  }

  onClickSubmit(): void {
    const body = this.createReferralRequestData();

    this.quickCheckoutHttpService.submitQuickCheckout$(body, {}).pipe(
      catchError((error) => {
        this.quickCheckoutState = QuickCheckoutState.ERROR;
        return of(error);
      })
    ).subscribe((resp: IQuickCheckoutApiResponse) => {
      this.quickCheckoutState = QuickCheckoutState.SUBMITTED;
      this.referralCreationResponseUuid = resp.referral_request_uuid;
    });
  }

  onClickResendEmail(): void {
    // TODO: ORCA-927 When email resend is ready, complete this request pipe
    // this.quickCheckoutHttpService.resendEmailVerification$(this.referralCreationResponseUuid).pipe().subscribe();
  }

  private createReferralRequestData(): IQuickCheckoutReferralCreationRequest {
    const formValues = this.quickCheckoutFormGroup.getRawValue();
    const commPrefs: string[] = ['ncc'];
    if (formValues.commPrefs.commPrefsPhone) {
      commPrefs.push('phone');
    }
    if (formValues.commPrefs.commPrefsSms) {
      commPrefs.push('sms');
    }

    /* eslint-disable @typescript-eslint/naming-convention */
    // Strong casting for all these values is safe since data can only be created after
    // the form is valid, so none of these data will be null
    return {
      first_name: formValues.firstName as string,
      last_name: formValues.lastName as string,
      email: formValues.email as string,
      phone: formValues.phone as string,
      received_date: new Date().toISOString(),
      source_attributes: {
        source: PSL_REFERRAL_SOURCE,
        date_of_birth: formValues.dateOfBirth as string,
        insurance: formValues.insurance as number,
        persona_provider_id: this.providerId,
        contact_sms_allowed: formValues.contactSmsAllowed as boolean,
        comm_prefs: commPrefs
      }
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }
}
