import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigurationService } from '@sondermind/configuration';
import { HttpService } from '@sondermind/http';
import { IAPIListRequestOptions, IAPIListResponse } from '@sondermind/utilities/models-http';

import {
  IInsurance,
  IInsuranceApiResponse,
  IProviderNetworkCompanyAPIResponse,
  parseInsurance,
  ProviderNetworkCompany
} from '@sondermind/utilities/models-provider-network-company';

@Injectable({
  providedIn: 'root'
})
export class ProviderNetworkCompanyHttpService {
  constructor(
    private configurationService: ConfigurationService,
    private http: HttpService
  ) {}

  list(opts: Partial<IAPIListRequestOptions> = {}): Observable<IAPIListResponse<ProviderNetworkCompany>> {
    const url = `${this.configurationService.env.svcBase}/billing/provider_network_companies`;
    const params = this.http.generateListParams(opts);

    return this.http
      .get<IAPIListResponse<IProviderNetworkCompanyAPIResponse>>(url, params)
      .pipe(
        map((resp) => ({ ...resp, data: resp.data.map((datum) => new ProviderNetworkCompany(datum)) })),
        this.http.handleHttpError(opts.error)
      );
  }

  listInsuranceOptions$(opts: Partial<IAPIListRequestOptions> = {}): Observable<IAPIListResponse<IInsurance>> {
    const params = this.http.generateListParams({ ...opts, view: 'provider_directory_page' });
    const url = `${this.configurationService.env.svcBase}/flows/provider_network_companies`;

    return this.http.get<IAPIListResponse<IInsuranceApiResponse>>(url, params)
      .pipe(
        map((response: IAPIListResponse<IInsuranceApiResponse>) => (
          {
            ...response,
            data: response.data.map(
              (insuranceApiResponse: IInsuranceApiResponse) => parseInsurance(insuranceApiResponse)
            )
          }
        )),
        this.http.handleHttpErrorStrict()
      );
  }
}
