import { CommonModule, DOCUMENT } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  Component, Inject, Input, OnInit
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subject, takeUntil, from } from 'rxjs';

import { ConfigurationModule, ConfigurationService, IDirectoryAppConfig } from '@sondermind/configuration';
import { IFilterFormValues } from '@sondermind/features/filter-form';
import { GtmDataLayerService } from '@sondermind/google-tag-manager';
import { SegmentService } from '@sondermind/segment';
import { InitialsPipe, StringArrayToCommaListPipe } from '@sondermind/utilities/pipes';
import { WINDOW } from '@sondermind/utilities/tools';
import {
  IconSizes, IrisButtonModule, IrisButtonTypeAttributeOptions, IrisIconModule
} from '@sondermindorg/iris-design-system-angular';

import { BILL_THOMPKINS } from '../../data/response';
import { IProviderPreview } from '../../models/provider-preview.interface';
import {
  IProviderListingCardSegmentSchema, ProviderDirectoryViewProfileEvent, ProviderListingCardEvents, ProviderListingCardRequestSessionEvent
} from '../../models/segment/provider-directory-segment.interface';
import { ProviderPhotoComponent } from '../provider-photo/provider-photo.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ConfigurationModule,
    HttpClientModule,
    IrisButtonModule,
    IrisIconModule,
    InitialsPipe,
    ProviderPhotoComponent,
    StringArrayToCommaListPipe
  ],
  selector: 'provider-listing-card',
  templateUrl: './provider-listing-card.component.html',
  styleUrls: ['./provider-listing-card.component.scss']
})
export class ProviderListingCardComponent implements OnInit {
  @Input() currentFormFilters: Partial<IFilterFormValues>;
  @Input() providerIndex: number; // For tracking which # result this provider is
  @Input() providerListing: IProviderPreview = BILL_THOMPKINS;
  @Input() queryParams: Params = null;
  @Input() selectedState: string = null;

  irisButtonTypeAttribute = IrisButtonTypeAttributeOptions.BUTTON;
  irisIconSizes = IconSizes;

  insurance: string = 'insurance or self-pay';

  matchLinkUrl: string = '';
  profileLinkUrl: string = '';

  offersSessionsInPerson: boolean = false;
  offersSessionsVideo: boolean = false;

  specialtyNamesArray: string[] = [];
  shortenedBio: string;
  destroyed$ = new Subject<void>();

  constructor(
    private configurationService: ConfigurationService<IDirectoryAppConfig>,
    private gtmService: GtmDataLayerService,
    @Inject(WINDOW) private window: Window,
    private segmentService: SegmentService<IProviderListingCardSegmentSchema>,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
  ){}

  ngOnInit(): void {
    this.insurance = this.getInsuranceSelected();
    this.matchLinkUrl = this.buildMatchLinkUrl();
    this.profileLinkUrl = this.buildProfileLinkUrl();
    this.offersSessionsInPerson = this.checkForInPersonSessions();
    this.offersSessionsVideo = this.checkForVideoSessions();
    this.specialtyNamesArray = this.buildSpecialtyNamesArray();
    this.shortenedBio = this.truncateProviderBio();
    this.handleCertificationsList();
  }

  getInsuranceSelected(): string {
    const selectedInsurance = this.currentFormFilters?.insurance;
    return selectedInsurance?.pncId >= 0 && selectedInsurance?.title
      ? selectedInsurance.title : 'insurance or self-pay';
  }

  buildMatchLinkUrl(): string {
    const link = `${this.configurationService.env.providerSharableLinkBase}/match/${this.providerListing.share_code}`;
    return `${link}${this.buildTrackingParamsString(true)}`;
  }

  buildProfileLinkUrl(): string {
    return `${this.providerListing.url}${this.buildTrackingParamsString(false)}`;
  }

  checkForInPersonSessions(): boolean {
    return this.providerListing.session_types_offered?.some(
      (sessionType: string) => sessionType.toLowerCase() === 'in_person'
    );
  }

  checkForVideoSessions(): boolean {
    return this.providerListing.session_types_offered?.some(
      (sessionType) => sessionType.toLowerCase() === 'video'
    );
  }

  requestSession(): void {
    const segEvent = {
      persona_provider_id: this.providerListing.id,
      accepting: this.providerListing.accepting_clients,
    } as ProviderListingCardRequestSessionEvent;

    from(this.segmentService.track<ProviderListingCardEvents.DIRECTORY_REQUEST_SESSION>(
      ProviderListingCardEvents.DIRECTORY_REQUEST_SESSION, segEvent
    )).subscribe();

    this.gtmService.sendProviderDirectorySessionRequested(
      this.matchLinkUrl,
      this.providerIndex,
      this.profileLinkUrl
    );
  }

  truncateProviderBio(): string {
    return this.providerListing.bio?.slice(0, 110);
  }

  buildSpecialtyNamesArray(): string[] {
    const providerSpecialties = this.providerListing?.specialties;
    return providerSpecialties ? providerSpecialties.map((specialty) => specialty.title) : [] as string[];
  }

  handleCertificationsList(): void {
    // if there are more than two, only show the first two
    if (this.providerListing?.certifications?.length > 1) {
      this.providerListing.certifications = this.providerListing.certifications.slice(0, 2);
    }
  }

  onClickViewProfile(): void {
    this.sendViewProfileSegmentEvent();
    this.gtmService.sendClickViewProfileEvent(this.profileLinkUrl, this.providerIndex);
  }

  private buildTrackingParamsString(addMatchflowParams: boolean): string {
    const filters = this.currentFormFilters;

    const params = new URLSearchParams();

    if (addMatchflowParams) {
      params.append('slug', this.configurationService.env.app.flowSlug);
      params.append('referringUrl', this.window.location.href);
    } else {
      const referringUrl = this.queryParams?.['referringUrl'] as string
        ?? this.window.location.host;

      params.append('referringUrl', referringUrl);
    }

    if ((this.queryParams?.['anonUserId'] as string)?.length) {
      params.append('anonUserId', this.queryParams['anonUserId']);
    }
    if (this.selectedState && this.selectedState !== '') {
      params.append('state', this.selectedState);
    }

    const selectedInsurance = filters?.insurance;
    if (selectedInsurance) {
      params.append('paymentPref', selectedInsurance.type);
      if (selectedInsurance.tag !== '') {
        params.append('paymentTag', selectedInsurance.tag.toLocaleLowerCase());
      }

      params.append('pncId', filters?.insurance.pncId.toString());
    }

    if (filters?.genders?.length) {
      params.append('genders', filters?.genders.join(','));
    }

    if (filters?.concerns?.length) {
      const modalityIds: number[] = [];
      const specialtyIds: number[] = [];

      filters?.concerns.forEach((concern) => {
        if (concern.isModality) {
          modalityIds.push(concern.id);
        } else {
          specialtyIds.push(concern.id);
        }
      });

      if (modalityIds.length) {
        params.append('modalities', modalityIds.join(','));
      }

      if (specialtyIds.length) {
        params.append('specialties', specialtyIds.join(','));
      }
    }

    // HACK: see https://stackoverflow.com/q/63310897, https://github.com/microsoft/TypeScript/issues/54466
    // There is currently an issue that prevents using params.size with TypeScript
    // entries().next().value is null if there are no params, so we can use that as a way to check size > 1
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return params.entries().next().value ? `?${params.toString()}` : '';
  }

  sendViewProfileSegmentEvent(): void {
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      const directoryFilters = {
        state: res['state'],
        insurance: res['insurance'],
        modalities: res['modalities'],
        specialties: res['specialties'],
        genders: res['genders'],
      };
      const referringUrl: string = res['referringUrl'] ?? '';

      let referringDomain: string;
      if (this.document.referrer) {
        referringDomain = new URL(this.document.referrer).origin;
      }

      const segEvent = {
        persona_provider_id: this.providerListing.id,
        referring_domain: referringDomain,
        internal_referring_page: referringUrl,
        directory_filters: directoryFilters,
        ranking: this.providerIndex + 1,
        accepting: this.providerListing.accepting_clients
      } as ProviderDirectoryViewProfileEvent;
      this.segmentService.track<ProviderListingCardEvents.DIRECTORY_VIEW_PROFILE>(
        ProviderListingCardEvents.DIRECTORY_VIEW_PROFILE, segEvent
      );
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
