import { AbstractControl, ValidationErrors } from '@angular/forms';

// eslint-disable-next-line no-var
declare var Stripe: any;

export class CardNumberValidator {
  static format(c: AbstractControl): ValidationErrors | null {
    if (typeof c.value === 'undefined' || c.value === null || c.value.toString().trim().length === 0) {
      return null;
    }

    if (typeof Stripe !== 'undefined' && !Stripe.card.validateCardNumber(c.value.toString())) {
      return { validateCardNumber: 'Invalid card number' };
    }
    return null;
  }
}
