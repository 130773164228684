import { Component, Input } from '@angular/core';
import { CarouselModule } from 'primeng/carousel';
import { from } from 'rxjs';

import {
  IMiniProviderPreview,
  MiniProviderListingCardComponent,
  MiniProviderListingCardLoadingComponent
} from '@sondermind/features/provider-listing-card';
import { SegmentService } from '@sondermind/segment';

import {
  IPslSegmentSchema,
  PslSegmentEvents,
  PslSimilarProviderViewData
} from '../../models/segment/psl-segment-schema.interface';

interface ICarouselResponsiveOptions {
  breakpoint: string;
  numVisible: number;
  numScroll: number;
}

const MAX_NUM_PROVIDERS_VISIBLE: number = 3;

@Component({
  selector: 'similar-providers-carousel',
  templateUrl: './similar-providers-carousel.component.html',
  styleUrls: ['./similar-providers-carousel.component.scss'],
  standalone: true,
  imports: [
    CarouselModule,
    MiniProviderListingCardComponent,
    MiniProviderListingCardLoadingComponent
  ]
})
export class SimilarProvidersCarousel {
  @Input() loadingSimilarProviders: boolean = false;
  @Input() set similarProviders(value: IMiniProviderPreview[]) {
    this.initializeCarousel(value);
  }
  @Input() currentlyViewedProviderId: number; // The id of the provider whose PSL the carousel is displayed on

  loadedProviders: IMiniProviderPreview[] = [];
  responsiveOptions: ICarouselResponsiveOptions[];
  numVisible: number;

  constructor(
    private segmentService: SegmentService<IPslSegmentSchema>
  ) {}

  private initializeCarousel(similarProviders: IMiniProviderPreview[]): void {
    if (!similarProviders?.length) { return }

    this.numVisible = similarProviders.length > MAX_NUM_PROVIDERS_VISIBLE
      ? MAX_NUM_PROVIDERS_VISIBLE
      : similarProviders.length;

    const isOnlyOneProvider = this.numVisible === 1;

    this.responsiveOptions = [
      {
        breakpoint: '1279px',
        numVisible: isOnlyOneProvider ? 1 : 2,
        numScroll: 1
      },
      {
        breakpoint: '959px',
        numVisible: 1,
        numScroll: 1
      },
    ];

    this.loadedProviders = similarProviders;
  }

  onSimilarProviderClick(similarProvider: IMiniProviderPreview): void {
    from(this.sendSimilarProviderSegmentEvent(similarProvider)).subscribe();
  }

  sendSimilarProviderSegmentEvent(similarProvider: IMiniProviderPreview): Promise<void> {
    const eventData: PslSimilarProviderViewData = {
      allCarouselProviderIds: this.loadedProviders.map((p) => p.id),
      currentProviderId: this.currentlyViewedProviderId,
      destinationProviderId: similarProvider.id,
      index: this.getProviderIndex(similarProvider)
    };

    return this.segmentService.track<PslSegmentEvents.PSL_SIMILAR_PROVIDER_VIEWED>(
      PslSegmentEvents.PSL_SIMILAR_PROVIDER_VIEWED,
      eventData
    );
  }

  getProviderIndex(provider: IMiniProviderPreview): number {
    return this.loadedProviders.findIndex((p) => p.id === provider.id);
  }
}
