import { InjectionToken } from '@angular/core';

export interface Environment {
  production: boolean;
  staging?: boolean;
  ci?: boolean;
  preprod?: boolean;
}

export const ENVIRONMENT = new InjectionToken<Environment>('Environment Settings');
