export enum COMMON {
  SUPPORT_PHONE_THERAPY_LINK = '8448889355',
  SUPPORT_PHONE_THERAPY_DISPLAY = '(844) THERAPY',
  SUPPORT_PHONE_GENERAL_LINK = '7203303713',
  SUPPORT_PHONE_GENERAL_DISPLAY = '(720) 330-3713',
  SUPPORT_PHONE_NO_MATCH_LINK = '8448437279',
  SUPPORT_PHONE_NO_MATCH_DISPLAY = '(844) 843-7279',
  SUPPORT_PHONE_REMATCH_LINK = '8448889355',
  SUPPORT_PHONE_REMATCH_DISPLAY = '(844) 888-9355',
  SUPPORT_EMAIL = 'support@sondermind.com',
  MARKETING_SITE_URL = 'https://www.sondermind.com',
  // Sondermind's lockbox address, which is displayed on client receipts.
  // If you are updating these variables, please also update the PAY_TO_ADDRESS_*
  // environment variables in sonder-rails!
  PAY_TO_ADDRESS_NAME = 'SonderMind Provider Network LLC',
  PAY_TO_ADDRESS_LINE_1 = 'DEPT 0187',
  PAY_TO_ADDRESS_LINE_2 = 'PO BOX 120187',
  PAY_TO_ADDRESS_CITY = 'DALLAS',
  PAY_TO_ADDRESS_STATE = 'TX',
  PAY_TO_ADDRESS_ZIP = '75312-0187',
  PAY_TO_ADDRESS_PHONE = '(844) 256-8987'
}

export const enum SpecializedSupport {
  ADOLESCENTS = '13-17 Years',
  INFANTS = '0-5 Years',
  CHILDREN = '6-12 Years',
  ADULTS = '18-65 Years',
  SENIORS = '66+ Year',
  COUPLES = 'Couples',
  LGBTQIA = 'LGBTQIA+ support',
  VETERAN = 'Military / Veteran support',
  PERINATAL = 'Perinatal support: pregnancy, prenatal, postpartum'
}

export const enum SpecializedSupportDisplay {
  ADOLESCENTS = 'Adolescents',
  INFANTS = 'Children',
  CHILDREN = 'Children',
  ADULTS = 'Adults',
  SENIORS = 'Older adults',
  COUPLES = 'Couples',
  LGBTQIA = 'LGBTQIA+ mental health',
  VETERAN = 'Military and veteran mental health',
  PERINATAL = 'Perinatal mental health'
}
