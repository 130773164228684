import { Component, OnInit } from '@angular/core';
import { ConfigurationService, IProviderShareableLinkAppConfig } from '@sondermind/configuration';
import {
  IconColors, IconSizes, IconUsageTypes, IrisIconModule
} from '@sondermindorg/iris-design-system-angular';
import { LandingPageContact } from '../../models/enums/landing-page-contact.enum';

@Component({
  selector: 'non-accepting-match-options',
  standalone: true,
  imports: [
    IrisIconModule
  ],
  templateUrl: './non-accepting-match-options.component.html',
  styleUrls: ['./non-accepting-match-options.component.scss']
})
export class NonAcceptingMatchOptionsComponent implements OnInit {
  bookSessionPhoneLink: string = LandingPageContact.MATCH_BY_PHONE_LINK;
  bookSessionPhoneDisplay: string = LandingPageContact.MATCH_BY_PHONE_DISPLAY;
  iconColor = IconColors.BRAND1;
  iconSize = IconSizes.MD;
  iconUsage = IconUsageTypes.STAND_ALONE;
  therapyMatchFlowUrl: string = 'https://start.sondermind.com';

  constructor(private configurationService: ConfigurationService<IProviderShareableLinkAppConfig>) {}

  ngOnInit(): void {
    this.therapyMatchFlowUrl = this.configurationService.env.app.flowHost;
  }
}
