<div class="checkout-container">
  <ng-switch [ngSwitch]="quickCheckoutState">
    <ng-container *ngSwitchCase="'form'" [ngTemplateOutlet]="form"></ng-container>
    <ng-container *ngSwitchCase="'error'" [ngTemplateOutlet]="submissionError"></ng-container>
    <ng-container *ngSwitchCase="'submitted'" [ngTemplateOutlet]="submissionConfirmation"></ng-container>
  </ng-switch>

  <ng-template #form>
    <h2> Please tell us some basic information about yourself to get scheduled. </h2>
    <div *ngIf="requestedTimeSlot" class="proposed-time">
      <span class="proposed-time-label">Proposed time:</span>
      <span class="proposed-time-value">
        {{ requestedTimeSlot.startTime| date:'EEEE, MMMM d, h' }}-{{ requestedTimeSlot.endTime| date:'h a' }}
      </span>
    </div>
    <form [formGroup]="quickCheckoutFormGroup" class="checkout-form">
      <iris-form-field label="First name" class="checkout-text-label">
        <iris-text-input 
          formControlName="firstName"
          required="true"
          autocomplete="given-name"
        ></iris-text-input>
      </iris-form-field>
  
      <iris-form-field label="Last name" class="checkout-text-label">
        <iris-text-input 
          formControlName="lastName"
          required="true"
          autocomplete="family-name"
        ></iris-text-input>
      </iris-form-field>
  
      <iris-form-field
        label="Email"
        class="checkout-text-label"
        [customErrorMessages]="{
          emailFormat: 'Please enter a valid email address.'
        }"
      >
        <iris-text-input 
          formControlName="email"
          required="true"
        ></iris-text-input>
      </iris-form-field>
  
      <iris-form-field
        label="Phone number"
        [customErrorMessages]="{
          phoneNumberFormat: 'Please enter a valid phone number.'
        }"
      >
        <iris-text-input 
          formControlName="phone"
          placeholder="(___) ___-____"
          [mask]="masks.phoneMask"
        ></iris-text-input>
      </iris-form-field>
  
      <iris-checkbox formControlName="contactSmsAllowed" class="contact-sms-checkbox">
        Opt me into SMS text account updates and session reminders (standard messaging rates apply)
      </iris-checkbox>
  
      <iris-form-field 
        label="Date of birth"
        class="checkout-text-label"
        [customErrorMessages]="{
          dateFormat: 'Dates must be in correct format of MM/DD/YYYY Month/Day/Year.' 
        }"
      >
        <iris-text-input 
          formControlName="dateOfBirth"
          required="true"
          autocomplete="bday"
          placeholder="mm/dd/yyyy"
          [mask]="masks.birthdayMask"
        ></iris-text-input>
      </iris-form-field>
  
      <iris-form-field 
        label="Location" 
        helperText="If you don't see your state, this provider is not licensed to practice in your state." 
        class="checkout-text-label"
      >
        <iris-dropdown
          formControlName="location"
          [options]="stateOptions"
        ></iris-dropdown>
      </iris-form-field>
  
      <iris-form-field 
        label="Insurance company" 
        helperText="If you don't see your insurance, this provider may not accept your insurance." 
        class="checkout-text-label"
      >
        <iris-dropdown
          class="insurance-dropdown"
          formControlName="insurance"
          [options]="insuranceOptions"
        ></iris-dropdown>
      </iris-form-field>
  
      <iris-form-field label="How should your provider contact you?">
        <div formGroupName="commPrefs">
          <iris-checkbox formControlName="commPrefsSms">
            SMS text message (standard messaging rates apply)
          </iris-checkbox>
          <iris-checkbox formControlName="commPrefsPhone">
            Phone call (they'll leave a voicemail if you aren't available)
          </iris-checkbox>
        </div>
      </iris-form-field>
      <iris-button-container [alignment]="irisButtonAlignmentEnd">
        <iris-button (click)="onCancel()" [type]="irisButtonTypeSecondary">
          Cancel
        </iris-button>
        <iris-button 
          class="submit-checkout-button"
          [disabled]="quickCheckoutFormGroup.invalid"
          (click)="onClickSubmit()"
        >
          Submit
        </iris-button>
      </iris-button-container>
    </form>
  </ng-template>

  <ng-template #submissionError>
    <h2>
      There was an error when submitting your request.
    </h2>
    <p>
      Please try again.
    </p>
    <iris-button-container [alignment]="irisButtonAlignmentEnd">
      <iris-button (click)="onBack()" [type]="irisButtonTypeSecondary">
        Back to form
      </iris-button>
      <iris-button (click)="onCancel()">
        Close
      </iris-button>
    </iris-button-container>
  </ng-template>

  <ng-template #submissionConfirmation>
    <h2>
      Your request has been submitted successfully.
    </h2>
    <p>
      Please check your email to log in and confirm your request within 24 hours.
      <br>
      If you don't see the email in your inbox, <a (click)="onClickResendEmail()">click here to resend it.</a>
    </p>

    <iris-button-container [alignment]="irisButtonAlignmentEnd">
      <iris-button (click)="onCancel()">
        Close
      </iris-button>
    </iris-button-container>
  </ng-template>
  
</div>
