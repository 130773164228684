interface IProviderNetworkCompany {
  id: number;
  name: string;
  allowCardPictures: boolean;
  authNumberRequired: boolean;
  authNumberVisible: boolean;
  averageClientResponsibility: string;
  billingAddressLine1: string;
  billingAddressLine2: string;
  billingCity: string;
  billingInfoVisible: boolean;
  billingName: string;
  billingPostalCode: string;
  billingState: string;
  billingWebsite: string;
  clientsCanAddToSelf: boolean;
  eap: boolean;
  eapCompanyNameRequired: boolean;
  eapCompanyNameVisible: boolean;
  expectedClientCopayCost: string;
  expectedClientCostPreDeductible: string;
  groupNumberRequired: boolean;
  groupNumberVisible: boolean;
  insuranceId: number;
  insuranceName: string;
  isSnap: boolean;
  logo: string;
  maximumContractedRate: string;
  minimumContractedRate: string;
  medicare: boolean;
  medicareAdvantage: boolean;
  memberHelpText: string;
  memberIdLabel?: string;
  memberNumberRequired: boolean;
  memberNumberVisible: boolean;
  networkState: string;
  planRequired: boolean;
  planVisible: boolean;
  preCardValidationRequired: boolean;
  preCardValidationRequiredMessage: string;
  providersCanAddToClients: boolean;
  spn: boolean;
  subscriberInfoVisible: boolean;
}

export interface IProviderNetworkCompanyAPIResponse {
  id: number;
  name: string;
  allow_card_pictures: boolean;
  auth_number_required: boolean;
  auth_number_visible: boolean;
  average_client_responsibility: string;
  billing_name: string;
  billing_address_line1: string;
  billing_address_line2: string;
  billing_city: string;
  billing_state: string;
  billing_phone: string;
  billing_postal_code: string;
  billing_info_visible: boolean;
  billing_website: string;
  clients_can_add_to_self: boolean;
  eap: boolean;
  eap_company_name_required: boolean;
  eap_company_name_visible: boolean;
  expected_client_copay_cost: string;
  expected_client_cost_pre_deductible: string;
  group_number_required: boolean;
  group_number_visible: boolean;
  insurance_id: number;
  insurance_name: string;
  is_pro_bono: boolean;
  is_redemption: boolean;
  is_snap: boolean;
  is_usage_based: boolean;
  logo: string;
  maximum_contracted_rate: string;
  minimum_contracted_rate: string;
  medicare: boolean;
  medicare_advantage: boolean;
  member_help_text: string;
  member_number_required: boolean;
  member_number_visible: boolean;
  network_state: string;
  plan_required: boolean;
  plan_visible: boolean;
  pre_card_validation_required: boolean;
  pre_card_validation_required_message: string;
  providers_can_add_to_clients: boolean;
  spn: boolean;
  subscriber_info_visible: boolean;
  sondermind_cash_pay_network: boolean;
}

export class ProviderNetworkCompany implements IProviderNetworkCompany {
  id: number;
  name: string;
  allowCardPictures: boolean;
  authNumberRequired: boolean;
  authNumberVisible: boolean;
  averageClientResponsibility: string;
  billingAddressLine1: string;
  billingAddressLine2: string;
  billingCity: string;
  billingInfoVisible: boolean;
  billingName: string;
  billingPhone: string;
  billingPostalCode: string;
  billingState: string;
  billingWebsite: string;
  clientsCanAddToSelf: boolean;
  eap: boolean;
  eapCompanyNameRequired: boolean;
  eapCompanyNameVisible: boolean;
  expectedClientCopayCost: string;
  expectedClientCostPreDeductible: string;
  groupNumberRequired: boolean;
  groupNumberVisible: boolean;
  insuranceId: number;
  insuranceName: string;
  isProBono: boolean;
  isRedemption: boolean;
  isSnap: boolean;
  isUsageBased: boolean;
  logo: string;
  maximumContractedRate: string;
  minimumContractedRate: string;
  medicare: boolean;
  medicareAdvantage: boolean;
  memberHelpText: string;
  memberIdLabel?: string;
  memberNumberRequired: boolean;
  memberNumberVisible: boolean;
  networkState: string;
  planRequired: boolean;
  planVisible: boolean;
  preCardValidationRequired: boolean;
  preCardValidationRequiredMessage: string;
  providersCanAddToClients: boolean;
  spn: boolean;
  subscriberInfoVisible: boolean;
  sondermindCashPayNetwork: boolean;

  get allHidden(): boolean {
    return !this.eap && !(
      this.groupNumberVisible
      || this.memberNumberVisible
      || this.subscriberInfoVisible
      || this.planVisible
      || this.eapCompanyNameVisible
      || this.billingInfoVisible
      || this.authNumberVisible
      || this.allowCardPictures);
  }

  static get defaultVisiblityValues(): Partial<ProviderNetworkCompany> {
    return {
      eap: false,
      groupNumberRequired: true,
      groupNumberVisible: true,
      memberNumberRequired: true,
      memberNumberVisible: true,
      subscriberInfoVisible: true,
      planRequired: true,
      planVisible: true,
      eapCompanyNameVisible: true,
      eapCompanyNameRequired: true,
      billingInfoVisible: true,
      authNumberVisible: true,
      allowCardPictures: true
    };
  }

  constructor(dataSource: IProviderNetworkCompanyAPIResponse | IProviderNetworkCompany, fromAPI: boolean = true) {
    if (fromAPI) {
      const apiDataSource = dataSource as IProviderNetworkCompanyAPIResponse;
      this.id = apiDataSource.id;
      this.name = apiDataSource.name;
      this.allowCardPictures = apiDataSource.allow_card_pictures;
      this.authNumberRequired = apiDataSource.auth_number_required;
      this.authNumberVisible = apiDataSource.auth_number_visible;
      this.averageClientResponsibility = apiDataSource.average_client_responsibility;
      this.billingAddressLine1 = apiDataSource.billing_address_line1;
      this.billingAddressLine2 = apiDataSource.billing_address_line2;
      this.billingCity = apiDataSource.billing_city;
      this.billingInfoVisible = apiDataSource.billing_info_visible;
      this.billingName = apiDataSource.billing_name;
      this.billingPhone = apiDataSource.billing_phone;
      this.billingPostalCode = apiDataSource.billing_postal_code;
      this.billingState = apiDataSource.billing_state;
      this.billingWebsite = apiDataSource.billing_website;
      this.clientsCanAddToSelf = apiDataSource.clients_can_add_to_self;
      this.eap = apiDataSource.eap;
      this.eapCompanyNameRequired = apiDataSource.eap_company_name_required;
      this.eapCompanyNameVisible = apiDataSource.eap_company_name_visible;
      this.expectedClientCopayCost = apiDataSource.expected_client_copay_cost;
      this.expectedClientCostPreDeductible = apiDataSource.expected_client_cost_pre_deductible;
      this.groupNumberRequired = apiDataSource.group_number_required;
      this.groupNumberVisible = apiDataSource.group_number_visible;
      this.insuranceId = apiDataSource.insurance_id;
      this.insuranceName = apiDataSource.insurance_name;
      this.isProBono = apiDataSource.is_pro_bono;
      this.isRedemption = apiDataSource.is_redemption;
      this.isSnap = apiDataSource.is_snap;
      this.isUsageBased = apiDataSource.is_usage_based;
      this.logo = apiDataSource.logo;
      this.maximumContractedRate = apiDataSource.maximum_contracted_rate;
      this.minimumContractedRate = apiDataSource.minimum_contracted_rate;
      this.medicare = apiDataSource.medicare;
      this.medicareAdvantage = apiDataSource.medicare_advantage;
      this.memberHelpText = apiDataSource.member_help_text;
      this.memberNumberRequired = apiDataSource.member_number_required;
      this.memberNumberVisible = apiDataSource.member_number_visible;
      this.networkState = apiDataSource.network_state;
      this.planRequired = apiDataSource.plan_required;
      this.planVisible = apiDataSource.plan_visible;
      this.preCardValidationRequired = apiDataSource.pre_card_validation_required;
      this.preCardValidationRequiredMessage = apiDataSource.pre_card_validation_required_message;
      this.providersCanAddToClients = apiDataSource.providers_can_add_to_clients;
      this.spn = apiDataSource.spn;
      this.subscriberInfoVisible = apiDataSource.subscriber_info_visible;
      this.sondermindCashPayNetwork = apiDataSource.sondermind_cash_pay_network;
    } else {
      /* dataSource is not from an API response, and therefore does not follow the API naming scheme.
      We assume dataSource has IProviderNetworkCompany shape and directly map it. */
      const nonApiDataSource = dataSource as IProviderNetworkCompany;
      Object.assign(this, nonApiDataSource);
    }
  }
}

/**
 * For sorting by name, then by state.
 */
export function sortPNCByNameThenState(companyA: ProviderNetworkCompany, companyB: ProviderNetworkCompany): number {
  if (companyA.name > companyB.name) return 1;
  if (companyA.name < companyB.name) return -1;

  if (companyA.networkState > companyB.networkState) return 1;
  if (companyA.networkState < companyB.networkState) return -1;

  return 0;
}
