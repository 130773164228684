import { Component, Inject } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { IrisButtonModule } from '@sondermindorg/iris-design-system-angular';
import { ConfigurationModule, ConfigurationService, IDirectoryAppConfig } from '@sondermind/configuration';
import { GtmDataLayerService } from '@sondermind/google-tag-manager';
import { InitialsPipe, StringArrayToCommaListPipe } from '@sondermind/utilities/pipes';
import { WINDOW } from '@sondermind/utilities/tools';

import { SegmentService } from '@sondermind/segment';
import { ActivatedRoute } from '@angular/router';
import { ProviderPhotoComponent } from '../provider-photo/provider-photo.component';
import { ProviderListingCardComponent } from '../provider-listing-card/provider-listing-card.component';
import { IProviderListingCardSegmentSchema } from '../../models/segment/provider-directory-segment.interface';

@Component({
  selector: 'mini-provider-listing-card',
  standalone: true,
  imports: [
    CommonModule,
    ConfigurationModule,
    HttpClientModule,
    IrisButtonModule,
    InitialsPipe,
    ProviderPhotoComponent,
    StringArrayToCommaListPipe
  ],
  templateUrl: './mini-provider-listing-card.component.html',
  styleUrls: ['./mini-provider-listing-card.component.scss']
})
export class MiniProviderListingCardComponent extends ProviderListingCardComponent {
  constructor(
    configurationService: ConfigurationService<IDirectoryAppConfig>,
    gtmService: GtmDataLayerService,
    @Inject(WINDOW) window: Window,
    segmentService: SegmentService<IProviderListingCardSegmentSchema>,
    route: ActivatedRoute,
    @Inject(DOCUMENT) document: Document,
  ){
    super(configurationService, gtmService, window, segmentService, route, document);
  }
}
