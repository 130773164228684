/* eslint-disable @typescript-eslint/naming-convention */
export interface IInsurance {
  isCashPay: boolean;
  isEap: boolean;
  pncId: number;
  isMedicare: boolean;
  isPrivateInsurance: boolean;
  title: string;
  tag: string;
}

export interface IInsuranceApiResponse {
  'cash_pay?': boolean;
  'eap?': boolean;
  id: number;
  'medicare?': boolean;
  'private_insurance?': boolean;
  title: string;
  tag: string;
}

export enum InsuranceType {
  INSURANCE = 'insurance',
  EAP = 'eap',
  MEDICARE = 'medicare_medicare_advantage',
  CASH = 'cash'
}

export enum InsuranceCategoryName {
  INSURANCE = 'Health insurance',
  MEDICARE = 'Medicare plans',
  EAP = '(EAP) Employee Assistance Program'
}

export const enum NoInsuranceOptionValue {
  NOT_SURE = -1,
  CASH_PAY = -2
}

export interface IInsuranceOptionValue {
  pncId: number;
  title: string;
  type: InsuranceType | undefined;
  tag: string;
}

export const CASH_PAY_OPTION_VALUE: IInsuranceOptionValue = {
  pncId: NoInsuranceOptionValue.CASH_PAY,
  title: 'out of pocket',
  type: InsuranceType.CASH,
  tag: ''
};

export function parseInsurance(apiObj: IInsuranceApiResponse): IInsurance {
  return {
    isCashPay: apiObj['cash_pay?'],
    isEap: apiObj['eap?'],
    pncId: apiObj.id,
    isMedicare: apiObj['medicare?'],
    isPrivateInsurance: apiObj['private_insurance?'],
    title: apiObj.title,
    tag: apiObj.tag,
  };
}
