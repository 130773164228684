import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigurationService } from './configuration.service';

const configFactory = (config: ConfigurationService): () => Observable<boolean> => () => config.load();

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: configFactory,
    multi: true,
    deps: [
      ConfigurationService,
    ]
  }]
})
export class ConfigurationModule { }
