import { SegmentSchema } from '@sondermind/segment';

export enum ProviderListingCardEvents {
  DIRECTORY_REQUEST_SESSION = 'Directory: Form Started',
  DIRECTORY_PAGE_VIEW = 'Directory Page View',
  DIRECTORY_VIEW_PROFILE = 'Viewed Profile From Directory'
}

export interface ProviderListingCardRequestSessionEvent {
  persona_provider_id: number;
  accepting: boolean;
}

export interface ProviderDirectoryPageViewEvent {
  referring_domain: string;
  count_providers_returned: number;
  internal_referring_page: string;
  directory_filters: {
    state: string | null;
    insurance: string | null;
    modalities: string | null;
    specialties: string | null;
    genders: string | null;
  };
  utm: string[][];
}

export interface ProviderDirectoryViewProfileEvent {
  persona_provider_id: number;
  referring_domain: string;
  internal_referring_page: string;
  directory_filters: {
    state: string | null;
    insurance: string | null;
    modalities: string | null;
    specialties: string | null;
    genders: string | null;
  };
  accepting: boolean;
  ranking: number;
}

export interface IProviderListingCardSegmentSchema extends SegmentSchema {
  [ProviderListingCardEvents.DIRECTORY_REQUEST_SESSION]: ProviderListingCardRequestSessionEvent;
  [ProviderListingCardEvents.DIRECTORY_PAGE_VIEW]: ProviderDirectoryPageViewEvent;
  [ProviderListingCardEvents.DIRECTORY_VIEW_PROFILE]: ProviderDirectoryViewProfileEvent;
}
